import { useTranslation } from 'react-i18next'
import { IMetaValue } from '../types/IMetaData'
import MetaDataFieldsForPage from './DocumentCreateModal/MetaDataFieldsForPage'
import Card from './base/Card'
import DocButton from './base/DocButton'

interface IMetaDataBox {
  requiredFields: IMetaValue[]
  optionalFields: IMetaValue[]
  inInspector?: boolean
  documentPage?: boolean
  onOptionalFieldsUpdate: () => void
  onRequiredFieldsUpdate: () => void
  disabled?: boolean
}

const MetaDataBox = ({
  requiredFields,
  optionalFields,
  inInspector = false,
  documentPage,
  onOptionalFieldsUpdate,
  onRequiredFieldsUpdate,
  disabled,
}: IMetaDataBox) => {
  const { t } = useTranslation()

  const content = (
    <>
      {documentPage && (
        <p className={'font-black mb-2'}>{t('document_meta_data')}</p>
      )}
      <div className={`-ml-7 -mt-2 ${!inInspector ? 'flex' : ''}`}>
        <div className="flex-none">
          <MetaDataFieldsForPage
            onFieldsUpdate={onRequiredFieldsUpdate}
            fields={requiredFields}
            title={t('required_meta_data')}
            required={true}
            editMode={false}
            disabled={disabled}
            inspectorPanel={inInspector}
          />
        </div>
        <div className={`${inInspector ? '-mt-3' : ''} flex-none`}>
          <MetaDataFieldsForPage
            onFieldsUpdate={onOptionalFieldsUpdate}
            fields={optionalFields}
            title={t('optional_meta_data')}
            required={false}
            disabled={disabled}
            editMode={false}
            inspectorPanel={inInspector}
          />
        </div>
      </div>
      <div className={'absolute hidden right-4 top-4'}>
        <DocButton text={t('all_meta_data')} />
      </div>
    </>
  )
  return !documentPage ? (
    <div className="ml-4 w-full">{content}</div>
  ) : (
    <Card classname="min-w-[30rem] max-w-[900px] relative">{content}</Card>
  )
}

export default MetaDataBox
