import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import { ColumnFiltersState } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import FmiSelectorsWithComment from 'src/components/system/system-fmi/FmiSelectorsWithComment'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import InspectorPanelFileContainerList from 'src/document/components/DocumentExplorer/InspectorPanelFileContainerList'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { IMetaValue } from 'src/document/types/IMetaData'
import useServiceData from 'src/hooks/useServiceData'
import SystemsConnectedToSystem from 'src/page/system/connections/SystemsConnectedToSystem'
import MultiAddActorsInterfacePanel from 'src/page/systematic-completion/SystematicCompletionTable/panels/MultiAddActorsInterfacePanel'
import { mcStatusBadgeColorMapper } from 'src/page/systematic-completion/mc/MCList'
import TestInspectorPanel from 'src/page/systematic-completion/tests/TestInspectorPanel'
import { useRooms } from 'src/query/room/rooms'
import { useSystem } from 'src/query/systems/systems'
import { getDisciplines } from 'src/service/DisciplineService'
import {
  IDiscipline,
  ISystem,
  ISystemRecordUpdate,
  ISystemType,
  ISystemTypeGroup,
  ITest,
  ITestSetup,
  ITestSystemGroup,
  TestSetupStatuses,
} from 'src/service/OrgTypes'
import {
  editSystem,
  getAllParentsOfSystem,
  getGroupSystemFMIs,
  getProjectSystemFMIs,
  getProjectSystemMMIs,
  getSystemsTypeUniques,
  updateSystemDisciplines,
  updateSystemNumber,
  updateTestSystemGroups,
  validateSystemNumber,
  updateSystemFileContainers,
} from 'src/service/SystemService'
import { getStatusesForType } from 'src/service/SystemStatusService'
import { getProjectSystemTypeGroupsPerDomain } from 'src/service/SystemTypeGroupService'
import { getSystemTests, updateTestSetup } from 'src/service/TestService'
import { getProjectTestSystemGroupOnly } from 'src/service/TestSystemGroupService'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import FieldGroup from 'src/ui-elements/fields/FieldGroup'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import BooleanInlineInputComponent from 'src/ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import { TDropDownType } from 'src/ui-elements/page-display/inline-components/IDropDown'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter, classNames, openFile } from 'src/utility/utils'
import MetaDataFieldsForPage from '../../document/components/DocumentCreateModal/MetaDataFieldsForPage'
import InspectorSections, {
  IInspectorSection,
} from '../../document/components/Inspector/InspectorSections'
import Button from '../../ui-elements/button/Button'
import FixedPane from '../../ui-elements/fixed-pane/FixedPane'
import Modal from '../../ui-elements/modal/Modal'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import DateTimeInlineInputComponent from '../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineCompontent from '../../ui-elements/page-display/inline-components/InlineComponent'
import MultiSelectorInlineInputComponent from '../../ui-elements/page-display/inline-components/MultiSelectorInlineInputComponent'
import AttachmentForm from '../Images/AttachmentForm'
import TableStatusLabel from '../status-dropdown/TableStatusLabel'
import TaskInspectorPanel from '../task/TaskInspectorPanel'
import SystemSelectorWithList from './SystemSelectorWithList'
import SystemTaskSection from './SystemTaskSection'
import {
  getMetaDataValues,
  initializeMetaValues,
  setExistingValues,
} from './SystemUtil'
import { IMetaField } from './system-type-fields/SystemTypeFieldsForm'

interface ISystemInspectorPanel {
  systemId: number
  open: boolean
  onUpdate: () => void
  onClose: () => void
  defaultIndex?: number
  testDocumentTypeId?: number
  testSetupTypeId?: number
  testTypeId?: number
  disciplineId?: number
  cascaded?: boolean
  showTestMetaData?: boolean
  selectedSystemTypeGroupName?: string
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
}

const SystemInspectorPanel: React.FC<ISystemInspectorPanel> = ({
  systemId,
  open,
  onClose,
  onUpdate,
  defaultIndex = 0,
  testSetupTypeId,
  testDocumentTypeId,
  testTypeId,
  disciplineId,
  cascaded,
  showTestMetaData,
  selectedSystemTypeGroupName,
}) => {
  const { t } = useTranslation()

  const userContext = useContext(UserContext)
  const writeAccess = userContext.actions.hasWriteAccess('object')
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state?.currentProject.id
  const isInfrastructure =
    projectContext.state?.currentProject.is_infrastructure_project
  const [showIssueModal, setShowIssueModal] = useState(false)
  const [showUploadAttachmentModal, setShowUploadAttachmentModal] =
    useState(false)
  const [selectedIssue, setSelectedIssue] = useState(0)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [parentSystems, setParentSystems] = useState<ISystemType[]>([])
  const [tests, setTests] = useState<ITest[]>([])
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [selectedSystem, setSelectedSystem] = useState<number>()
  const [showSystemInspectorPanel, setShowSystemInspectorPanel] =
    useState(false)
  const [selectedTest, setSelectedTest] = useState<number>()
  const [showTestInspectorPanel, setShowTestInspectorPanel] = useState(false)
  const [showMultiActorsPanel, setShowMultiActorsPanel] = useState(false)
  const [systemTypeGroups, setSystemTypeGroups] = useState<ISystemTypeGroup[]>(
    [],
  )
  const [showRecordIdUpdate, setShowRecordIdUpdate] = useState(false)
  const [showRecordIdUpdateConfirm, setShowRecordIdUpdateConfirm] =
    useState(false)
  const [recordIdUpdate, setRecordIdUpdate] = useState<ISystemRecordUpdate>()
  const [newRecordId, setNewRecordId] = useState<string>()
  const [testSystemGroups, setTestSystemGroups] = useState<ITestSystemGroup[]>(
    [],
  )
  const [fileContainerDefaultFilter, setFileContainerDefaultFilter] =
    useState<ColumnFiltersState>()

  const { data: rooms = [] } = useRooms()

  const { data: system, refetch } = useSystem(systemId)
  const { data: systemFmis } = useServiceData(
    () =>
      system?.system_fmi_group_id
        ? getGroupSystemFMIs(system?.system_fmi_group_id)
        : getProjectSystemFMIs(projectId),
    [system?.system_fmi_group_id],
  )

  useEffect(() => {
    if (!testDocumentTypeId) return
    setFileContainerDefaultFilter([
      { id: 'test_document_type', value: [testDocumentTypeId] },
    ])
  }, [testDocumentTypeId])

  useEffect(() => {
    if (!system) return
    const metaData = getMetaDataValues(system.meta_data)
    const metaFields = initializeMetaValues(
      system?.system_type?.optional_fields ?? [],
      'System',
      systemId,
    )
    setOptionalFields(setExistingValues(metaData, metaFields))
  }, [system])

  const loadDisciplines = () => {
    setDisciplines([])
    return getDisciplines(projectId).then((res) => {
      setDisciplines(res)
      return res
    })
  }

  const loadTests = () => {
    getSystemTests(projectId, +systemId).then((res) =>
      setTests(res?.length > 0 ? res : []),
    )
  }

  const loadTestSystemGroups = () => {
    return getProjectTestSystemGroupOnly(projectId).then((res) => {
      setTestSystemGroups(res)
      return res
    })
  }

  const loadMetaValues = (
    fields?: IMetaField[],
    values?: IMetaValue[],
  ): IMetaValue[] => {
    const metaFields = initializeMetaValues(fields ?? [], 'System', systemId)
    return setExistingValues(values ?? [], metaFields)
  }

  const loadParentsOfSystem = () => {
    setParentSystems([])
    getAllParentsOfSystem(systemId).then((data) => {
      setParentSystems(data.map((item: ISystem) => item.system_type || []))
    })
  }

  const loadSystemData = useCallback(async () => {
    refetch()
    loadParentsOfSystem()
  }, [systemId])

  useEffect(() => {
    refetch()
    loadTests()
    loadDisciplines()
  }, [systemId, projectId])

  useEffect(() => {
    const loadMetaDataFields = () => {
      getProjectSystemTypeGroupsPerDomain(projectId, 'Test').then((res) => {
        setSystemTypeGroups(
          res.filter(
            (systemTypeGroup) => systemTypeGroup.optional_fields.length,
          ),
        )
      })
    }
    showTestMetaData && loadMetaDataFields()
  }, [showTestMetaData, projectId])

  const onSystemClick = (id: number) => {
    setSelectedSystem(id)
    setShowSystemInspectorPanel(true)
  }

  const onIssueClick = (id: number) => {
    setSelectedIssue(id)
    setShowIssueModal(true)
  }

  const onTestClick = (testId: number) => {
    setSelectedTest(testId)
    setShowTestInspectorPanel(true)
  }

  const closeIssueModal = () => {
    setShowIssueModal(false)
  }

  const onChangeInputTestSetup = (update: Partial<ITestSetup>, id?: number) => {
    if (id && id > 0) {
      updateTestSetup(projectId, id, {
        id: id,
        ...convertUndefinedToNull(update),
      }).then(() => {
        loadSystemData()
        onUpdate()
      })
    }
  }

  const onUpdateTestSystemGroups = (testSystemGroupIds: TDropDownType[]) => {
    const ids = testSystemGroupIds.filter((id) => id !== undefined) as number[]
    updateTestSystemGroups(systemId, ids).then(() => {
      loadSystemData()
      onUpdate()
    })
  }

  const onUpdateDisciplines = (ids: number[]) => {
    updateSystemDisciplines(projectId, systemId, ids).then(() => {
      loadSystemData()
      onUpdate()
    })
  }

  const onCloseAttachmentModal = () => {
    setShowUploadAttachmentModal(false)
    refetch()
  }

  const onUpdateSystem = () => {
    loadSystemData()
    onUpdate()
  }

  const getTestMetadataRows = (): IInspectorSection[] =>
    showTestMetaData
      ? systemTypeGroups.map((systemTypeGroup) => ({
          name: systemTypeGroup.name,
          icon: Icons.DATABASE_GREY,
          activeIcon: Icons.DATABASE,
          content: (
            <div className={'flex flex-col -ml-4 -mt-2'}>
              <MetaDataFieldsForPage
                onFieldsUpdate={onUpdateSystem}
                fields={loadMetaValues(
                  systemTypeGroup.optional_fields,
                  getMetaDataValues(system?.meta_data),
                )}
                required={false}
                editMode={true}
                inspectorPanel={true}
              />
            </div>
          ),
          overflowVisible: true,
        }))
      : []

  const MCStatuses = Object.values(TestSetupStatuses).map((status) => ({
    id: status,
    name: capFirstLetter(t(status)),
  }))

  const onSystemUpdate = () => {
    loadSystemData()
    onUpdate?.()
  }

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4 -mt-2'}>
      {optionalFields.length > 0 && (
        <MetaDataFieldsForPage
          onFieldsUpdate={onSystemUpdate}
          fields={optionalFields}
          required={false}
          editMode={false}
          inspectorPanel={true}
          disabled={!writeAccess}
        />
      )}
    </div>
  )

  const getRows = (): IInspectorSection[] => [
    {
      name: t('system_details'),
      icon: Icons.SYSTEM_GRAY,
      activeIcon: Icons.SYSTEM_BLACK,
      content: getMainContent(),
      onClick: loadSystemData,
      overflowVisible: true,
    },
    {
      name: t('metadata'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      content: getMetaDataContent(),
    },
    {
      name: t('actors_in_interface'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      content: disciplineId ? (
        <SystemTaskSection
          disciplines={disciplines}
          existingSystemTasks={system?.system_tasks ?? []}
          onUpdate={onUpdateSystem}
          showAddModal={() => setShowMultiActorsPanel(true)}
        />
      ) : (
        <></>
      ),
    },
    {
      name: t('documents'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorPanelFileContainerList
          parentFilter={{
            system_ids: [systemId],
          }}
          defaultFilter={fileContainerDefaultFilter}
          refetchParent={() => {
            onUpdateSystem()
            setFileContainerDefaultFilter(undefined)
          }}
          addFileContainers={async (ids) => {
            await updateSystemFileContainers(systemId, ids, [])
            onUpdateSystem()
          }}
          removeFileContainers={async (ids) => {
            await updateSystemFileContainers(systemId, [], ids)
            onUpdateSystem()
          }}
        />
      ),
      overflowVisible: true,
    },
    {
      name: t('test_setup'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      content: (
        <InspectorSectionTable
          headerColumns={[t('setup_name'), t('status')]}
          rowsData={
            system?.test_setups
              ? system?.test_setups.map((testSetup) => {
                  return {
                    cells: [
                      <span key={testSetup?.setup_name}>
                        {testSetup?.setup_name ?? ''}
                      </span>,
                      <TableStatusLabel
                        key={`${testSetup.id} status}`}
                        cell={{
                          status:
                            testSetup.status ?? TestSetupStatuses.NOT_CHECKED,
                        }}
                        rowId={0}
                        statusTypes={MCStatuses}
                        onStatusSelect={(statusType) => {
                          onChangeInputTestSetup(
                            {
                              status: statusType.id as TestSetupStatuses,
                            },
                            testSetup.id,
                          )
                        }}
                        selectColor={({ status }) =>
                          mcStatusBadgeColorMapper[status]
                        }
                      />,
                    ],
                    id: system.id,
                    selected: testSetupTypeId === testSetup.test_setup_type_id,
                    showOverflow: true,
                  }
                })
              : []
          }
        />
      ),
      overflowVisible: true,
    },
    {
      name: t('components'),
      icon: Icons.SYSTEM_GRAY,
      activeIcon: Icons.SYSTEM_BLACK,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('system_number'),
            t('system_name'),
            t('system_type'),
            t('status'),
          ]}
          rowsData={
            system?.children_systems?.map((system) => {
              return {
                cells: [
                  system?.record_id ?? '',
                  system?.name ?? '',
                  `${system?.system_type?.type_code ?? ''} - ${
                    system?.system_type?.name ?? ''
                  }`,
                  system?.system_status?.name ?? '',
                ],
                id: system.id,
              }
            }) ?? []
          }
          handleClick={onSystemClick}
        />
      ),
    },
    {
      name: t('system_connections'),
      icon: Icons.SYSTEM_GRAY,
      activeIcon: Icons.SYSTEM_BLACK,
      content: system && (
        <SystemsConnectedToSystem
          system={system}
          tableName={'SystemConnectionInspectorPanel'}
        />
      ),
    },
    {
      name: t('tests'),
      icon: Icons.CHECK_GRAY,
      activeIcon: Icons.CHECK_GRAY,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('record_id'),
            t('title'),
            t('test_type'),
            t('status'),
            t('planned_date'),
            t('contract'),
          ]}
          rowsData={
            tests
              ? tests
                  .sort((a, b) => (a.test_type_id ?? 0) - (b.test_type_id ?? 0))
                  .map((test) => {
                    return {
                      cells: [
                        test?.record_id ?? '-',
                        test?.name ?? '-',
                        `${test?.test_type?.name ?? '-'}`,
                        t(test?.status ?? '-'),
                        test.execution_date ? (
                          <span
                            className={`${
                              moment(test.execution_date) < moment() &&
                              'text-red'
                            }`}
                          >
                            {moment(test.execution_date).format('L')}
                          </span>
                        ) : (
                          '-'
                        ),
                        test.contract?.contractName ?? '-',
                      ],
                      id: test.id,
                      selected: test.test_type_id === testTypeId,
                    }
                  })
              : []
          }
          handleClick={onTestClick}
        />
      ),
    },
    {
      name: t('cases'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorSectionTable
          headerColumns={[t('id'), t('title'), t('status'), t('deadline')]}
          rowsData={
            system?.tasks
              ? system?.tasks.map((issue) => {
                  return {
                    cells: [
                      issue?.record_id ?? '',
                      issue.title,
                      t(`${issue.status}`),
                      moment(issue.deadline).format('L'),
                    ],
                    id: issue.id,
                  }
                })
              : []
          }
          handleClick={onIssueClick}
        />
      ),
    },
    {
      name: t('attachment'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <>
          {writeAccess && (
            <Button
              onClick={() => setShowUploadAttachmentModal(true)}
              type={Button.ButtonType.BOUNDARY_WHITE}
              size={Button.ButtonSize.SMALL}
              className={'mb-2'}
            >
              {t('upload_type', { type: t('attachment') })}
            </Button>
          )}
          <InspectorSectionTable
            headerColumns={[
              t('file_name'),
              t('document_category'),
              t('source'),
              t('description'),
              t('created_at'),
            ]}
            rowsData={
              system?.images?.map((image) => {
                return {
                  cells: [
                    <div
                      key={image.url}
                      className="underline text-blue-root"
                      onClick={openFile(image.url)}
                    >
                      {image.name}
                    </div>,
                    image.document_type ? image.document_type.name : '',
                    image?.source ?? '',
                    image?.description ?? '',
                    moment(image.created_at).format('L'),
                  ],
                  id: system.id,
                }
              }) ?? []
            }
            handleClick={onSystemClick}
          />
        </>
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={systemId} parentType="System" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={systemId} parentType="System" />,
    },
  ]

  const onChangeInput = (update: Partial<ISystem>) => {
    editSystem({
      ...convertUndefinedToNull(update),
      id: systemId,
    }).then(() => {
      loadSystemData()
      onUpdate()
    })
  }

  const onConfirmRecordIdUpdate = () => {
    setShowRecordIdUpdateConfirm(false)
    if (recordIdUpdate) {
      updateRecordIdUpdate(recordIdUpdate)
    }
  }
  const onRecordIdUpdate = () => {
    if (system?.system_setting_id && newRecordId) {
      validateSystemNumber(
        projectId,
        system?.system_setting_id,
        newRecordId,
        systemId,
      ).then((res) => {
        if (showRecordIdUpdate) {
          setRecordIdUpdate(res)
          if (res?.errors.length === 0) {
            if (res.has_child_systems) {
              setShowRecordIdUpdateConfirm(true)
            } else {
              updateRecordIdUpdate(res)
            }
          }
        }
      })
    }
  }

  const updateRecordIdUpdate = (update: ISystemRecordUpdate) => {
    if (system?.system_setting_id) {
      updateSystemNumber(
        projectId,
        system?.system_setting_id,
        systemId,
        update,
      ).then(() => {
        setShowRecordIdUpdate(false)
        setRecordIdUpdate(undefined)
        loadParentsOfSystem()
        refetch()
      })
    }
  }

  const onRecordIdCancel = () => {
    setShowRecordIdUpdateConfirm(false)
    setShowRecordIdUpdate(false)
    setRecordIdUpdate(undefined)
  }

  const getMainContent = () => {
    return (
      <div className="-mt-3 -ml-6">
        <InlineComponentsWrapper padding="left" labelWidth="w-48">
          <TextInlineInputCompontent
            label="system_name"
            value={system?.name}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('required')
              }
              return undefined
            }}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            disabled={!writeAccess}
          />
          <TextInlineInputCompontent
            label="system_number"
            value={system?.record_id}
            onValueSubmitted={(newValue) => {
              if (newValue) {
                setRecordIdUpdate(undefined)
                setNewRecordId(newValue)
              }
            }}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('required')
              }
              return undefined
            }}
            disabled={!showRecordIdUpdate}
            autoFocus={showRecordIdUpdate}
          />
          {showRecordIdUpdate &&
            recordIdUpdate &&
            recordIdUpdate?.errors.length > 0 && (
              <InlineCompontent
                label=""
                disabled={true}
                content={
                  <>
                    {recordIdUpdate?.errors.map((error) => {
                      return (
                        <div className={'text-red-600 text-sm'} key={error}>
                          {t(error)}
                        </div>
                      )
                    })}
                  </>
                }
              />
            )}
          <InlineCompontent
            label=""
            disabled={true}
            content={
              <>
                {!showRecordIdUpdate && writeAccess && (
                  <Button
                    onClick={() => setShowRecordIdUpdate(true)}
                    className={ButtonType.BOUNDARY_WHITE}
                  >
                    {t('update_system_record_id')}
                  </Button>
                )}
                {showRecordIdUpdate && (
                  <>
                    <Button
                      onClick={onRecordIdUpdate}
                      className={ButtonType.BOUNDARY_WHITE}
                    >
                      {t('save')}
                    </Button>
                    <Button
                      onClick={onRecordIdCancel}
                      className={ButtonType.BOUNDARY_WHITE}
                    >
                      {t('cancel')}
                    </Button>
                  </>
                )}
              </>
            }
          />
          {parentSystems.map((item, index) => {
            return (
              <SelectorInlineInputComponent
                getItemLabel={(systemType) =>
                  systemType
                    ? `${systemType.type_code} - ${systemType.name}`
                    : ''
                }
                label={index ? '' : 'system_type'}
                disabled
                selectedId={item.id}
                initialItem={item}
                key={`system_type_${index}`}
                inspectorPanel={true}
              />
            )
          })}
          <TextInlineInputCompontent
            label="system_type_description"
            value={system?.system_type?.name}
            disabled={true}
          />
          <SelectorInlineInputComponent
            getItems={() => getSystemsTypeUniques(projectId)}
            label={'system_type_unique'}
            onValueSubmitted={(system_type_unique_id) =>
              onChangeInput({ system_type_unique_id })
            }
            selectedId={system?.system_type_unique_id}
            getItemLabel={(discipline) =>
              discipline ? `${discipline.type_code} - ${discipline.name}` : ''
            }
            initialItem={system?.system_type_unique}
            cancelButton={true}
            inspectorPanel={true}
            disabled={!writeAccess}
          />
          <MultiSelectorInlineInputComponent
            items={disciplines}
            getItems={loadDisciplines}
            label={'disciplines'}
            onValueSubmitted={(disciplineIds) => {
              if (disciplineIds !== undefined)
                onUpdateDisciplines(disciplineIds)
            }}
            selectedIds={system?.disciplines?.map((item) => item.id) ?? []}
            initialItems={system?.disciplines}
            getItemLabel={(discipline) =>
              discipline ? `${discipline.shortName} - ${discipline.name}` : ''
            }
            cancelButton={true}
            disabled={!writeAccess}
          />
          <MultiSelectorInlineInputComponent
            label={'contracts'}
            selectedIds={system?.contracts?.map((item) => item.id) ?? []}
            initialItems={system?.contracts}
            onValueSubmitted={() => {}}
            getItemLabel={(contract) =>
              contract
                ? `${contract.contractName} - ${contract.contractNumber}`
                : ''
            }
            disabled={true}
          />
          {!isInfrastructure && (
            <>
              <FieldGroup header={capitalize(t('location'))}>
                <SelectorInlineInputComponent
                  label={t('floor')}
                  inspectorPanel={false}
                  getItemLabel={(floor) => floor?.name}
                  selectedId={system?.room?.floor_id}
                  disabled
                  initialItem={system?.room?.floor}
                />
                <SelectorInlineInputComponent
                  label={t('room')}
                  getItemLabel={(room) =>
                    `${room?.functional_room_number} ${room?.room_name}`
                  }
                  onValueSubmitted={(room_id) => {
                    onChangeInput({ room_id })
                  }}
                  selectedId={system?.room_id}
                  disabled={!writeAccess}
                  inspectorPanel={false}
                  items={rooms}
                  initialItem={system?.room}
                />
              </FieldGroup>

              <FieldGroup header={capitalize(t('coverage'))}>
                <SelectorInlineInputComponent
                  label={t('floor')}
                  inspectorPanel={false}
                  getItemLabel={(floor) => floor?.name}
                  selectedId={system?.coverage_room?.floor_id}
                  disabled
                  initialItem={system?.coverage_room?.floor}
                />
                <SelectorInlineInputComponent
                  label={t('room')}
                  getItemLabel={(room) =>
                    `${room?.functional_room_number} ${room?.room_name}`
                  }
                  onValueSubmitted={(coverage_room_id) => {
                    onChangeInput({ coverage_room_id })
                  }}
                  selectedId={system?.coverage_room_id}
                  disabled={!writeAccess}
                  inspectorPanel={false}
                  items={rooms}
                  initialItem={system?.coverage_room}
                />
              </FieldGroup>
            </>
          )}
          <TextInlineInputCompontent
            label={'location'}
            onValueSubmitted={(location) => {
              return onChangeInput({ location })
            }}
            value={system?.location}
            disabled={!writeAccess}
          />
          <SelectorInlineInputComponent
            getItems={() => getStatusesForType(projectId, 'System')}
            getItemLabel={(systemStatus) => systemStatus?.name ?? ''}
            label={'object_status'}
            onValueSubmitted={(system_status_id) => {
              if (system_status_id) onChangeInput({ system_status_id })
            }}
            selectedId={system?.system_status_id}
            initialItem={system?.system_status}
            cancelButton={true}
            inspectorPanel={true}
            disabled={!writeAccess}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectSystemMMIs(projectId)}
            getItemLabel={(mmi) => mmi?.name ?? ''}
            label={'MMI'}
            onValueSubmitted={(system_mmi_id) => {
              if (system_mmi_id) onChangeInput({ system_mmi_id })
            }}
            selectedId={system?.system_mmi_id}
            initialItem={system?.system_mmi}
            cancelButton={true}
            inspectorPanel={true}
            disabled={!writeAccess}
          />
          {!isInfrastructure && (
            <FmiSelectorsWithComment
              systemData={system}
              projectId={projectId}
              onChangeSystemInput={onChangeInput}
              systemId={systemId}
              systemFmis={systemFmis ?? []}
              reloadSystemData={refetch}
              disabled={!writeAccess}
            />
          )}
          <BooleanInlineInputComponent
            label="is_in_bim"
            onValueSubmitted={(is_in_bim) => {
              onChangeInput({ is_in_bim })
            }}
            value={system?.is_in_bim}
            showCheckBox
            disabled={!writeAccess}
          />
          <BooleanInlineInputComponent
            label="is_active"
            onValueSubmitted={(is_active) => {
              onChangeInput({ is_active })
            }}
            value={system?.is_active}
            showCheckBox
            disabled={!writeAccess}
          />
          <BooleanInlineInputComponent
            label="is_testable"
            onValueSubmitted={(is_testable) => {
              onChangeInput({ is_testable })
            }}
            value={system?.is_testable}
            showCheckBox
            showConfirmModal
            confirmMessage={t('are_you_sure_you_want_to_change_is_testable', {
              params: { a_system: t('a_system') },
            })}
            disabled={!writeAccess}
          />
          <MultiSelectorInlineInputComponent
            items={testSystemGroups}
            getItems={loadTestSystemGroups}
            label={'test_system_group'}
            onValueSubmitted={(testSystemGroupIds) => {
              if (testSystemGroupIds === undefined) return
              onUpdateTestSystemGroups(testSystemGroupIds)
            }}
            selectedIds={
              system?.test_system_groups?.map((item) => item.id) ?? []
            }
            initialItems={system?.test_system_groups}
            getItemLabel={(testSystemGroup) =>
              testSystemGroup
                ? `${testSystemGroup.record_id} - ${testSystemGroup.title}`
                : ''
            }
            cancelButton={true}
            disabled={!writeAccess}
          />
          <MultiSelectorInlineInputComponent
            label={'test_work_group'}
            disabled={true}
            selectedIds={
              system?.test_work_groups?.map(
                (testWorkGroup) => testWorkGroup.id,
              ) ?? []
            }
            onValueSubmitted={() => {
              return
            }}
            initialItems={system?.test_work_groups ?? []}
            getItemLabel={(testWorkGroup) =>
              testWorkGroup
                ? `${testWorkGroup.record_id} - ${testWorkGroup.title}`
                : ''
            }
          />
          <SystemSelectorWithList
            label={'replaced_by_object_no'}
            projectId={projectId}
            currentSystemId={systemId}
            systemSettingId={system?.system_setting_id}
            selectedSystemId={system?.replaced_by_id}
            onSystemSelect={(id) => onChangeInput({ replaced_by_id: id })}
            onRemove={() => onChangeInput({ replaced_by_id: null })}
            initialSystem={system?.replaced_by}
            key={system?.replaced_by_id}
            disabled={!writeAccess}
          />
          <TextInlineInputCompontent
            label="description"
            value={system?.description}
            onValueSubmitted={(description) => {
              if (description) onChangeInput({ description })
            }}
            textArea
            disabled={!writeAccess}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={system?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={system?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }

  const nameIndex = systemTypeGroups.findIndex(
    (systemTypeGroup) => systemTypeGroup.name === selectedSystemTypeGroupName,
  )

  const rows = getRows()

  return (
    <>
      <FixedPane
        title={
          system?.name
            ? `${system?.record_id ?? ''} - ${system?.name ?? ''}`
            : t('loading...')
        }
        show={open}
        onClose={() => onClose()}
        className={'w-[700px]'}
        disableOutsideClose={true}
        cascaded={cascaded}
        detailPageData={{
          key: DetailPageKeys.SYSTEM,
          ids: {
            systemId: system?.id,
            systemSettingId: system?.system_setting_id,
          },
        }}
      >
        <div className={styleClass.root}>
          <InspectorSections
            defaultIndex={
              showTestMetaData && selectedSystemTypeGroupName && nameIndex >= 0
                ? nameIndex + rows.length
                : defaultIndex
            }
            sections={[...rows, ...getTestMetadataRows()]}
          />
          {showIssueModal && selectedIssue && (
            <TaskInspectorPanel
              taskId={selectedIssue}
              open={showIssueModal}
              onClose={closeIssueModal}
              projectId={projectId}
              onUpdate={closeIssueModal}
            />
          )}

          {selectedSystem && showSystemInspectorPanel && (
            <SystemInspectorPanel
              systemId={selectedSystem}
              open={showSystemInspectorPanel}
              onUpdate={() => {
                onUpdate()
              }}
              onClose={() => setShowSystemInspectorPanel(false)}
            />
          )}
          {selectedTest && showTestInspectorPanel && (
            <TestInspectorPanel
              testId={selectedTest}
              open={showTestInspectorPanel}
              onClose={() => setShowTestInspectorPanel(false)}
              onUpdate={loadTests}
            />
          )}
          {showMultiActorsPanel && system && disciplineId && (
            <MultiAddActorsInterfacePanel
              show={showMultiActorsPanel}
              selectedDisciplines={disciplines}
              selectedSystems={[
                {
                  ...system,
                  recordId: system.record_id,
                  subSystems:
                    system.children_systems?.map((system) => ({
                      ...system,
                      recordId: system.record_id,
                      subSystems: [],
                    })) ?? [],
                },
              ]}
              existingSystemTasks={system.system_tasks ?? []}
              onUpdate={async () => {
                onUpdate()
                refetch()
              }}
              onClose={() => setShowMultiActorsPanel(false)}
              setSelection={false}
              initialSelectedDisciplineIds={[disciplineId]}
            />
          )}
        </div>
      </FixedPane>
      {showUploadAttachmentModal && (
        <AttachmentForm
          show={showUploadAttachmentModal}
          parentType={'System'}
          parentId={systemId}
          projectId={projectId}
          onClose={onCloseAttachmentModal}
        />
      )}
      {showRecordIdUpdateConfirm && (
        <Modal
          title={`${t('update_system_record_id')}: ${system?.record_id}`}
          show={showRecordIdUpdateConfirm}
          closeModal={onRecordIdCancel}
          maxWidth={600}
        >
          <div>
            <div
              className={
                'flex flex-row items-center w-full mt-2 px-3 pt-4 mb-6'
              }
            >
              <Icon
                className={'flex flex-none h-10 w-10 mr-4'}
                styleClass={'w-full'}
                icon={Icons.WARNING_RED}
              />
              <div className={'font-normal text-sm leading-5 text-gray-700'}>
                {t('system_number_update_confirm')}
              </div>
            </div>
            <ModalFooter>
              <Button
                type={Button.ButtonType.DEFAULT}
                onClick={onConfirmRecordIdUpdate}
              >
                {t('confirm')}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                onClick={onRecordIdCancel}
              >
                {t('cancel')}
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      )}
    </>
  )
}
export default SystemInspectorPanel
