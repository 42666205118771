import { isEqual } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { metadataTimeTypes } from 'src/document/types/FolderMetadataTypes'
import BooleanInlineInputComponent from 'src/ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import { IStringDropDownItem } from 'src/ui-elements/page-display/inline-components/IDropDown'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import useProjectId from '../../../components/hooks/useProjectId'
import {
  createUserDefinedFieldsValue,
  updateUserDefinedFieldsValue,
} from '../../../service/SystemTypeFieldService'
import MaterialIcon from '../../../ui-elements/icon/materialIcon'
import MultiSelectorInlineInputComponent from '../../../ui-elements/page-display/inline-components/MultiSelectorInlineInputComponent'
import { convertUndefinedToNull } from '../../../utility/convertNullToUndefined'
import { IMetaValue } from '../../types/IMetaData'

interface IMetaDataFieldsForPageProps {
  title?: string
  fields: IMetaValue[]
  required: boolean
  errorMessage?: string
  editMode: boolean
  onFieldsUpdate: () => void
  loading?: boolean
  border?: 'right' | 'left'
  inspectorPanel: boolean
  disabled?: boolean
  padding?: boolean
}

const MetaDataFieldsForPage: React.FC<IMetaDataFieldsForPageProps> = ({
  title,
  fields,
  onFieldsUpdate,
  required,
  loading,
  border,
  inspectorPanel,
  disabled,
  padding = true,
}) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const onFieldValueUpdate = (
    fieldId: number,
    value?: string | boolean,
    list?: string[],
  ) => {
    const tmpField = fields.find(
      (field) => field.user_defined_field_id === fieldId,
    )

    if (tmpField) {
      const updatedData = {
        ...tmpField,
        ...convertUndefinedToNull({
          data_value: value,
          data_value_list: list,
          error: '',
        }),
      }
      if (updatedData.id) {
        updateUserDefinedFieldsValue(
          updatedData.id,
          updatedData as IMetaValue,
        ).then(() => {
          onFieldsUpdate()
        })
      } else {
        createUserDefinedFieldsValue(projectId, updatedData as IMetaValue).then(
          () => {
            onFieldsUpdate()
          },
        )
      }
    } else {
      console.error('did not find user defined field to update')
    }
  }

  return (
    <InlineComponentsWrapper
      padding={padding ? 'left' : undefined}
      title={title}
      labelWidth={'w-36'}
      loading={loading}
      border={border}
    >
      {fields.length > 0 ? (
        fields.map((field, index) => {
          return field.data_type === 'time' ? (
            <DateTimeInlineInputComponent
              key={index}
              includeTime={isEqual(
                field.pattern,
                metadataTimeTypes['dateTime'].pattern,
              )}
              onValueSubmitted={(value) => {
                onFieldValueUpdate(field.user_defined_field_id, value)
              }}
              label={field.name}
              selectedTime={`${field.data_value ?? ''}`}
              cancelButton={!field?.is_required}
              disabled={disabled}
            />
          ) : field.data_type === 'string' ? (
            <TextInlineInputCompontent
              key={index}
              label={field.name}
              value={field.data_value as string}
              onValueSubmitted={(value) => {
                onFieldValueUpdate(field.user_defined_field_id, value)
              }}
              disabled={disabled}
            />
          ) : field.data_type === 'enum' ? (
            <SelectorInlineInputComponent<string>
              items={
                Array.isArray(field.pattern)
                  ? field.pattern
                      .sort((patternA, patternB) =>
                        patternA?.localeCompare(patternB),
                      )
                      .map((pattern: string) => ({
                        id: pattern,
                        name: pattern,
                      }))
                  : []
              }
              label={field.name}
              onValueSubmitted={(value) => {
                onFieldValueUpdate(field.user_defined_field_id, value)
              }}
              getItemLabel={(field) => field?.id}
              selectedId={field?.data_value as string}
              initialItem={{ id: field.data_value as string }}
              cancelButton={true}
              inspectorPanel={inspectorPanel}
              disabled={disabled}
            />
          ) : field.data_type === 'boolean' ? (
            <BooleanInlineInputComponent
              label={field.name}
              onValueSubmitted={(value) => {
                onFieldValueUpdate(
                  field.user_defined_field_id,
                  value ? 'true' : 'false',
                )
              }}
              value={field.data_value === 'true'}
              showCheckBox
              disabled={disabled}
            />
          ) : field.data_type === 'multi_enum' ? (
            <MultiSelectorInlineInputComponent<string, IStringDropDownItem>
              items={
                Array.isArray(field.pattern)
                  ? field.pattern
                      .sort((patternA, patternB) =>
                        patternA?.localeCompare(patternB),
                      )
                      .map((pattern: string) => ({
                        id: pattern,
                        name: pattern,
                      }))
                  : []
              }
              label={field.name}
              onValueSubmitted={(value) => {
                onFieldValueUpdate(
                  field.user_defined_field_id,
                  undefined,
                  value,
                )
              }}
              getItemLabel={(field) => field?.name ?? ''}
              selectedIds={field?.data_value_list ?? []}
              initialItems={
                field?.data_value_list
                  ? field?.data_value_list
                      .sort((dataA, dataB) => dataA?.localeCompare(dataB))
                      .map((data: string) => ({
                        id: data,
                        name: data,
                      }))
                  : []
              }
              cancelButton={true}
              disabled={disabled}
            />
          ) : (
            <span />
          )
        })
      ) : (
        <div className="flex items-center">
          <MaterialIcon className="text-base text-blue mr-1" icon="info" />
          <span className="text-gray-600 text-[14px]">
            {required
              ? t('no_required_metadata_found')
              : t('no_optional_metadata_found')}
          </span>
        </div>
      )}
    </InlineComponentsWrapper>
  )
}

export default MetaDataFieldsForPage
