import AttachFile from '@icons/attach_file.svg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { useSubcontractorFileContainers } from 'src/query/fdvu/subcontractor'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { StatusEnum } from 'src/utility/statusEnums'

interface IAssignedFileContainerList {
  selectedFileContainerId?: number
  setSelectedFileContainer: (fileContainer: IFdvuFileContainer) => void
  hideInReview: boolean
}

const AssignedFileContainerList: FC<IAssignedFileContainerList> = ({
  selectedFileContainerId,
  setSelectedFileContainer,
  hideInReview,
}) => {
  const { data: fileContainers = [] } =
    useSubcontractorFileContainers(hideInReview)

  return (
    <div className={'rounded-sm border bg-white'}>
      <div className={'mb-10'}>
        {fileContainers.map((fileContainer, index) => (
          <div
            key={fileContainer.id}
            onClick={() => {
              setSelectedFileContainer(fileContainer)
            }}
            className={twMerge(
              'flex justify-between w-full text-gray-700 cursor-pointer p-2 hover:bg-blue-100',
              selectedFileContainerId === fileContainer.id &&
                'bg-blue-200 font-medium',
              index >= fileContainers.length - 1 && 'rounded-b-sm',
              index === 0 && 'rounded-t-sm',
            )}
          >
            <div className={'flex items-center gap-2'}>
              <div className="min-w-24 flex flex-col">
                <FileContainerSubcontractorStatus
                  status={fileContainer.status}
                />
              </div>
              <p>{fileContainer.recordId}</p>
              <p>{fileContainer.name}</p>
              {fileContainer.projectFiles.length > 0 && (
                <div className={'text-xs text-blue-root flex'}>
                  <AttachFile className={'fill-blue-root text-lg'} />
                  <span className="font-medium">
                    {fileContainer.projectFiles.length}
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const FileContainerSubcontractorStatus: FC<{
  status: StatusEnum
}> = ({ status }) => {
  const { t } = useTranslation()

  const getBadgeConfig = () => {
    switch (status) {
      case StatusEnum.COMPLETED:
        return {
          color: BadgeColor.GREEN,
          text: t('completed'),
        }
      case StatusEnum.DELIVERED:
        return {
          color: BadgeColor.BLUE,
          text: t('delivered'),
        }
      case StatusEnum.IN_PROGRESS:
        return {
          color: BadgeColor.GREEN,
          text: t('in_review'),
        }
      case StatusEnum.DECLINED:
        return {
          color: BadgeColor.RED,
          text: t('declined'),
        }
      default:
        return { color: BadgeColor.YELLOW, text: t('open') }
    }
  }
  const badgeConfig = getBadgeConfig()

  return <Badge text={badgeConfig.text} color={badgeConfig.color} />
}

export default AssignedFileContainerList
