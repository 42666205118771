import DoNotDisturbOn from '@icons/do_not_disturb_on.svg'
import {
  createColumnHelper,
  ColumnDef,
  ColumnFiltersState,
} from '@tanstack/react-table'
import { capitalize } from 'lodash'
import * as React from 'react'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useProjectId from 'src/components/hooks/useProjectId'
import DocumentInspectorPanel from 'src/document/components/Inspector/DocumentInspectorPanel'
import { IFileContainer } from 'src/document/types/IFileContainer'
import { useConfirmWithCommentModal } from 'src/hooks/useConfirmModal'
import SelectFileContainerModal from 'src/page/system/SelectFileContainerModal'
import { queryClient } from 'src/query/client'
import {
  fileContainersKey,
  useCompactFileContainersWithPagination,
} from 'src/query/documents/fileContainer'
import { useTestDocumentTypes } from 'src/query/test'
import { editDocument, reviseDocument } from 'src/service/FileContainerService'
import {
  defaultTextColumn,
  defaultDateColumnObject,
  styleClass,
} from 'src/ui-elements/Table/Columns'
import Table from 'src/ui-elements/Table/Table'
import { styleClass as headerStyle } from 'src/ui-elements/Table/TableColumn'
import {
  stepColumn,
  documentStatusColumn,
  testDocumentTypeColumn,
} from 'src/ui-elements/Table/module-columns/DocumentModuleColumns'
import Button from 'src/ui-elements/button/Button'
import { ButtonType, ButtonSize } from 'src/ui-elements/button/ButtonEnums'

interface IInspectorPanelFileContainerList {
  parentFilter: Record<string, number[]>
  defaultFilter?: ColumnFiltersState
  addFileContainers?: (fileContainerIds: number[]) => Promise<void>
  removeFileContainers?: (fileContainerIds: number[]) => void
  refetchParent?: () => void
}

const InspectorPanelFileContainerList: FC<IInspectorPanelFileContainerList> = ({
  parentFilter,
  defaultFilter,
  addFileContainers,
  removeFileContainers,
  refetchParent,
}) => {
  const { t } = useTranslation()
  const [selectedFileContainerId, setSelectedFileContainerId] = useState<
    number | undefined
  >(undefined)
  const fileContainerColumnHelper = createColumnHelper<IFileContainer>()
  const projectId = useProjectId()
  const { data: testDocumentTypes = [] } = useTestDocumentTypes()
  const { control, trigger, watch } = useForm<{ comment: string }>()
  const { confirm } = useConfirmWithCommentModal(control, trigger)

  const refetch = () => {
    queryClient.invalidateQueries({ queryKey: [fileContainersKey] })
    refetchParent?.()
  }

  const [showAddFileContainersModal, setShowAddFileContainersModal] =
    useState(false)

  const onFieldChange = (id: number, updateDoc: Partial<IFileContainer>) => {
    const doc = { ...updateDoc, id }
    editDocument(projectId, id, doc).then(refetch)
  }

  const toggleRevision = async (
    e: React.MouseEvent,
    id: number,
    isRevoke: boolean,
  ) => {
    e.stopPropagation()
    const confirmed = await confirm({
      title: isRevoke ? t('revoke_revision_request') : t('revision_request'),
      message: isRevoke
        ? t('revoke_revision_request_action')
        : t('report_revision_request_action'),
      iconColor: 'fill-blue-root',
    })
    if (!confirmed) return
    reviseDocument(projectId, id, watch('comment')).then(refetch)
  }

  const columns = [
    fileContainerColumnHelper.accessor('status', {
      id: 'revise-button',
      size: 120,
      header: () => (
        <div className={twMerge(headerStyle.headerColumn(), 'ml-1 truncate')}>
          {t('revise_document')}
        </div>
      ),
      cell: (props) => {
        const isRevoke = props.cell.getValue() === 'needs_revision'
        return (
          <div
            className={twMerge(styleClass.cell, 'w-full flex justify-center')}
          >
            <Button
              type={isRevoke ? ButtonType.DANGER : ButtonType.SECONDARY}
              size={ButtonSize.NARROW}
              onClick={(e) => toggleRevision(e, +props.row.id, isRevoke)}
            >
              {isRevoke ? t('revoke') : t('needs_revision')}
            </Button>
          </div>
        )
      },
    }),
    fileContainerColumnHelper.accessor('record_id', {
      ...defaultTextColumn<IFileContainer>('record_id', {
        name: capitalize(t('document_number')),
      }),
      size: 80,
    }),
    fileContainerColumnHelper.accessor('name', {
      ...defaultTextColumn<IFileContainer>('name', {
        name: capitalize(t('document_name')),
      }),
      size: 80,
    }),
    fileContainerColumnHelper.accessor(
      'test_document_type',
      testDocumentTypeColumn(onFieldChange, testDocumentTypes, t),
    ),
    fileContainerColumnHelper.accessor('status', documentStatusColumn(t)),
    fileContainerColumnHelper.accessor(
      'overdue_date',
      defaultDateColumnObject<IFileContainer>(
        'overdue_date',
        { name: t('deadline') },
        (item) => (item.current_step?.progress || 100) < 99,
      ),
    ),
    fileContainerColumnHelper.accessor('current_step', stepColumn(t)),
  ] as ColumnDef<IFileContainer>[]

  return (
    <>
      <Table
        name="FileContainerInspectorPanel"
        columns={columns}
        onRowClick={(row: IFileContainer) => setSelectedFileContainerId(row.id)}
        onPreviewClick={(row: IFileContainer) =>
          setSelectedFileContainerId(row.id)
        }
        useDataQuery={useCompactFileContainersWithPagination}
        initialFilter={parentFilter}
        defaultFilter={defaultFilter}
        disableTableKeeper={!!defaultFilter}
        deleteIcon={DoNotDisturbOn}
        onDeleteClick={
          removeFileContainers
            ? (row) => {
                removeFileContainers([row.id])
                refetch()
              }
            : undefined
        }
        tableButtons={(selectedDocuments) => ({
          customButtons:
            addFileContainers && removeFileContainers
              ? [
                  <Button
                    key="add_documents"
                    title={t('add_documents')}
                    className={'h-8'}
                    type={Button.ButtonType.PRIMARY}
                    onClick={() => setShowAddFileContainersModal(true)}
                  >
                    {capitalize(t('add_documents'))}
                  </Button>,
                  <Button
                    key="remove_documents"
                    title={t('remove_documents')}
                    type={Button.ButtonType.DANGER}
                    className={'p-1 w-8 h-8'}
                    disabled={selectedDocuments.length < 1}
                    onClick={() => {
                      removeFileContainers(
                        selectedDocuments.map((system) => system.id),
                      )
                      refetch()
                    }}
                  >
                    <DoNotDisturbOn
                      className={twMerge(
                        'text-xl',
                        selectedDocuments.length > 0 && 'fill-red',
                      )}
                    />
                  </Button>,
                ]
              : undefined,
        })}
      />
      {selectedFileContainerId && (
        <DocumentInspectorPanel
          documentId={selectedFileContainerId}
          open={true}
          onClose={() => {
            setSelectedFileContainerId(undefined)
          }}
        />
      )}
      {showAddFileContainersModal && addFileContainers && (
        <SelectFileContainerModal
          onSelectFileContainers={async (ids) => {
            await addFileContainers(ids)
            refetch()
            setShowAddFileContainersModal(false)
          }}
          closeModal={() => setShowAddFileContainersModal(false)}
        />
      )}
    </>
  )
}

export default InspectorPanelFileContainerList
