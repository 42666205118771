import { useState } from 'react'

type AllowedNames = 'edit' | 'details' | 'delete' | 'new' | 'inspect' | 'reload'

const useBoolean = (names: AllowedNames[]) => {
  const [state, setState] = useState<Record<AllowedNames, boolean>>(() =>
    names.reduce<Record<AllowedNames, boolean>>(
      (acc, name) => {
        acc[name] = false
        return acc
      },
      {} as Record<AllowedNames, boolean>,
    ),
  )

  const setTrue = (name: AllowedNames) => {
    setState((prevState) => ({
      ...prevState,
      [name]: true,
    }))
  }

  const setFalse = (name: AllowedNames) => {
    setState((prevState) => ({
      ...prevState,
      [name]: false,
    }))
  }

  const toggle = (name: AllowedNames) => {
    setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }

  const value = (name: AllowedNames) => state[name]

  return { value, setTrue, setFalse, toggle } as const
}

export default useBoolean
