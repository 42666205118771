import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deleteDocument } from 'src/service/DocumentService'
import { getDocuments } from 'src/service/ImageService'
import { IDocument, IUserData } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import Modal from 'src/ui-elements/modal/Modal'
import { getOrganizationBaseUrl, openFile } from 'src/utility/utils'
import UserIcon from '../user/UserIcon'
import UploadFile from './UploadFile'

interface IDocumentsProps {
  parentType: string
  parentId: number
  projectId: number
  className?: string
  disableEdit?: boolean
}

const DocumentsList = ({
  parentType,
  parentId,
  projectId,
  className,
  disableEdit,
}: IDocumentsProps) => {
  const { t } = useTranslation()
  const [showUploadModal, setUploadModal] = useState(false)
  const [documents, setDocuments] = useState<IDocument[]>([])

  const fetchDocuments = () => {
    getDocuments(projectId, parentType, parentId).then((res) => {
      if (res) {
        setDocuments(res)
      }
    })
  }

  useEffect(() => {
    fetchDocuments()
  }, [parentId, parentType, projectId])

  const toggleUploadModal = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setUploadModal(true)
  }

  const filesUploaded = (_docs: IDocument[]) => {
    setUploadModal(false)
    fetchDocuments()
  }

  const columns: IListColumns[] = [
    {
      name: 'file_name',
      size: '400',
      id: 'fileName',
      dataField: 'fileName||fileUrl',
      cell: (data: { fileName: string; fileUrl: string }) => (
        <div
          className="underline truncate text-blue-root"
          onClick={openFile(data.fileUrl)}
        >
          {data.fileName}
        </div>
      ),
    },
    {
      name: 'created_by',
      size: '250',
      id: 'responsible',
      dataField: 'user',
      sortingField: 'user',
      cell: (user: IUserData) =>
        user && (
          <UserIcon
            userId={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            image_url={user.image_url}
          />
        ),
    },
    {
      name: 'created_at',
      size: '150',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (date) => <span>{moment(date).format('L')}</span>,
    },
    {
      name: 'updated_at',
      size: '150',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const onClose = () => {
    setUploadModal(false)
    fetchDocuments()
  }

  const uploadUrl = (): string => {
    return `${getOrganizationBaseUrl()}/projects/${projectId}/parents/${parentType}/${parentId}/documents`
  }

  const onDeleteFile = async (id: number) => {
    await deleteDocument(projectId, id)
    fetchDocuments()
  }
  return (
    <div className={className}>
      <>
        <List
          actionButton={
            !disableEdit ? (
              <Button type={ButtonType.PRIMARY} onClick={toggleUploadModal}>
                {t('upload')}
              </Button>
            ) : (
              <></>
            )
          }
          columns={columns}
          tableName={'DocumentsUrlList'}
          data={documents}
          itemsPerPage={0}
          disableConfigStore={true}
          disableColumnSelector={true}
          actionMenu={
            !disableEdit
              ? [
                  {
                    name: t('delete'),
                    action: (row: IDocument) => onDeleteFile(row.id),
                  },
                ]
              : []
          }
        />
      </>
      <Modal
        show={showUploadModal}
        title={t('upload')}
        closeModal={onClose}
        size={'w-1/2'}
        maxWidth={800}
      >
        <div className="w-full my-8">
          <UploadFile
            uploadedDocuments={filesUploaded}
            uploadUrl={uploadUrl()}
          />
        </div>
      </Modal>
    </div>
  )
}

export default DocumentsList
