import Add from '@icons/add.svg'
import DoNotDisturbOn from '@icons/do_not_disturb_on.svg'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import DeliverySelectList from 'src/components/process/delivery/DeliverySelectList'
import { useDeliveryColumns } from 'src/components/process/delivery/useDeliveryColumns'
import KeypointChangeLogModal from 'src/components/process/main-process/KeypointChangeLogModal'
import { useFilteredDeliveries } from 'src/query/planning/deliveries'
import {
  addDeliveryDependency,
  removeDeliveryDependency,
  updateDelivery,
} from 'src/service/DeliveryService'
import { IDelivery } from 'src/service/OrgTypes'
import Table from 'src/ui-elements/Table/Table'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import DeliveryInspectorPanel from './DeliveryInspectorPanel'

export interface IDependencyDeliveryProps {
  deliveryId: number
  showDependentOn?: boolean
  tableName: string
  inspectorPanel?: boolean
  initialDependencySelection?: number[]
  disabled?: boolean
}

const DependencyDelivery = ({
  deliveryId,
  showDependentOn = false,
  tableName,
  inspectorPanel = false,
  initialDependencySelection,
  disabled = false,
}: IDependencyDeliveryProps) => {
  const { t } = useTranslation()
  const [showAddDependencyModal, setShowAddDependencyModal] =
    useState<boolean>(false)
  const [selectedDeliveries, setSelectedDeliveries] = useState<number[]>([])
  const [selectedDelivery, setSelectedDelivery] = useState<
    IDelivery | undefined
  >(undefined)
  const [changeLogModal, setChangeLogModal] = useState<boolean>(false)
  const [changeLogReason, setChangeLogReason] = useState<string>('')

  const [openInspectorPanel, setOpenInspection] = useState(false)

  const queryClient = useQueryClient()
  const projectId = useProjectId()

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['deliveries'] })
  }

  const closeModal = () => {
    setShowAddDependencyModal(false)
    setSelectedDeliveries([])
  }

  const onSubmitDependency = async () => {
    await addDeliveryDependency(deliveryId, {
      dependent_on_delivery: showDependentOn ? selectedDeliveries : null,
      dependent_delivery: !showDependentOn ? selectedDeliveries : null,
    })
    setShowAddDependencyModal(false)
    reload()
  }

  const deleteDeliveries = async (deliveryIds: number[]) => {
    await removeDeliveryDependency(
      deliveryId,
      showDependentOn
        ? {
            dependent_on_deliveries: deliveryIds,
          }
        : { dependent_deliveries: deliveryIds },
    )
    reload()
  }

  const handlePreviewClick = (data: IDelivery) => {
    setOpenInspection(true)
    if (data) {
      setSelectedDelivery(data)
    }
  }

  const onDateChange = (delivery: IDelivery, date: string) => {
    setSelectedDelivery({ ...delivery, endTime: date })
    setChangeLogReason('')
    setChangeLogModal(true)
  }

  const onChangeLogModalClose = () => {
    setChangeLogModal(false)
    setSelectedDelivery({} as IDelivery)
  }

  const onChangeLogSubmit = async () => {
    setChangeLogModal((prevState) => !prevState)
    if (selectedDelivery?.id) {
      await updateDelivery(
        { ...selectedDelivery, change_reason: changeLogReason },
        selectedDelivery.id,
      )
      setSelectedDelivery({} as IDelivery)
      reload()
    }
  }

  const { defaultOrdering, columns, inspectorPanelColumnVisibility } =
    useDeliveryColumns({
      reload: reload,
      onDateChange: onDateChange,
      disabled: disabled,
    })

  return (
    <div className={'pl-4'}>
      <Table
        initialColumnVisibility={
          inspectorPanel ? inspectorPanelColumnVisibility : undefined
        }
        name={tableName}
        useDataQuery={useFilteredDeliveries}
        initialFilter={
          showDependentOn
            ? { dependent_delivery_id: deliveryId }
            : { dependent_on_delivery_id: deliveryId }
        }
        onPreviewClick={handlePreviewClick}
        onDeleteClick={
          !disabled ? (delivery) => deleteDeliveries([delivery.id]) : undefined
        }
        deleteIcon={DoNotDisturbOn}
        tableButtons={() => ({
          onBulkDelete: !disabled ? deleteDeliveries : undefined,
          customButtons: !disabled
            ? [
                <Button
                  key="new_dependency"
                  onClick={() => setShowAddDependencyModal(true)}
                  type={ButtonType.PRIMARY}
                >
                  <Add className={'fill-white text-xl'} />
                  {t('new_dependency')}
                </Button>,
              ]
            : [],
        })}
        columns={columns}
        defaultOrdering={defaultOrdering}
      />
      {showAddDependencyModal && (
        <Modal
          show={showAddDependencyModal}
          title={t('select_delivery_you_want_to_add')}
          closeModal={closeModal}
          maxWidth={1200}
        >
          <DeliverySelectList
            onSelectDeliveries={setSelectedDeliveries}
            tableName={'selectDeliveriesForDependencies'}
            filter={{
              not_ids: [deliveryId, ...(initialDependencySelection ?? [])],
            }}
          />

          <ModalFooter>
            <Button
              type={Button.ButtonType.DEFAULT}
              onClick={() => setShowAddDependencyModal(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              disabled={selectedDeliveries.length === 0}
              type={Button.ButtonType.PRIMARY}
              onClick={onSubmitDependency}
            >
              {t('save')}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {openInspectorPanel && selectedDelivery && (
        <DeliveryInspectorPanel
          deliveryId={selectedDelivery?.id ?? 0}
          open={openInspectorPanel}
          onClose={() => setOpenInspection(false)}
          projectId={projectId}
          onUpdate={reload}
        />
      )}
      {changeLogModal && selectedDelivery?.id ? (
        <KeypointChangeLogModal
          show={changeLogModal}
          toggleChangeLogModal={onChangeLogModalClose}
          onChangeLogSubmit={onChangeLogSubmit}
          onChangeReasonLog={setChangeLogReason}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default DependencyDelivery
