import { TableFilter } from 'src/ui-elements/Table/useTable'
import { IDelivery, IPaginateResult } from './OrgTypes'
import { request } from './service-utils'

export interface IDeliveryPaginate {
  pages: number
  total_items: number
  deliveries: IDelivery[]
}

export const newDelivery = (
  delivery: Omit<IDelivery, 'id'>,
  userId: number,
) => {
  return request(`/users/${userId}/deliveries`, 'POST', delivery)
}

export const getProjectDeliveriesGraph = (projectId: number) => {
  return request(`projects/${projectId}/ppus/delivery`, 'GET')
}

export const updateDelivery = (delivery: any, deliveryId: number) => {
  return request(`/deliveries/${deliveryId}`, 'PUT', delivery)
}

export const massMoveDelivery = (projectId: number, body: any) => {
  return request(`/projects/${projectId}/deliveries/mass_update`, 'POST', body)
}

export const getKeypointDeliveriesOnly = (keypointId: number): Promise<any> => {
  return request(`/key_points/${keypointId}/deliveries`, 'GET')
}

export const getDelivery = (
  projectId: number,
  deliveryId: number,
): Promise<IDelivery> => {
  return request(`projects/${projectId}/deliveries/${deliveryId}`, 'GET')
}

export const getDependentOnDeliveries = (
  projectId: number,
  deliveryId: number,
): Promise<IDelivery[]> => {
  return request(
    `projects/${projectId}/deliveries/${deliveryId}/dependent_on_deliveries`,
    'GET',
  )
}

export const getDependentDeliveries = (
  projectId: number,
  deliveryId: number,
): Promise<IDelivery[]> => {
  return request(
    `projects/${projectId}/deliveries/${deliveryId}/dependent_deliveries`,
    'GET',
  )
}

export const getAllProjectDeliveries = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/deliveries`, 'GET')
}

// Add Delivery Dependency
export const addDeliveryDependency = (
  deliveryId: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/deliveries/${deliveryId}/delivery_dependencies`,
    'PUT',
    dependencies,
    true,
  )
}

// Remove Delivery Dependency
export const removeDeliveryDependency = (
  deliveryId: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/deliveries/${deliveryId}/delivery_dependencies/remove`,
    'PUT',
    dependencies,
    true,
  )
}

export const deleteDeliveryById = (deliveryId: number) => {
  return request(`/deliveries/${deliveryId}`, 'DELETE', {}, true)
}

export const getDeliveryChangeLog = (deliveryId: number): Promise<any> => {
  return request(`deliveries/${deliveryId}/change_logs`, 'GET')
}

export const filterDelivery = (
  projectId: number,
  filter: TableFilter,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginateResult<IDelivery>> => {
  return request(
    `projects/${projectId}/deliveries_filter/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getFloatingUserDeliveries = (
  userId: number,
): Promise<IDelivery[]> => {
  return request(`/users/${userId}/floating_deliveries`, 'GET')
}

export const getKeypointFloatingUserDeliveries = (
  userId: number,
  keypointId: number,
): Promise<IDelivery[]> => {
  return request(
    `/users/${userId}/key_points/${keypointId}/floating_deliveries`,
    'GET',
  )
}

export const getFloatingKeypointDeliveries = (
  keypointId: number,
): Promise<IDelivery[]> => {
  return request(`/key_points/${keypointId}/floating_deliveries`, 'GET')
}

export const getImprovementDeliveries = (
  improvementId: number,
): Promise<IDelivery[]> => {
  return request(`/improvements/${improvementId}/deliveries`, 'GET')
}

export const getProjectDeliveryData = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/delivery_data`, 'GET')
}

export const deleteBulkDeliveries = (
  projectId: number,
  data: number[],
): Promise<any> => {
  return request(`projects/${projectId}/deliveries/remove_multiple`, 'POST', {
    ids: data,
  })
}

export const getDeliveryResponsibleCanvasData = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(
    `/projects/${projectId}/delivery_responsible_canvas`,
    'POST',
    data,
  )
}

export const getDeliveryTree = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/deliveries/tree`, 'GET')
}
