import moment from 'moment'
import { ITask } from '../../service/OrgTypes'
import { getTask } from '../../service/TaskService'
import { getMetaDataValues, loadMetaValues } from '../system/SystemUtil'
import { IMetaField } from '../system/system-type-fields/SystemTypeFieldsForm'

export const setMetaDataSection = (fields: IMetaField[], task: ITask) => {
  const metaData = getMetaDataValues(task.meta_data)
  return loadMetaValues(task.id, 'Task', fields, metaData)
}

export const copyValuesFromExistingTask = (task: ITask) => {
  return {
    ...task,
    id: null,
    title: `${task.title}-Copy`,
    deadline: moment(task.deadline),
    optional_fields: setMetaDataSection(
      task.task_type?.optional_fields ?? [],
      task,
    ),
  } as Omit<ITask, 'id'>
}

export const copyTask = async (projectId: number, taskToCopy: ITask) => {
  const task: ITask = await getTask(projectId, taskToCopy.id)
  return copyValuesFromExistingTask(task)
}
