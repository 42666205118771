import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { t } from 'i18next'
import { capitalize } from 'lodash'
import { Moment } from 'moment'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { ICategory } from 'src/components/grid/Grid/types'
import { IStatusTypes } from 'src/components/status-dropdown/TableStatusDropdown'
import TableStatusLabel, {
  IStatusCell,
} from 'src/components/status-dropdown/TableStatusLabel'
import CheckBox from 'src/components/switchHoc/CheckBox'
import SystemSyntaxHierarchyButtons from 'src/components/system/SystemSyntax/SystemSyntaxHierarchyButtons'
import DualTableSelector, {
  IGetAPI,
  IIsOptional,
} from 'src/components/table-ResponsibleEditor/DualTableSelector'
import TableSelectorEditor from 'src/components/table-ResponsibleEditor/TableSelectorEditor'
import TableTextEditor from 'src/components/table-texteditor/TableTextEditor'
import { Discipline } from 'src/components/timeline/types'
import UserIcon from 'src/components/user/UserIcon'
import DateTimeInput from 'src/document/components/DateTimeInput'
import DocListFileCount from 'src/document/components/FolderDocument/DocListFileCount'
import { IProjectFile } from 'src/document/types/IFileContainer'
import SyntaxSearchFilterLevel from 'src/fdvu/components/tabs/standard-requirement/standard-requirement-filter/SyntaxSearchFilterLevel'
import { IFdvuFlow } from 'src/fdvu/types/IFdvuFlow'
import { IFilterObject } from 'src/fdvu/types/IFilterObject'
import { IRequirementFiltered } from 'src/fdvu/types/IRequirement'
import {
  getAllRequirementStatus,
  getRequirementStatusBadgeColor,
} from 'src/fdvu/utils/RequirementStatusUtil'
import {
  IContract,
  IImprovement,
  IKeypoint,
  IMainProcess,
  IMilestone,
  ITag,
  ITeam,
  IUserData,
  IDiscipline,
  IStatus,
  ISystemSetting,
  ITestDocumentType,
  IImage,
} from 'src/service/OrgTypes'
import { getStatusesForType } from 'src/service/SystemStatusService'
import TableColumn from 'src/ui-elements/Table/TableColumn'
import MultiValueListSelector from 'src/ui-elements/Table/cells/MultiValueListSelector'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { ISingleFilter } from 'src/ui-elements/list/ListContextProvider'
import { filterType } from 'src/ui-elements/list/ListTypes'
import { toFixed } from 'src/utility/Utility'
import { StatusEnum } from 'src/utility/statusEnums'
import { classNames, openFile } from 'src/utility/utils'

export const styleClass = {
  cell: classNames('px-1 truncate'),
  editableCell: classNames(
    'hover:bg-blue-root-tab-wash focus:bg-white',
    'px-1 truncate',
    'cursor-text',
  ),
  disciplineCell: classNames('flex flex-row px-1 w-fit'),
}

export interface CustomMeta {
  name: string
  disabled?: boolean
  getFilter?: () => Promise<ISingleFilter[]>

  // Use only if the data in the export is parsed differently than that in the web list
  // For old column definitions, use filterDataField
  field?: string
}

// ==== NEW table columns
type DefaultColumn = {
  record_id: string
  created_at: string
  updated_at: string
  baseline: string
  closed_date: string
  title: string
  description: string
  subject: string
  control_area_title: string
  ['current_revision.name']: string
  ['current_revision.start_time']: string
  ['current_file_container_step.duedate']: string
  ['folder.name']: string
  event: string
  taskType: string
  deadline: string
  document_name: string
  background: string
  current_situation: string
  profitability: string
  ['main_process.name']: string
  [key: string]: string
  ['parent.record_id']: string
}

const defaultHelper = createColumnHelper<DefaultColumn>()

export function defaultTextColumn<T>(
  field: string,
  meta: CustomMeta,
): ColumnDef<T, string | undefined> {
  return {
    id: field,
    size: 150,
    cell: (props) => (
      <span className={styleClass.cell}>{props.getValue()}</span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  }
}

export function defaultFilterColumn<T>(
  field: string,
  meta: CustomMeta,
): ColumnDef<T> {
  return {
    id: field,
    size: 150,
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  }
}

export function defaultEditableTextColumn<T>(
  field: string,
  meta: CustomMeta,
  onChange: (key: string, value: string) => void,
  disabled: boolean = false,
): ColumnDef<T, string | undefined> {
  return {
    id: field,
    size: 150,
    cell: (props) => (
      <TableTextEditor
        value={props.getValue() ?? ''}
        clickToEdit={!disabled}
        disableEdit={disabled}
        onSubmit={(value) => onChange(props.row.id, value)}
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  }
}

export const textColumn = (
  field: string,
  meta: CustomMeta,
  options?: {
    accessorFn?: (row: DefaultColumn) => string
    noFilter?: boolean
    noSort?: boolean
  },
) =>
  defaultHelper.accessor(options?.accessorFn ?? field, {
    id: field,
    size: 150,
    cell: ({ cell }) => (
      <span className={styleClass.cell}>{cell.getValue()}</span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
    enableColumnFilter: options?.noFilter ? false : true,
    enableSorting: options?.noSort ? false : true,
  })

export function defaultDateColumnObject<T>(
  field: string,
  meta: CustomMeta,
  showExpired?: (item: T) => boolean,
  withTime?: boolean,
  onDateChange?: (date: string, item: T) => void,
  validate?: (item: T, newDate?: Moment) => string | undefined,
  disabled?: boolean,
): ColumnDef<T, string | Moment | undefined | null> {
  return {
    id: field,
    size: withTime ? 150 : 120,
    cell: ({ cell, row }) => (
      <DateTimeInput
        inputClassName={
          disabled || !onDateChange ? styleClass.cell : styleClass.editableCell
        }
        width={cell.column.getSize() - 5}
        date={`${cell.getValue() ?? ''}`}
        onSave={(date) => date && onDateChange?.(date, row.original)}
        validate={(value) => validate?.(row.original, value)}
        disabled={disabled || !onDateChange}
        deadline={showExpired?.(row.original)}
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.RANGEDATE,
      processFilter: (val: { start: string; end: string }) => ({
        [field]: val.start && val.end ? [val.start, val.end] : [],
      }),
      ...meta,
    },
  }
}

export const dateColumn = (
  field: string,
  meta: CustomMeta,
  showExpired?: boolean,
  withTime?: boolean,
) =>
  defaultHelper.accessor(
    field,
    defaultDateColumnObject<DefaultColumn>(
      field,
      meta,
      () => !!showExpired,
      withTime,
    ),
  )

type FileColumn = {
  project_files: IProjectFile[]
  ['current_revision.project_files']: IProjectFile[]
}

const fileHelper = createColumnHelper<FileColumn>()

export const fileColumn = (field: keyof FileColumn, meta: CustomMeta) =>
  fileHelper.accessor(field, {
    id: field,
    size: 150,
    cell: ({ cell }) => (
      <DocListFileCount projectFiles={cell.getValue() ?? []} />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    enableSorting: false,
    meta: {
      field: field,
      filterType: filterType.NUMBER,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

// ===================
type NumberColumn = {
  total_units: number
  hours_per_unit: number
  productivity: number
  effective_working_hours: number
  total_working_hours: number
  number_of_workers: number
  duration_in_hours: number
  duration: number
  planned_execution_hours: number
  planned_staffing: number
  average_staffing: number
  delay: number
}

const numberHelper = createColumnHelper<NumberColumn>()

export function defaultNumberColumn<T>(
  field: string,
  meta: CustomMeta,
): ColumnDef<T, number> {
  return {
    id: field,
    size: 150,
    cell: ({ cell }) => (
      <span className={styleClass.cell}>{toFixed(cell.getValue())}</span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.NUMBER,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  }
}

export const numberColumn = (field: keyof NumberColumn, meta: CustomMeta) =>
  numberHelper.accessor(field, defaultNumberColumn(field, meta))

// ===================
type EditableColumn = {
  name: string
  title: string
  record_id: string
  endTime: string
  description: string
  location: string
  deadline: string
  planned_start: string
  planned_end: string
  actual_start: string
  actual_end: string
}
const editableHelper = createColumnHelper<EditableColumn>()

export const editableTextColumn = (
  field: keyof EditableColumn,
  meta: CustomMeta,
  onChange: (key: string, value: string) => void,
  disabled: boolean = false,
) =>
  editableHelper.accessor(field, {
    id: field,
    cell: ({ cell, row }) => {
      return (
        <TableTextEditor
          value={cell.getValue()}
          clickToEdit={!disabled}
          disableEdit={disabled}
          onSubmit={(value) => onChange(row.id, value)}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

export const editableDateColumn = (
  field: keyof EditableColumn,
  meta: CustomMeta,
  onDateChange: (date: string, item: any) => void,
  validate?: (item: any, newDate?: Moment) => string | undefined,
  disabled?: boolean,
) =>
  editableHelper.accessor(field, {
    id: field,
    cell: ({ cell, row }) => {
      return (
        <DateTimeInput
          inputClassName={styleClass.editableCell}
          width={cell.column.getSize() - 5}
          date={`${cell.getValue() ?? ''}`}
          onSave={(date) => date && onDateChange(date, row.original)}
          validate={(value) => validate?.(row.original, value)}
          disabled={disabled}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.RANGEDATE,
      processFilter: (val: { start: string; end: string }) => ({
        [field]: [val.start, val.end],
      }),
      ...meta,
    },
  })

type BooleanColumn = {
  is_active: boolean
  is_in_bim: boolean
  is_testable: boolean
  isActive: boolean
  active: boolean
}
const booleanHelper = createColumnHelper<BooleanColumn>()

export const booleanColumn = (
  field: keyof BooleanColumn,
  meta: CustomMeta,
  onChange: (key: string, value: boolean) => void,
  disableEdit?: boolean,
) =>
  booleanHelper.accessor(field, {
    id: field,
    cell: ({ cell, row }) => {
      return (
        <div
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
          }}
          className={'pl-2 flex items-center'}
        >
          <CheckBox
            disable={disableEdit}
            disableTab={true}
            onChange={(value: boolean) => onChange(row.id, value)}
            valueProp={cell.getValue()}
          />
        </div>
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.BOOLEAN,
      processFilter: (val) => ({
        [field]: val,
      }),
      ...meta,
    },
  })

type MultiValueColumn = {
  systems: { id: number }[]
  requirements: { id: number }[]
  file_containers: { id: number }[]
  system_relations: { id: number }[]
}

const multiValueHelper = createColumnHelper<MultiValueColumn>()

export const multiValueListSelectorColumn = (
  field: keyof MultiValueColumn,
  meta: CustomMeta,
  identifierField: string,
  onClick?: (selectedIds: number[], id: number) => void,
) =>
  multiValueHelper.accessor(field, {
    id: field,
    size: 250,
    enableSorting: false,
    cell: ({ cell, row }) => (
      <MultiValueListSelector
        values={cell.getValue()}
        field={identifierField}
        onClick={onClick}
        selectedId={+row.id}
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

type MultiFilterOptionsColumnType = {
  contract: IContract
  mile_stone: IMilestone
  team: ITeam
  category: ICategory
  flow: IFdvuFlow
}

const multiFilterOptionsHelper =
  createColumnHelper<MultiFilterOptionsColumnType>()

export const multiFilterOptionsColumn = (
  field: keyof MultiFilterOptionsColumnType,
  meta: CustomMeta,
  displayFields: string[],
) =>
  multiFilterOptionsHelper.accessor(field, {
    id: field,
    size: 250,
    cell: ({ cell }) => {
      return cell.getValue() ? (
        <span className={styleClass.cell}>
          {displayFields.map((value) => `${cell.getValue()[value]}` + ' ')}
        </span>
      ) : (
        ''
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: val,
      }),
      ...meta,
    },
  })

type MultiFilterOptionsColumnTypeEditable = {
  key_point: IKeypoint
  tag: ITag
  improvement: IImprovement
  discipline: IDiscipline
  priority: { name: string; value: string; active: boolean }
  test_work_group_status: IStatus
  test_document_type: ITestDocumentType
}

const multiFilterOptionsEditableHelper =
  createColumnHelper<MultiFilterOptionsColumnTypeEditable>()

export const multiFilterOptionsColumnEditable = <T extends object>(
  field: keyof MultiFilterOptionsColumnTypeEditable,
  meta: CustomMeta,
  displayFields: string[],
  getData: () => Promise<T>,
  onChange: (key: number, value: string) => void,
  disabled?: boolean,
  transformDisplayValue?: (value: any) => string,
) => {
  return multiFilterOptionsEditableHelper.accessor(field, {
    id: field,
    size: 250,
    cell: ({ cell, row }) => (
      <TableSelectorEditor
        rowId={row.original['id']}
        selected={cell.getValue()}
        getData={getData}
        dataFields={displayFields}
        isUserSelector={false}
        onDataSelected={(value) => onChange(row.original['id'], value.id)}
        displayContent={
          cell.getValue() ? (
            transformDisplayValue ? (
              transformDisplayValue(cell.getValue())
            ) : (
              <span className={styleClass.cell}>
                {displayFields.map(
                  (value) => `${cell.getValue()[value]}` + ' ',
                )}
              </span>
            )
          ) : (
            ''
          )
        }
        disableEdit={disabled}
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })
}

export function defaultMultiFilterColumn<T, S>(
  field: string,
  meta: CustomMeta,
  displayFields: string[],
  getData: () => Promise<S>,
  onChange: (key: number, value: string) => void,
  disabled?: boolean,
  transformDisplayValue?: (value: any) => string,
): ColumnDef<T, unknown> {
  return {
    id: field,
    size: 250,
    cell: ({ cell, row }) => (
      <TableSelectorEditor
        rowId={row.original['id']}
        selected={cell.getValue()}
        getData={getData}
        dataFields={displayFields}
        isUserSelector={false}
        onDataSelected={(value) => onChange(row.original['id'], value.id)}
        displayContent={
          cell.getValue() ? (
            transformDisplayValue ? (
              transformDisplayValue(cell.getValue())
            ) : (
              <span className={styleClass.cell}>
                {displayFields.map(
                  (value) => `${cell.getValue()?.[value] ?? ''}` + ' ',
                )}
              </span>
            )
          ) : (
            ''
          )
        }
        disableEdit={disabled}
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  }
}

type DualSelectorColumnTypes = {
  responsible: IUserData
  discipline: Discipline
  main_process: IMainProcess
  team: ITeam
}

const dualSelectorColumnHelper = createColumnHelper<DualSelectorColumnTypes>()

export const dualSelectorColumn = <T extends object>(
  field: keyof DualSelectorColumnTypes,
  secondaryField: keyof DualSelectorColumnTypes,
  meta: CustomMeta,
  displayFields: string[],
  displayFieldsSecondary: string[],
  getAPI: IGetAPI,
  onDataSelected: (id: number, primary: number, secondary: number) => void,
  displayContent?: (primaryObject?: T) => JSX.Element,
  fieldsOptional: IIsOptional = { primary: true, secondary: true },
  primaryUserSelector: boolean = false,
  secondaryUserSelector: boolean = false,
  disable = false,
) =>
  dualSelectorColumnHelper.accessor(field, {
    id: field,
    size: 250,
    cell: ({ cell, row }) => (
      <DualTableSelector
        disableEdit={disable}
        rowId={row.original['id']}
        selected={{
          primary: row.original[field],
          secondary: row.original[secondaryField],
        }}
        getAPI={getAPI}
        label={{ primary: field, secondary: secondaryField }}
        dataFields={{
          primary: displayFields,
          secondary: displayFieldsSecondary,
        }}
        onDataSelected={onDataSelected}
        displayContent={
          displayContent ? (
            displayContent(row.original[field] as T)
          ) : cell.getValue() ? (
            <span>
              {displayFields.map((value) => `${cell.getValue()[value]}` + ' ')}
            </span>
          ) : (
            ''
          )
        }
        fieldsOptional={fieldsOptional}
        primaryUserSelector={primaryUserSelector}
        secondaryUserSelector={secondaryUserSelector}
      />
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

type StatusColumnType = {
  status: IStatusCell
}

const statusColumnHelper = createColumnHelper<StatusColumnType>()

export function planningDefaultStatusColumn<T>(
  field: string,
  dataFields: (keyof IStatusCell)[],
  meta: CustomMeta,
  statusTypes: IStatusTypes[],
  onStatusSelect?: (
    statusTypes: IStatusTypes,
    key: number,
    cell: IStatusCell,
  ) => void,
  selectColor?: (cell: IStatusCell) => BadgeColor,
): ColumnDef<T, string> {
  return {
    id: field,
    size: 130,
    cell: ({ row }) => {
      const statusCell = dataFields.reduce((acc, field) => {
        acc[field] = row.original[field]
        return acc
      }, {})
      return (
        <TableStatusLabel
          disableEdit={!onStatusSelect}
          statusTypes={statusTypes}
          cell={statusCell as IStatusCell}
          rowId={row.original['id']}
          onStatusSelect={onStatusSelect}
          showDropdownAlways={true}
          selectColor={selectColor}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      getFilter: () => {
        return new Promise((resolve) => {
          resolve(
            statusTypes.map((status) => {
              return {
                value: status.id,
                id: status.id,
                name: status.name,
                active: false,
              }
            }),
          )
        })
      },
      ...meta,
    },
  }
}

export const statusColumn = (
  field: keyof StatusColumnType,
  dataFields: (keyof IStatusCell)[],
  meta: CustomMeta,
  statusTypes: IStatusTypes[],
  onStatusSelect?: (
    statusTypes: IStatusTypes,
    key: number,
    cell: IStatusCell,
  ) => void,
  selectColor?: (cell: IStatusCell) => BadgeColor,
) =>
  statusColumnHelper.accessor(field, {
    id: field,
    size: 130,
    cell: ({ row }) => {
      const statusCell = dataFields.reduce((acc, field) => {
        acc[field] = row.original[field]
        return acc
      }, {})
      return (
        <TableStatusLabel
          disableEdit={!onStatusSelect}
          statusTypes={statusTypes}
          cell={statusCell as IStatusCell}
          rowId={row.original['id']}
          onStatusSelect={onStatusSelect}
          showDropdownAlways={true}
          selectColor={selectColor}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      getFilter: () => {
        return new Promise((resolve) => {
          resolve(
            statusTypes.map((status) => {
              return {
                value: status.id,
                id: status.id,
                name: status.name,
                active: false,
              }
            }),
          )
        })
      },
      ...meta,
    },
  })

type FDVUStatusColumnType = {
  status: IRequirementFiltered['status']
}

const fdvuStatusColumnHelper = createColumnHelper<FDVUStatusColumnType>()

export const fdvuStatusColumn = (
  field: keyof FDVUStatusColumnType,
  meta: CustomMeta,
) =>
  fdvuStatusColumnHelper.accessor(field, {
    id: field,
    size: 130,
    cell: ({ cell }) => {
      return (
        <Badge
          size={Badge.BadgeSize.LARGE}
          text={capitalize(t(cell.getValue().toLowerCase())) ?? ''}
          color={getRequirementStatusBadgeColor(cell.getValue())}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: val,
      }),
      getFilter: () => {
        return new Promise((resolve) => {
          resolve(
            getAllRequirementStatus().map((status) => {
              return {
                value: status,
                name: t(status.toLowerCase()),
                active: false,
              }
            }),
          )
        })
      },
      ...meta,
    },
  })

type SystemStatusColumnType = {
  test_work_group_status: IStatus
  test_system_group_status: IStatus
}

const systemStatusColumnHelper = createColumnHelper<SystemStatusColumnType>()

export const systemStatusColumn = (
  field: keyof SystemStatusColumnType,
  meta: CustomMeta,
  onChange: (key: string, value: string) => void,
  projectId: number,
  parentType: 'System' | 'TestSystemGroup' | 'TestWorkGroup',
  disableEdit?: boolean,
) =>
  systemStatusColumnHelper.accessor(field, {
    id: field,
    size: 130,
    cell: ({ cell, row }) => {
      return (
        <TableSelectorEditor
          rowId={+row.id}
          selected={cell.getValue()}
          getData={() => getStatusesForType(projectId, parentType)}
          dataFields={['name']}
          isUserSelector={false}
          showCancelButton={true}
          onDataSelected={(value) => onChange(row.id, value.id)}
          displayContent={
            <Badge
              status={StatusEnum[cell.getValue()?.status.toUpperCase()]}
              text={cell.getValue()?.name}
            />
          }
          disableEdit={disableEdit}
        />
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: val,
      }),
      ...meta,
    },
  })

type UserColumnType = {
  user: IUserData
  whodunnit: IUserData
  responsible: IUserData
  reporter: IUserData
  created_by: IUserData
  updated_by: IUserData
}

const userColumnHelper = createColumnHelper<UserColumnType>()

export function defaultUserColumn<T>(
  field: string,
  meta: CustomMeta,
): ColumnDef<T, IUserData> {
  return {
    id: field,
    size: 200,
    cell: ({ cell }) =>
      cell.getValue() ? (
        <div className={styleClass.cell}>
          <UserIcon
            userId={cell.getValue().id}
            firstName={cell.getValue().firstName}
            lastName={cell.getValue().lastName}
            image_url={cell.getValue().image_url}
          />
        </div>
      ) : (
        <span />
      ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  }
}

export const userColumn = (field: keyof UserColumnType, meta: CustomMeta) =>
  userColumnHelper.accessor(field, {
    id: field,
    size: 200,
    cell: ({ cell }) =>
      cell.getValue() ? (
        <div className={styleClass.cell}>
          <UserIcon
            userId={cell.getValue().id}
            firstName={cell.getValue().firstName}
            lastName={cell.getValue().lastName}
            image_url={cell.getValue().image_url}
          />
        </div>
      ) : (
        <span />
      ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })

type TaskCountColumnType = {
  total_children: number
}

const taskCountHelper = createColumnHelper<TaskCountColumnType>()

export function defaultChildrenCountColumn<T>(
  meta: CustomMeta,
): ColumnDef<T, number> {
  return {
    id: 'total_children',
    size: 150,
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ cell, row }) => {
      return (
        <span
          className={classNames(
            styleClass.cell,
            row.original['expired_children'] > 0 && 'text-red-two',
          )}
        >
          {row.original['done_children']}/{cell.getValue()}
        </span>
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: 'done_children||total_children',
      ...meta,
    },
  }
}

export const taskCountColumn = (meta: CustomMeta) =>
  taskCountHelper.accessor('total_children', defaultChildrenCountColumn(meta))

type EnumColumnType = {
  item_type: string
  event: string
}

type EnumType = {
  id: string
  name: string
}

const enumColumnHelper = createColumnHelper<EnumColumnType>()

export const enumColumn = (
  field: keyof EnumColumnType,
  meta: CustomMeta,
  enumTypes: EnumType[],
  displayCell: (cellValue?: string) => string,
) =>
  enumColumnHelper.accessor(field, {
    id: field,
    size: 100,
    cell: ({ cell }) => (
      <span className={twMerge(styleClass.cell, 'first-capitalize')}>
        {t(displayCell(cell.getValue()))}
      </span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      getFilter: () => {
        return new Promise((resolve) => {
          resolve(
            enumTypes.map((type) => {
              return {
                value: type.id,
                id: type.id,
                name: type.name,
                active: false,
              }
            }),
          )
        })
      },
      ...meta,
    },
  })

export function defaultEnumColumn<T>(
  id: string,
  meta: CustomMeta,
  enumTypes: EnumType[],
  displayCell?: (cellValue?: string) => string,
): ColumnDef<T, string> {
  return {
    id,
    size: 100,
    cell: ({ cell }) => (
      <span className={twMerge(styleClass.cell, 'first-capitalize')}>
        {t(displayCell ? displayCell(cell.getValue()) : cell.getValue())}
      </span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: id,
      filterType: filterType.DEFAULT,
      processFilter: (val) => ({
        [id]: [val],
      }),
      getFilter: () => {
        return new Promise((resolve) => {
          resolve(
            enumTypes.map((type) => {
              return {
                value: type.id,
                id: type.id,
                name: type.name,
                active: false,
              }
            }),
          )
        })
      },
      ...meta,
    },
  }
}

interface IAttachment {
  name?: string
  url?: string
  link?: string
}

const linkHelper = createColumnHelper<IAttachment>()

export function defaultLinkColumn<T>(
  field: string,
  meta: CustomMeta,
): ColumnDef<T, IImage> {
  return {
    id: field,
    size: 150,
    cell: ({ cell }) => {
      const image = cell.getValue()
      if (!image) return
      return (
        <span
          onClick={openFile(image.url ?? '')}
          className={'text-blue-root underline px-2 overflow-hidden'}
        >
          {image.name ?? image.url}
        </span>
      )
    },
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    enableSorting: false,
    meta: {
      field: field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  }
}

export const linkColumn = (field: keyof IAttachment, meta: CustomMeta) =>
  linkHelper.accessor(field, {
    id: field,
    size: 250,
    cell: ({ cell, row }) => (
      <span
        onClick={openFile(row.original.url ?? '')}
        className={'text-blue-root underline px-3 overflow-hidden'}
      >
        {cell.getValue()}
      </span>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      ...meta,
    },
  })

const mainProcessColumnHelper = createColumnHelper<{
  main_process: { name?: string } | undefined
}>()

export const mainProcessColumn = (field: string, meta: CustomMeta) =>
  mainProcessColumnHelper.accessor(
    (row) => {
      return row.main_process?.name ?? ''
    },
    {
      id: field,
      size: 250,
      cell: ({ cell }) => (
        <span className={styleClass.cell}>{cell.getValue()}</span>
      ),
      header: (props) => (
        <TableColumn column={props.column} table={props.table} />
      ),
      meta: {
        field: field,
        ...meta,
      },
    },
  )

type StandardRequirementFilter = {
  systemSyntaxFilters: IFilterObject[]
}

const standardRequirementFilterColumnHelper =
  createColumnHelper<StandardRequirementFilter>()

export const standardRequirementFilterColumn = (
  field: keyof StandardRequirementFilter,
  syntaxCodes: ISystemSetting[],
  meta: CustomMeta,
) =>
  standardRequirementFilterColumnHelper.accessor(field, {
    id: field,
    size: 250,
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ cell }) => (
      <div
        className={
          'text-d-fontchip text-xs font-normal flex w-full gap-2 overflow-hidden'
        }
      >
        {cell.getValue()?.map((filter, index) => {
          const systemSyntaxCategories =
            syntaxCodes?.find((syntax) => syntax.id === filter.systemSettingId)
              ?.system_syntax_categories ?? []

          return (
            <div
              className="px-2 bg-d-grey-lightest rounded flex items-center gap-2"
              key={index}
            >
              <div className="flex gap-1">
                {Object.keys(filter.syntaxSearchFilters).map((key) => {
                  const syntaxCategory = systemSyntaxCategories.find(
                    (syntax) => syntax.id === parseInt(key),
                  )
                  return (
                    syntaxCategory && (
                      <SyntaxSearchFilterLevel
                        key={key}
                        searchFilters={filter.syntaxSearchFilters[key] ?? []}
                        systemSyntaxItem={syntaxCategory}
                        disabled
                        small
                        onChange={() => {}}
                      />
                    )
                  )
                })}
              </div>
              <div className="flex items-center">
                <span>{t('levels')}: </span>
                <SystemSyntaxHierarchyButtons
                  systemSettingId={filter.systemSettingId}
                  appliesToLevels={filter.appliesToLevels}
                  small
                  disabled
                />
              </div>
            </div>
          )
        })}
      </div>
    ),
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field: field,
      ...meta,
    },
  })
