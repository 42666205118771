import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import UploadFile from 'src/components/document/UploadFile'
import Selector from 'src/components/selectors/Selector'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import {
  getControlArea,
  getControlAreaRooms,
} from 'src/service/ControlAreaService'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { deleteFile } from 'src/service/DocumentService'
import { getUploadDocURl } from 'src/service/FileUploadService'
import {
  IConstructionCanvasControlArea,
  IConstructionWagon,
  IDocument,
  IInputErrorMessage,
  IRoom,
  ISystem,
  ITask,
  IUserData,
} from 'src/service/OrgTypes'
import { getProjectRooms, getRoom } from 'src/service/RoomService'
import { getSystem } from 'src/service/SystemService'
import { createTask } from 'src/service/TaskService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import Input from 'src/ui-elements/input/Input'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { renderDayContents } from 'src/utility/Utility'
import UploadFileList from '../../components/document/UploadFileList'
import { classNames } from '../../utility/utils'
import { FullPageLoader } from '../MUtils'
import BottomActionBtns from '../components/BottomActionBtns'
import MContent from '../components/MContent'
import MControlAreaInfo from '../components/MControlAreaInfo'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MRoomInfo from '../components/MRoomInfo'
import MSystemInfo from '../components/MSystemInfo'
import MVognInfo from './MVognInfo'

export interface IMAddIssueProps {
  projectId: number
  onClose: (done?: boolean) => void
  controlAreaId?: number
  parentDeadline?: moment.Moment
  submitTask: (task: ITask) => void
  wagon?: IConstructionWagon
  parentType?: string
  parentId?: number
  title: string
}

const MAddIssue = ({
  projectId,
  onClose,
  controlAreaId,
  parentDeadline: _parentDeadline,
  submitTask,
  wagon,
  parentType,
  parentId,
  title: issueTitle,
}: IMAddIssueProps) => {
  const styleClass = {
    root: classNames('bg-white', 'w-screen', 'h-screen'),
    container: classNames('px-6', 'py-4', 'overflow-y-auto'),
    errorMessage: classNames('text-red-600', 'ml-2', 'text-xs'),
  }

  useEffect(() => {
    if (parentType === 'System') {
      getSytemData()
      setTask((prev) => {
        return { ...prev, system_id: parentId }
      })
    }
    if (parentType === 'Room') {
      getSelectedRoom()
      getItems('rooms')
      setTask((prev) => {
        return { ...prev, location_id: parentId }
      })
    }
    if (parentType === 'ControlArea') {
      getSelectedControlArea()
    }
  }, [])

  const getSytemData = async () => {
    if (parentId && +parentId > 0) {
      const system = await getSystem(+parentId)
      setSelectedSystem(system ? system : ({} as ISystem))
    }
  }

  const getSelectedRoom = async () => {
    if (parentId && +parentId > 0) {
      const room = await getRoom(+parentId)
      setSelectedRoom(room)
    }
  }

  const getSelectedControlArea = async () => {
    if (parentId && +parentId > 0) {
      const controlArea = await getControlArea(+parentId)
      setSelectedControlArea(controlArea)
    }
  }

  const { t } = useTranslation()
  const [task, setTask] = useState<ITask>({} as ITask)
  const [fouces, setFocues] = useState<string>('')
  const [selectLoading, setSelectLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any>({
    disciplines: [],
    users: [],
    rooms: [],
    systems: [],
  })
  const [errorMessages, setErrorMessages] = useState<IInputErrorMessage[]>([])
  const [documents, setDocuments] = useState<IDocument[]>([])
  const userContext = useContext(UserContext)
  const [selectedRoom, setSelectedRoom] = useState<IRoom>({} as IRoom)
  const [selectedSystem, setSelectedSystem] = useState<ISystem>({} as ISystem)
  const [selectedControlArea, setSelectedControlArea] =
    useState<IConstructionCanvasControlArea>(
      {} as IConstructionCanvasControlArea,
    )

  const setDisciplineUserSync = (val: { responsible_id: number }) => {
    if (val.responsible_id && val.responsible_id > 0) {
      const selectedUser = items.users.find(
        (user: any) => user.id === val.responsible_id,
      ) as IUserData
      const disciplinesVal =
        selectedUser && selectedUser.disciplines
          ? selectedUser.disciplines.filter((d) => d.project_id === projectId)
          : undefined
      const discipline = disciplinesVal
        ? disciplinesVal.find((v) => v.id === task.discipline_id)
        : undefined
      const selectedDispline = discipline
        ? discipline
        : disciplinesVal
          ? disciplinesVal[0]
          : undefined
      if (selectedDispline) {
        if (items.disciplines.length === 0) {
          getItems('disciplines')
        }
        val['discipline_id'] = selectedDispline.id
        setTask(val as ITask)
        clearAnErrorMessage('discipline_id')
      }
    }
  }

  const getItems = async (itemName: string, disciplineId?: number) => {
    let it: any[] = []
    const copyItem = { ...items }
    setSelectLoading(true)
    switch (itemName) {
      case 'disciplines':
        it = await getProjectDisciplines(projectId)
        break
      case 'users':
        const discId = disciplineId
          ? disciplineId
          : task.discipline_id && task.discipline_id > 0
            ? task.discipline_id
            : undefined
        it = discId
          ? await getDisplineUsers(discId)
          : await getProjectUsersWithDisciplines(projectId)
        break
      case 'rooms':
        it = controlAreaId
          ? await getControlAreaRooms(controlAreaId)
          : wagon?.control_area_id
            ? await getControlAreaRooms(wagon?.control_area_id)
            : parentType === 'Room' && projectId
              ? await getProjectRooms(projectId)
              : []
        break
    }
    copyItem[itemName] = it
    setItems(copyItem)
    setSelectLoading(false)
  }

  const onChangeInput = (field: string, value: string | number) => {
    const taskTemp = { ...task }
    taskTemp[field] = value
    setTask(taskTemp)
    if (field === 'discipline_id') {
      taskTemp['responsible_id'] = 0
      getItems('users', Number(value))
      setTask(taskTemp)
    }
    if (field === 'responsible_id') {
      setDisciplineUserSync(taskTemp)
    }
    clearAnErrorMessage(field)
  }

  const clearAnErrorMessage = (input: string) => {
    const copyErrMesg = [...errorMessages]
    const index = copyErrMesg.findIndex((er) => er.for === input)
    if (index > -1) {
      copyErrMesg.splice(index, 1)
    }
    setErrorMessages(copyErrMesg)
  }

  const getErrorMessage = (input: string): string => {
    const error = errorMessages?.find((err) => err.for === input)
    return error ? error.errorMessage : ''
  }

  const filesUploaded = (docs: IDocument[]) => {
    setDocuments(documents.concat(docs))
  }

  const updateUploadedFiles = (docs: IDocument[]) => {
    setDocuments(docs)
  }

  const onCloseModal = () => {
    documents.forEach((item) => {
      deleteFile({ file_url: item.fileUrl }).catch((err) => console.error(err))
    })

    setDocuments([])
    onClose(false)
  }

  const makeErrorMessage = (
    errorMessage: string,
    input: string,
  ): IInputErrorMessage => {
    return { for: input, errorMessage }
  }

  const onRoomClear = () => {
    const aTask = { ...task }
    aTask.location_id = undefined
    setTask(aTask)
  }

  const onSubmit = (e: any) => {
    let errorVal = false
    e.preventDefault()
    e.stopPropagation()
    setLoading(true)
    setErrorMessages([])
    const errors = []
    const { deadline, title, discipline_id, responsible_id } = task

    if (!deadline) {
      errors.push(
        makeErrorMessage(
          t('select_w_param', { param: t('deadline') }),
          'deadline',
        ),
      )
      errorVal = true
    }

    if (!discipline_id) {
      errors.push(
        makeErrorMessage(
          t('select_w_param', { param: t('discipline') }),
          'discipline_id',
        ),
      )
      errorVal = true
    }

    if (!responsible_id) {
      errors.push(
        makeErrorMessage(
          t('select_w_param', { param: t('responsible') }),
          'responsible_id',
        ),
      )
      errorVal = true
    }

    if (!title) {
      errors.push(
        makeErrorMessage(t('fill_out_w_param', { param: t('title') }), 'title'),
      )
      errorVal = true
    }

    if (!errorVal) {
      const aTask = {
        ...task,
        project_id: projectId,
        status: 'open',
        taskType: 'Aksjon',
        documents,
      }
      if (parentId && parentType) {
        aTask.construction_locomotive_id =
          parentType === 'ConstructionLocomotive' ? parentId : undefined
        aTask.control_area_id =
          parentType === 'ControlArea'
            ? parentId
            : parentType === 'Room'
              ? selectedRoom?.control_area_id
              : parentType === 'ConstructionLocomotive'
                ? wagon?.control_area_id
                : undefined
        aTask.parent_id = parentId
        aTask.parent_type = parentType
        createTask(aTask).then(() => {
          onClose(true)
        })
      } else {
        submitTask(aTask)
        onClose(true)
      }
    } else {
      setErrorMessages(errors)
      setLoading(false)
    }
  }

  return (
    <MPage bgColor={'white'}>
      <>
        <MHeader
          showHomeBtn={true}
          title={issueTitle}
          showUser={false}
          user={userContext.state.user}
        />
        <div className={styleClass.container}>
          {loading ? (
            <FullPageLoader />
          ) : (
            <>
              <MContent>
                <>
                  <div className={'w-full md:w-1/2'}>
                    {wagon && !isEmpty(wagon) && (
                      <MVognInfo noPadding={true} wagon={wagon} />
                    )}
                    {parentType === 'Room' && !isEmpty(selectedRoom) && (
                      <MRoomInfo noPadding={true} room={selectedRoom} />
                    )}
                    {parentType === 'System' && !isEmpty(selectedSystem) && (
                      <MSystemInfo noPadding={true} system={selectedSystem} />
                    )}
                    {parentType === 'ControlArea' &&
                      !isEmpty(selectedControlArea) && (
                        <MControlAreaInfo
                          noPadding={true}
                          controlArea={selectedControlArea}
                        />
                      )}
                  </div>
                  {getErrorMessage('deadline') ? (
                    <label className={styleClass.errorMessage}>
                      {getErrorMessage('deadline')}
                    </label>
                  ) : null}
                  <div className={'w-full mt-2 mb-6'}>
                    <SingleDatePicker
                      firstDayOfWeek={1}
                      date={task.deadline ? moment(task.deadline) : null}
                      onDateChange={(date) =>
                        onChangeInput(
                          'deadline',
                          date && date.isValid() ? date.format() : '',
                        )
                      }
                      renderDayContents={renderDayContents}
                      focused={fouces === 'deadline'}
                      onFocusChange={(focused) =>
                        setFocues(focused.focused ? 'deadline' : '')
                      }
                      id="Starttidspunkt"
                      small={true}
                      isOutsideRange={() => false}
                      showDefaultInputIcon={true}
                      noBorder={true}
                      numberOfMonths={1}
                      displayFormat={() =>
                        moment.localeData('no').postformat('DD.MM.YY')
                      }
                      hideKeyboardShortcutsPanel={true}
                      placeholder={t('deadline') + '*'}
                    />
                  </div>

                  <div className={'mb-3'}>
                    <Input
                      label={t('title')}
                      block={true}
                      value={task.title}
                      onChange={(e: any) =>
                        onChangeInput('title', e.target.value)
                      }
                      errorMessage={getErrorMessage('title')}
                      focus={fouces === 'title'}
                      inMobile={true}
                      noPadding={true}
                      hideLabel={true}
                      bgColor={'mobile'}
                      placeholder={t('fill_out_w_param', { param: t('title') })}
                    />
                  </div>

                  <div className={'my-3'}>
                    <Selector
                      items={items.disciplines}
                      selectedItemId={task.discipline_id}
                      onOpenSelectFunction={() => getItems('disciplines')}
                      loading={selectLoading}
                      onSelect={(id) => onChangeInput('discipline_id', id)}
                      label={t('discipline')}
                      dataFields={['shortName', 'name']}
                      required={true}
                      fontSize={'sm'}
                      fontWeight={'bold'}
                      errorMessage={getErrorMessage('discipline_id')}
                      inMobile={true}
                      hidelabel={true}
                      bgColor={'mobile'}
                    />
                  </div>

                  <div className={'my-3'}>
                    <Selector
                      items={items.users}
                      selectedItemId={task.responsible_id}
                      onOpenSelectFunction={() => getItems('users')}
                      loading={selectLoading}
                      onSelect={(id) => onChangeInput('responsible_id', id)}
                      label={t('responsible')}
                      dataFields={['firstName', 'lastName']}
                      required={true}
                      fontSize={'sm'}
                      userSelector={true}
                      fontWeight={'bold'}
                      errorMessage={getErrorMessage('responsible_id')}
                      inMobile={true}
                      hidelabel={true}
                      bgColor={'mobile'}
                    />
                  </div>

                  <div className={'my-3'}>
                    <Selector
                      items={items.rooms}
                      onOpenSelectFunction={() => getItems('rooms')}
                      loading={selectLoading}
                      selectedItemId={task.location_id ? task.location_id : 0}
                      onSelect={(id) => onChangeInput('location_id', id)}
                      label={t('room')}
                      dataFields={['room_name', 'functional_room_number']}
                      fontSize={'sm'}
                      fontWeight={'bold'}
                      inMobile={true}
                      hidelabel={true}
                      bgColor={'mobile'}
                      onCancel={onRoomClear}
                      cancelButton={true}
                    />
                  </div>

                  <div className={'my-3'}>
                    <Textarea
                      label={t('description')}
                      value={task.description}
                      isValid={false}
                      autoFocus={false}
                      onChange={(e: any) =>
                        onChangeInput('description', e.target.value)
                      }
                      block={true}
                      inMobile={true}
                      noPaddingX={true}
                      hidelabel={true}
                      bgColor={'mobile'}
                      placeholder={t('fill_out_w_param', {
                        param: t('description'),
                      })}
                    />
                  </div>

                  <div className={'my-3'}>
                    <UploadFile
                      inMobile={true}
                      uploadUrl={getUploadDocURl(projectId, 'Task')}
                      uploadedDocuments={filesUploaded}
                    />
                  </div>

                  <div className={'mb-20'}>
                    {documents && documents.length > 0 && (
                      <UploadFileList
                        documents={documents}
                        updateDocuments={updateUploadedFiles}
                      />
                    )}
                  </div>
                </>
              </MContent>
              <BottomActionBtns
                onSave={(e) => onSubmit(e)}
                onCancel={onCloseModal}
              />
            </>
          )}
        </div>
      </>
    </MPage>
  )
}

export default MAddIssue
