import Add from '@material-symbols/svg-500/rounded/add.svg'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import CollapsableCard from 'src/components/collapsable-card/CollapsableCard'
import useProjectId from 'src/components/hooks/useProjectId'
import SelectSingleImprovement from 'src/components/improvement/improvement-list/selectSingleImprovement'
import SystemSelectModal from 'src/components/system/system-table/SystemSelectModal'
import { getAllProjectDeliveries } from 'src/service/DeliveryService'
import { ITask } from 'src/service/OrgTypes'
import { getProjectProcesses } from 'src/service/ProcessService'
import { getProjectTags } from 'src/service/TagService'
import ButtonWithDropdownMenu from 'src/ui-elements/button/ButtonWithDropdownMenu'
import IconButtonWithDropdownMenu from 'src/ui-elements/button/IconButtonWithDropDownMenu'
import SelectorWithTable from 'src/ui-elements/page-display/SelectorWithTable'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import { capFirstLetter } from 'src/utility/utils'

interface ITaskRelationsCardProps {
  task: Partial<ITask>
  onChangeInput: ({}: Partial<ITask>) => Promise<void>
  card?: boolean
  inForm?: boolean
}

const TaskRelations = ({
  task,
  onChangeInput,
  card,
  inForm = false,
}: ITaskRelationsCardProps) => {
  const { t } = useTranslation()
  const [showSystemsModal, setShowSystemsModal] = useState(false)
  const [showSelectImprovementModal, setShowSelectImprovementModal] =
    useState(false)
  const [showMainProcess, setShowMainProcess] = useState(!!task.main_process_id)
  const [showType, setShowType] = useState(!!task.tag_id)
  const [showDelivery, setShowDelivery] = useState(!!task.delivery_id)
  const projectId = useProjectId()

  const dropDownItems = [
    {
      title: t('system'),
      onClick: () => setShowSystemsModal(true),
      active: !task.system_id,
    },
    {
      title: t('improvement'),
      onClick: () => setShowSelectImprovementModal(true),
      active: !task.improvement_id,
    },
    {
      title: t('main_process'),
      onClick: () => setShowMainProcess(true),
      active: !task.main_process_id,
    },
    {
      title: t('type'),
      onClick: () => setShowType(true),
      active: !task.tag_id,
    },
    {
      title: t('delivery'),
      onClick: () => setShowDelivery(true),
      active: !task.delivery_id,
    },
  ]

  const selectedConnections = (
    <>
      {task.improvement_id && (
        <SelectorWithTable
          label={t('improvement')}
          name={task.improvement?.title}
          onShowModal={() => setShowSelectImprovementModal(true)}
          onRemove={() =>
            onChangeInput({ improvement_id: undefined, improvement: undefined })
          }
          gotoLink={
            !inForm
              ? `?modal=improvement&project=${task.project_id}&id=${task.improvement_id}`
              : undefined
          }
          modal
        />
      )}
      {task?.system_id && (
        <SelectorWithTable
          label={t('system')}
          name={`${task.system?.record_id} ${task.system?.name}`}
          onShowModal={() => setShowSystemsModal(true)}
          onRemove={() =>
            onChangeInput({ system_id: undefined, system: undefined })
          }
          gotoLink={
            !inForm
              ? `?modal=system&project=${task.project_id}&id=${task.system_id}`
              : undefined
          }
          modal
        />
      )}
      {(showMainProcess || task.main_process_id) && (
        <InlineComponentsWrapper inputWidth="w-64">
          <SelectorInlineInputComponent
            getItems={() => getProjectProcesses(projectId)}
            label={t('main_process')}
            getItemLabel={(process) => process?.name}
            initialItem={task?.main_process}
            selectedId={task?.main_process_id}
            onValueSubmitted={(val) => {
              if (!val) setShowMainProcess(false)
              onChangeInput({ main_process_id: val })
            }}
            cancelButton={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
      )}
      {(showType || task.tag_id) && (
        <InlineComponentsWrapper inputWidth="w-64">
          <SelectorInlineInputComponent
            getItems={() => getProjectTags(projectId)}
            label={t('type')}
            getItemLabel={(tag) => tag?.name}
            initialItem={task?.tag}
            selectedId={task?.tag_id ?? 0}
            onValueSubmitted={(val) => {
              if (!val) setShowType(false)
              onChangeInput({ tag_id: val })
            }}
            cancelButton={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      )}
      {(showDelivery || task.delivery_id) && (
        <InlineComponentsWrapper inputWidth="w-64">
          <SelectorInlineInputComponent
            getItems={() => getAllProjectDeliveries(projectId)}
            label={t('delivery')}
            getItemLabel={(delivery) => delivery?.name}
            initialItem={task?.delivery}
            selectedId={task?.delivery_id ?? 0}
            onValueSubmitted={(val) => {
              if (!val) setShowDelivery(false)
              onChangeInput({ delivery_id: val })
            }}
            cancelButton={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      )}
    </>
  )

  return (
    <>
      {card ? (
        <CollapsableCard
          title={capFirstLetter(`${t('connections')}`)}
          className="bg-white"
          titleClassName="border-b border-gray-500 pb-2"
          addIcon={true}
          reverseTitleOrder={true}
          CustomIcon={
            dropDownItems.some((item) => item.active) ? (
              <IconButtonWithDropdownMenu
                items={dropDownItems.filter((item) => item.active)}
                className="mr-2 h-4 w-4 bg-blue-100 hover:bg-blue-200"
                icon={<Add className="fill-blue-root h-3 w-3" />}
                showOnLeft
              />
            ) : (
              <></>
            )
          }
        >
          {selectedConnections}
        </CollapsableCard>
      ) : (
        <div className={twMerge('max-w-fit gap-1 pb-4 px-4', inForm && 'px-2')}>
          <ButtonWithDropdownMenu
            className={'my-2 ml-0'}
            items={dropDownItems.filter((item) => item.active)}
            customStyling
            disabled={!dropDownItems.some((item) => item.active)}
          >
            <Add className="w-4 h-4 mr-1 ml-0" />
            {capitalize(t('add_connection'))}
          </ButtonWithDropdownMenu>
          {selectedConnections}
        </div>
      )}
      {showSystemsModal && (
        <SystemSelectModal
          tableName={'selectSystemForTaskRelations'}
          key={projectId}
          isSingleSelect
          onRowClick={(system) => {
            setShowSystemsModal(false)
            onChangeInput({ system_id: system.id, system: system })
          }}
          closeModal={() => setShowSystemsModal(false)}
        />
      )}
      {showSelectImprovementModal && (
        <SelectSingleImprovement
          onSelect={(improvement) => {
            setShowSelectImprovementModal(false)
            onChangeInput({
              improvement_id: improvement.id,
              improvement: improvement,
            })
          }}
          closeModal={() => setShowSelectImprovementModal(false)}
        />
      )}
    </>
  )
}

export default TaskRelations
