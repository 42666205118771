import Cancel from '@icons/cancel.svg'
import Check from '@icons/check.svg'
import HorizontalRule from '@icons/horizontal_rule.svg'
import { useIsFetching, useQueryClient } from '@tanstack/react-query'
import moment from 'moment/moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import UploadProvider from 'src/document/components/FileUpload/context/FileUploadContext'
import { IDocumentHistory } from 'src/document/types/IFileContainer'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { useFileContainerHistorySubContractor } from 'src/query/documents/fileContainerHistory'
import { useCurrentSubcontractor } from 'src/query/fdvu/subcontractor'
import FileList from 'src/subcontractor/components/requirements/FileList'
import FileUpload from 'src/subcontractor/components/requirements/FileUpload'
import FdvuSubcontractorService from 'src/subcontractor/service/SubcontractorService'
import { getAccessToken } from 'src/subcontractor/utils/AccessToken'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Spinner from 'src/ui-elements/loader/Spinner'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import InlineInputLabelComponent from 'src/ui-elements/page-display/inline-components/InlineInputLabelComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { StatusEnum } from 'src/utility/statusEnums'

interface IFileSubmissionPanel {
  selectedFileContainer: IFdvuFileContainer
  hideInReview: boolean
}

const FileSubmissionPanel = ({
  selectedFileContainer,
  hideInReview,
}: IFileSubmissionPanel) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: currentSubcontractor } = useCurrentSubcontractor()

  const refresh = () => {
    queryClient.invalidateQueries({
      queryKey: ['fdvu', 'subcontractor', 'fileContainers', hideInReview],
    })
  }
  const isFetching = useIsFetching({
    queryKey: ['fdvu', 'subcontractor', 'fileContainers', hideInReview],
  })

  const { data: history, refetch: historyRefetch } =
    useFileContainerHistorySubContractor(selectedFileContainer.id, {
      enabled: !!currentSubcontractor,
    })

  const inProgress = selectedFileContainer.status === StatusEnum.IN_PROGRESS
  const canDeliver =
    selectedFileContainer.status === StatusEnum.OPEN ||
    selectedFileContainer.status === StatusEnum.DECLINED

  const deliverDocuments = async () => {
    const status = canDeliver ? StatusEnum.DELIVERED : StatusEnum.OPEN
    await FdvuSubcontractorService.updateDocumentStatus(
      selectedFileContainer.id,
      status,
    )
    refresh()
    await historyRefetch()
  }

  const statusIcon = (history: IDocumentHistory) => {
    const step = history.step ?? history.archived_step
    if (step?.sequence === 1) {
      return (
        <>
          <Check className="text-lg fill-task-blue" />
          <p className={'font-bold text-task-blue'}>{t('delivered')}</p>
        </>
      )
    }
    if (history.closed_by_subcontractor) {
      return (
        <>
          <HorizontalRule className="fill-yellow text-lg" />
          <p className={'font-bold text-yellow-500'}>{t('re_opened')}</p>
        </>
      )
    } else if (!history.closed_by_subcontractor && step?.sequence === 2) {
      return (
        <>
          <Cancel className={`text-xl fill-red`} />
          <p className={'font-bold text-red'}>{t('declined')}</p>
        </>
      )
    }
    return (
      <>
        <HorizontalRule className="fill-green-500 text-lg" />
        <p className={'font-bold text-green-500'}>{t('in_review')}</p>
      </>
    )
  }

  return (
    <div className="w-1/3">
      <div className="bg-white border rounded-sm pb-3 w-full flex flex-col ">
        <div className="flex flex-col bg-grey-light p-1 rounded-t-sm text-sm font-bold">
          {`${selectedFileContainer?.recordId ?? ''} ${selectedFileContainer?.name ?? ''}`}
        </div>
        <InlineComponentsWrapper
          padding="left"
          labelWidth={'w-32'}
          inputWidth={'w-full'}
        >
          <TextInlineInputCompontent
            label="covers_requirements"
            value={selectedFileContainer.requirements
              .map((req) => req.title)
              .join(', ')}
            disabled
          />
          <TextInlineInputCompontent
            label="systems"
            value={selectedFileContainer.systems
              .map((system) => system.recordId)
              .join(', ')}
            disabled
          />
        </InlineComponentsWrapper>
        <div className={'z-0'}>
          <UploadProvider>
            <FileUpload
              multiple={true}
              uploadUrl={`{SUBCONTRACTOR}file_containers/${selectedFileContainer?.id}/project_files`}
              accessToken={getAccessToken()}
              onUploadComplete={refresh}
              disabled={!canDeliver}
            />
          </UploadProvider>
        </div>
        {selectedFileContainer && (
          <FileList
            files={selectedFileContainer.projectFiles}
            refreshFileContainer={refresh}
            disableDeleteFile={!canDeliver}
            fileContainerId={selectedFileContainer.id}
          />
        )}
        <Button
          className={'mx-4'}
          type={canDeliver ? ButtonType.SUCCESS : ButtonType.WARNING}
          onClick={deliverDocuments}
          disabled={isFetching === 1 || inProgress}
        >
          {isFetching ? (
            <Spinner />
          ) : canDeliver || inProgress ? (
            t('deliver')
          ) : (
            t('re_open_document')
          )}
        </Button>
        {history && history.length > 1 ? (
          <InlineComponentsWrapper padding="left" labelWidth={'w-16'}>
            <InlineInputLabelComponent label={'history'} />
          </InlineComponentsWrapper>
        ) : (
          <></>
        )}
        <div
          className={
            'overflow-y-scroll h-full items-center justify-center px-4'
          }
        >
          {history
            ?.filter((h) => h.closed_date)
            .map((history) => (
              <div key={history.id} className="font-medium text-sm mb-2 mx-2">
                <div className={'flex items-center gap-2'}>
                  {statusIcon(history)}
                  <p>{`${moment(history.created_at).format('L')}`}</p>
                  {!history.closed_by_subcontractor ? (
                    <p>{`${history.closed_by?.firstName ?? ''} ${history.closed_by?.lastName ?? ''}`}</p>
                  ) : (
                    <p>{history.subcontractor?.name ?? ''}</p>
                  )}
                </div>
                {!history.closed_by_subcontractor && (
                  <p className={'p-2 pl-5'}>{history.reason}</p>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default FileSubmissionPanel
