import moment from 'moment'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import MultiSelector from 'src/components/multi-selector/MultiSelector'
import {
  initializeMetaValues,
  setExistingValues,
} from 'src/components/system/SystemUtil'
import DocumentMetaDataFields from 'src/document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from 'src/document/types/IMetaData'
import { ICopyDeliveryState } from 'src/page/delivery/DeliveryTimeline'
import {
  addDeliveryDependency,
  getAllProjectDeliveries,
  getDelivery,
  getProjectDeliveryData,
  newDelivery,
  updateDelivery,
} from 'src/service/DeliveryService'
import { getDisciplines } from 'src/service/DisciplineService'
import { deleteFile } from 'src/service/DocumentService'
import { getUploadDocURl } from 'src/service/FileUploadService'
import {
  IDelivery,
  IDeliveryStatus,
  IDiscipline,
  IDocument,
  IImprovement,
  IMainProcess,
  ISystemTypeGroup,
  ITag,
  ITeam,
  IUserData,
  IKeypoint,
} from 'src/service/OrgTypes'
import { getProjectSystemTypeGroupsPerDomain } from 'src/service/SystemTypeGroupService'
import { getProjectTags } from 'src/service/TagService'
import { getMainProcessTeams } from 'src/service/TeamService'
import {
  getProjectDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getDeliveryErrorMessage,
  getErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import { renderDayContents } from 'src/utility/Utility'
import { capFirstLetter, classNames } from 'src/utility/utils'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import Spinner from '../../../ui-elements/loader/Spinner'
import Modal from '../../../ui-elements/modal/Modal'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import Textarea from '../../../ui-elements/textarea/Textarea'
import UploadFile from '../../document/UploadFile'
import UploadFileList from '../../document/UploadFileList'
import Selector from '../../selectors/Selector'

interface IDeliveryFormProps {
  show: boolean
  onDeliveryCreated: () => void
  projectId: number
  improvementId?: number
  user: IUserData
  isNotFromProcess?: boolean
  closeModal: () => void
  selectedKeypoint?: any
  delivery?: Partial<IDelivery>
  copyDeliveryState?: ICopyDeliveryState
  editMode?: boolean
}

const styleClass = {
  root: classNames('flex', 'flex-col'),
  inputGroup: classNames('flex', 'flex-row', 'w-full'),
  label: classNames(
    'w-full',
    'font-roboto',
    'block',
    'font-medium',
    'text-sm',
    'leading-5',
    'text-gray-700',
    'my-2',
  ),
  wrapper: classNames('px-2', 'flex-1'),
}

const DeliveryForm = ({
  show,
  onDeliveryCreated,
  projectId,
  improvementId,
  user,
  isNotFromProcess,
  closeModal,
  selectedKeypoint,
  delivery,
  copyDeliveryState,
  editMode,
}: IDeliveryFormProps) => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(delivery?.name ?? '')
  const [allDeliveries, setAlDeliveries] = useState<IDelivery[]>([])
  const [dependencyFirstTime, setDependencyFirstTime] = useState<boolean>(true)
  const [dependencyLoading, setDependencyLoading] = useState<boolean>(false)
  const [selectedDependencies, setSelectedDependencies] = useState<any[]>([])
  const [description, setDescription] = useState<string>(
    delivery?.description ?? '',
  )
  const [assumptions, setAssumptions] = useState<string>(
    delivery?.assumptions ?? '',
  )
  const [notes, setNotes] = useState<string>(delivery?.notes ?? '')
  const [loading, setLoading] = useState<boolean>(false)
  const [keypoints, setKeypoints] = useState<IKeypoint[]>([])
  const [keypointId, setKeypointId] = useState<number | undefined>(
    delivery ? delivery.key_point_id : undefined,
  )
  const [mainProcesses, setMainProcesses] = useState<IMainProcess[]>([])
  const [mainProcessId, setMainProcessId] = useState<number | undefined>(
    delivery ? delivery.main_process_id : undefined,
  )
  const [teams, setTeams] = useState<ITeam[]>([])
  const [teamId, setTeamId] = useState<number | undefined>(
    delivery ? delivery.team_id : undefined,
  )
  const [improvements, setImprovements] = useState<IImprovement[]>([])
  const [users, setUsers] = useState<IUserData[]>([])
  const [selectedUser, setSelectedUser] = useState<number>(
    delivery ? (delivery.responsible_id ? delivery.responsible_id : 0) : 0,
  )
  const [endTime, setEndTime] = useState<any>(
    delivery && delivery.endTime ? moment(delivery.endTime) : null,
  )
  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false)
  const [disciplineId, setDisciplineId] = useState<number>(
    delivery ? (delivery.discipline_id ? delivery.discipline_id : 0) : 0,
  )
  const [contractId, setContractId] = useState<number | null>(
    delivery ? (delivery.contract_id ? delivery.contract_id : null) : null,
  )
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [datoErrorMessage, setDatoErrorMessage] = useState<string>('')
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [responsibleErrorMessage, setResponsibleErrorMessage] =
    useState<string>('')
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const [selectLoading, setSelectLoading] = useState<boolean>(false)
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [duration, setDuration] = useState<number>(0)
  const [isCleared, setIsCleared] = useState<boolean | undefined>(undefined)
  const [tags, setTags] = useState<ITag[]>(
    delivery && delivery.tag ? [delivery.tag] : [],
  )
  const [tagId, setTagId] = useState<number | null | undefined>(
    delivery && delivery.tag_id ? delivery.tag_id : null,
  )
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<ISystemTypeGroup>()

  useEffect(() => {
    if (isNotFromProcess) {
      if (selectedKeypoint) {
        setKeypointId(selectedKeypoint.id)
        setMainProcessId(selectedKeypoint.main_process_id)
        setTeamId(selectedKeypoint.team_id)
      } else if (copyDeliveryState) {
        getCopyDeliveryItem(copyDeliveryState)
      }
      getProjectDeliveryData(projectId).then((res) => {
        setKeypoints(
          res.key_points
            ? res.key_points.filter((del: IKeypoint) => del.status !== 'done')
            : [],
        )
        setDisciplines(res.disciplines)
        setMainProcesses(res.main_processes || [])
        setTeams(res.teams || [])
        setImprovements(res.improvements || [])
      })
    }
    if (delivery) {
      getUsers()
    }
  }, [])

  const fetchSystemGroupType = useCallback(() => {
    getProjectSystemTypeGroupsPerDomain(projectId, 'Delivery').then(
      (res: ISystemTypeGroup[]) => {
        const value = res.pop()
        setSystemTypeGroup(value)
        if (value) {
          const metaFields = initializeMetaValues(
            value.optional_fields ?? [],
            'Delivery',
            delivery?.id,
          )
          setOptionalFields(
            setExistingValues(delivery?.optional_fields ?? [], metaFields),
          )
        }
      },
    )
  }, [])

  useEffect(() => {
    fetchSystemGroupType()
  }, [fetchSystemGroupType])

  const getCopyDeliveryItem = async (copiedItem: ICopyDeliveryState) => {
    setLoading(true)
    const {
      date,
      id: deliveryId,
      discipline_id: disciplinID,
      mainProcess_id: mainId,
      theme_id: temaId,
      tag_id: copiedTagId,
    } = copiedItem
    const del = await getDelivery(projectId, deliveryId)

    const discId = disciplinID
      ? disciplinID
      : del
        ? del.discipline_id
          ? del.discipline_id
          : 0
        : 0
    const responsibleId = del
      ? del.responsible_id
        ? del.responsible_id
        : 0
      : 0

    setEndTime(date)
    setName(del ? del.name : '')
    setDescription(del ? del.description : '')
    setAssumptions(del ? del.assumptions : '')
    setNotes(del ? del.notes : '')
    setKeypointId(del ? del.key_point_id : undefined)
    setMainProcessId(mainId ? mainId : del ? del.main_process_id : undefined)
    setTeamId(temaId ? temaId : del ? del.team_id : undefined)
    setTagId(copiedTagId ? copiedTagId : del ? del.tag_id : undefined)
    setSelectedUser(responsibleId)
    setDisciplineId(discId)
    setContractId(del ? (del.contract_id ? del.contract_id : null) : null)
    getUsers(discId, responsibleId)
    setLoading(false)
    setTags(del && del.tag ? [del.tag] : [])
    setTagId(del && del.tag_id ? del.tag_id : 0)
  }

  const getUsers = (disId = 0, responsibleId = 0) => {
    setSelectLoading(true)
    if (disId > 0) {
      getProjectDisplineUsers(projectId, disId).then((res) => {
        const userExistInDiscipline =
          res.findIndex((discipline) => responsibleId === discipline.id) > -1
        setUsers(res || [])
        setSelectLoading(false)
        setSelectedUser(userExistInDiscipline ? responsibleId : 0)
      })
    } else {
      getProjectUsersWithDisciplines(projectId).then((res) => {
        setUsers(res || [])
        setSelectLoading(false)
      })
    }
  }

  const onNameChange = (e: any) => {
    e.preventDefault()
    const value = e.target.value
    setName(value)
    setTitleErrorMessage('')
  }

  const onDescriptionChange = (e: any) => {
    const value = e.target.value
    setDescription(value)
  }

  const onDurationChange = (e: any) => {
    const value = +e.target.value
    setDuration(value)
  }

  const onAssumptionsChange = (e: any) => {
    const value = e.target.value
    setAssumptions(value)
  }

  const onNotesChange = (e: any) => {
    const value = e.target.value
    setNotes(value)
  }

  const onSelectKeypoint = (id: number) => {
    setKeypointId(id)
    if (!mainProcessId) {
      const selKeypoint = keypoints.find((del) => del.id === id)
      if (selKeypoint) {
        setMainProcessId(selKeypoint.main_process_id)
      }
    }
  }

  const onSelectMainProcess = (id: number) => {
    getMainProcessTeams(id).then((res) => {
      setTeams(res || [])
    })
    setMainProcessId(id)
  }

  const onSelectTeam = (id: number) => {
    const selectedTeam = teams.filter((team: ITeam) => team.id === id).pop()
    if (selectedTeam) {
      setMainProcessId(selectedTeam.main_process_id)
    }
    setTeamId(id)
  }

  const onDisciplineChange = async (value: number) => {
    const discipline = disciplines
      ? disciplines.find((v) => v.id === value)
      : undefined
    if (selectedUser <= 0) {
      const usersList = await getProjectDisplineUsers(projectId, value)
      setSelectedUser(0)
      setUsers(usersList)
    }

    setDisciplineId(value)
    setContractId(
      discipline && discipline.contract_id ? discipline.contract_id : null,
    )

    setResponsibleErrorMessage('')
    setDisciplineErrorMessage('')
  }

  const onResponsibleChange = (userId: number) => {
    const userSelected: IUserData | undefined = users
      .filter((u) => u.id === userId)
      .pop()
    const userDisciplines =
      userSelected && userSelected.disciplines
        ? userSelected.disciplines.filter((d) => d.project_id === projectId)
        : undefined
    const discipline =
      userDisciplines && userDisciplines.length === 1
        ? userDisciplines[0]
        : undefined

    setSelectedUser(userId)
    setDisciplines(userDisciplines ? userDisciplines : [])
    if (discipline) {
      setDisciplineId(discipline.id)
      setContractId(discipline.contract_id)
    }

    setResponsibleErrorMessage('')
    setDisciplineErrorMessage('')
  }

  const onDisciplineUserClear = async () => {
    setDisciplineId(0)
    setSelectedUser(0)
    const allProjectDis = await getDisciplines(projectId)
    const allUsers = await getProjectUsersWithDisciplines(projectId)
    setDisciplines(allProjectDis)
    setUsers(allUsers)
    setIsCleared(false)
  }

  const onTagChange = (value: number) => {
    setTagId(value)
  }

  const getTags = () => {
    setSelectLoading(true)
    getProjectTags(projectId).then((res) => {
      if (res) {
        setTags(res)
        setSelectLoading(false)
      }
    })
  }

  const submitDelivery = (e: any) => {
    let foundError = false
    e.preventDefault()
    setDatoErrorMessage('')
    setTitleErrorMessage('')
    setDisciplineErrorMessage('')
    setResponsibleErrorMessage('')

    if (!name && name === '') {
      setTitleErrorMessage(getErrorMessage(ValidationError.MISSING_TITLE, t))
      foundError = true
    }

    if (isNotFromProcess) {
      if (!endTime || !moment(endTime).isValid()) {
        setDatoErrorMessage(
          getErrorMessage(ValidationError.MISSING_DEADLINE, t),
        )
        foundError = true
      }

      if (!selectedUser) {
        setResponsibleErrorMessage(
          getErrorMessage(ValidationError.MISSING_RESPONSIBLE, t),
        )
        foundError = true
      }

      if (keypointId && endTime) {
        const selKeypoint = keypoints.find((del) => del.id === keypointId)
        if (
          selKeypoint &&
          selKeypoint.endTime &&
          endTime.isAfter(moment(selKeypoint.endTime), 'day')
        ) {
          setDatoErrorMessage(
            getDeliveryErrorMessage(
              StructureValidationError.PAST_PARENT_DEADLINE,
              t,
              moment(selKeypoint.endTime).format('L'),
            ),
          )
          foundError = true
        }
      }

      const improvement = improvements.find((u) => u.id === improvementId)
      if (endTime && improvement && improvement.deadline) {
        if (moment(endTime).isAfter(moment(improvement.deadline), 'day')) {
          setDatoErrorMessage(
            getDeliveryErrorMessage(
              StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
              t,
              moment(improvement.deadline).format('L'),
            ),
          )
          foundError = true
        }
      }

      if (!disciplineId || disciplineId === 0) {
        setDisciplineErrorMessage(
          getErrorMessage(ValidationError.MISSING_DISCIPLINE, t),
        )
        foundError = true
      }
    }

    if (!foundError) {
      setLoading(true)
      if (delivery && editMode) {
        updateDelivery(
          {
            ...delivery,
            name,
            description,
            assumptions,
            notes,
          },
          delivery.id ?? -1,
        ).then(() => {
          setLoading(false)
          closeModal()
        })
      } else {
        let createdDelivery: Omit<IDelivery, 'id'> = {} as IDelivery
        const copyFromId = delivery ? delivery.copy_from_id : undefined
        if (isNotFromProcess) {
          createdDelivery = {
            name,
            description,
            assumptions,
            notes,
            status: IDeliveryStatus.OPEN,
            responsible_id: selectedUser,
            key_point_id: keypointId,
            main_process_id: mainProcessId,
            team_id: teamId,
            endTime: endTime.toISOString(),
            discipline_id: disciplineId,
            contract_id: contractId,
            record_id: '',
            documents,
            project_id: projectId,
            improvement_id: improvementId,
            tag_id: tagId ? tagId : null,
            copy_from_id: copyFromId,
            duration,
            system_type_group_id: systemTypeGroup?.id,
            optional_fields: optionalFields,
          }
        } else {
          createdDelivery = {
            name,
            description,
            assumptions,
            notes,
            status: IDeliveryStatus.OPEN,
            responsible_id: null,
            record_id: '',
            documents,
            project_id: projectId,
            tag_id: tagId ? tagId : null,
            duration,
            key_point_id: selectedKeypoint?.id,
            system_type_group_id: systemTypeGroup?.id,
            optional_fields: optionalFields,
          }
        }

        newDelivery(createdDelivery, user.id)
          .then((res) => {
            addDeliveryDependency(res.id, {
              dependent_on_delivery: selectedDependencies.concat(),
            })
            if (createMultiple) {
              onDeliveryCreated()
              setLoading(false)
            } else {
              setLoading(false)
              setDocuments([])
              onDeliveryCreated()
              closeModal()
            }
          })
          .catch(() => {
            setLoading(false)
          })
      }
    }
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const onDatePickerFocus = (focused: any) => {
    setDatePickerFocused(focused.focused)
  }

  const setDeliveryDatePickerValue = (date: moment.Moment) => {
    setEndTime(date)
    setDatoErrorMessage('')
  }

  const outSideRangeDate = () => {
    return false
  }

  const filesUploaded = (docs: IDocument[]) => {
    setDocuments(documents.concat(docs))
  }

  const updateUploadedFiles = (docs: IDocument[]) => {
    setDocuments(docs)
  }

  const onCloseModal = () => {
    documents.forEach((item) => {
      deleteFile({ file_url: item.fileUrl }).catch((err) => console.error(err))
    })

    setDocuments([])
    closeModal()
  }

  const openDepedencyDelivery = async () => {
    if (dependencyFirstTime) {
      setDependencyLoading(true)
      const deliveries = await getAllProjectDeliveries(projectId)
      setAlDeliveries(deliveries)
      setDependencyFirstTime(false)
      setDependencyLoading(false)
    }
  }

  const onSelectDependency = (dependencies: any[]) => {
    setSelectedDependencies(dependencies)
  }

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setOptionalFields(values)
  }

  return (
    <Modal
      show={show}
      title={t('new_delivery')}
      closeModal={closeModal}
      size={'w-1/2'}
      maxWidth={800}
      confirmOutsideClick={true}
    >
      <form className={styleClass.root} onSubmit={submitDelivery}>
        {isNotFromProcess ? (
          <Fragment>
            <div className={`${styleClass.inputGroup} flex-wrap items-center`}>
              <div
                className={'w-mx-content flex flex-col px-2 pb-1 items-start'}
              >
                <div className={'flex'}>
                  <label className={styleClass.label}>
                    {capFirstLetter(t('deadline'))} *
                    {datoErrorMessage ? (
                      <label
                        className={'pl-2 text-sm my-2'}
                        style={{
                          color: 'Red',
                          fontWeight: 400,
                        }}
                      >
                        {datoErrorMessage}
                      </label>
                    ) : null}
                  </label>
                </div>
                <div className={'flex justify-between pr-2'}>
                  <SingleDatePicker
                    firstDayOfWeek={1}
                    date={endTime}
                    onDateChange={setDeliveryDatePickerValue}
                    renderDayContents={renderDayContents}
                    focused={datePickerFocused}
                    onFocusChange={onDatePickerFocus}
                    id="datepicker-delivery"
                    small={true}
                    isOutsideRange={outSideRangeDate}
                    showDefaultInputIcon={true}
                    noBorder={true}
                    numberOfMonths={1}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    hideKeyboardShortcutsPanel={true}
                  />
                </div>
              </div>
            </div>
            <div className={'mb-2 pt-px w-36'}>
              <Input
                label={t('duration_days')}
                value={duration}
                onChange={onDurationChange}
                block={true}
                required={false}
                type={'number'}
                minValue={0}
                step={'any'}
              />
            </div>
            <div className={styleClass.inputGroup}>
              <Input
                type={'text'}
                label={t('title')}
                value={name}
                onChange={onNameChange}
                block={true}
                required={true}
                errorMessage={titleErrorMessage}
                autoFocus={true}
              />
            </div>
            <div className={`${styleClass.inputGroup} flex-wrap`}>
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={disciplines}
                  selectedItemId={disciplineId}
                  onSelect={onDisciplineChange}
                  label={t('discipline')}
                  dataFields={['shortName', 'name']}
                  required={true}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  errorMessage={disciplineErrorMessage}
                  onCancel={onDisciplineUserClear}
                  cancelButton={true}
                />
              </div>
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={users}
                  selectedItemId={selectedUser}
                  onSelect={onResponsibleChange}
                  onOpenSelectFunction={() =>
                    getUsers(disciplineId, selectedUser)
                  }
                  loading={selectLoading}
                  label={t('responsible')}
                  dataFields={['firstName', 'lastName']}
                  required={true}
                  fontSize={'sm'}
                  userSelector={true}
                  fontWeight={'bold'}
                  errorMessage={responsibleErrorMessage}
                  onCancel={onDisciplineUserClear}
                  cancelButton={true}
                  isCleared={isCleared}
                />
              </div>
            </div>
            <div className={`${styleClass.inputGroup} flex-wrap`}>
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={mainProcesses}
                  selectedItemId={mainProcessId ? mainProcessId : 0}
                  onSelect={onSelectMainProcess}
                  label={t('main_process')}
                  dataFields={['name']}
                  required={false}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                />
              </div>
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={teams}
                  selectedItemId={teamId ? teamId : 0}
                  onSelect={onSelectTeam}
                  label={t('team')}
                  dataFields={['name']}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                />
              </div>
            </div>
            <div className={styleClass.inputGroup}>
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={keypoints}
                  selectedItemId={keypointId ? keypointId : 0}
                  onSelect={onSelectKeypoint}
                  label={t('keypoint')}
                  dataFields={['name']}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                />
              </div>
              <div className={'px-2 w-full md:w-1/2'}>
                <MultiSelector
                  items={allDeliveries}
                  onOpenList={openDepedencyDelivery}
                  label={t('depends_on')}
                  scroll={true}
                  dataFields={['record_id', 'name']}
                  selectedItems={selectedDependencies}
                  fontWeight={'bold'}
                  onSelect={onSelectDependency}
                  noBorder={true}
                  loading={dependencyLoading}
                  disableSelectAll={true}
                />
              </div>
            </div>
            <div className={styleClass.inputGroup}>
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={tags}
                  selectedItemId={tagId ? tagId : 0}
                  loading={selectLoading}
                  onSelect={onTagChange}
                  onOpenSelectFunction={getTags}
                  label={t('type')}
                  dataFields={['name']}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  cancelButton={true}
                  onCancel={() => setTagId(null)}
                />
              </div>
            </div>
          </Fragment>
        ) : (
          <div className={styleClass.inputGroup}>
            <Input
              type={'text'}
              label={t('title')}
              value={name}
              onChange={onNameChange}
              block={true}
              required={true}
              errorMessage={titleErrorMessage}
              focus={createMultiple}
            />
          </div>
        )}
        <Textarea
          value={description}
          isValid={false}
          label={t('description')}
          onChange={onDescriptionChange}
          block={true}
          required={false}
        />
        <Textarea
          value={assumptions}
          isValid={false}
          label={t('assumptions')}
          onChange={onAssumptionsChange}
          block={true}
          required={false}
        />
        <Textarea
          value={notes}
          isValid={false}
          label={t('result')}
          onChange={onNotesChange}
          block={true}
          required={false}
        />
        {optionalFields.length > 0 && (
          <div className="pb-8">
            <DocumentMetaDataFields
              title={''}
              onFieldsUpdate={onOptionalFieldsUpdate}
              fields={optionalFields}
              required={false}
            />
          </div>
        )}

        {documents && documents.length > 0 && (
          <UploadFileList
            documents={documents}
            updateDocuments={updateUploadedFiles}
          />
        )}

        <UploadFile
          uploadUrl={getUploadDocURl(projectId, 'Delivery')}
          uploadedDocuments={filesUploaded}
        />

        <ModalFooter>
          <Button onClick={onCloseModal}>{capFirstLetter(t('cancel'))}</Button>
          <Button
            type={Button.ButtonType.SECONDARY}
            onClick={onMultipleCreate}
            disabled={loading}
          >
            {loading ? <Spinner /> : capFirstLetter(t('add_multiple'))}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            onClick={onSingleCreate}
            disabled={loading}
          >
            {loading ? (
              <Spinner />
            ) : delivery && editMode ? (
              capFirstLetter(t('edit_delivery'))
            ) : (
              capFirstLetter(t('add'))
            )}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default DeliveryForm
