import Add from '@icons/add.svg'
import Delete from '@icons/delete.svg'
import DoNotDisturbOn from '@icons/do_not_disturb_on.svg'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeliveryColumns } from 'src/components/process/delivery/useDeliveryColumns'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { deliveriesExportData } from 'src/export-templates/DeliveryExports'
import { deliveryImportTemplate } from 'src/export-templates/DeliveryImportTemplate'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import useUserAccess from 'src/hooks/useUserAccess'
import { useFilteredDeliveries } from 'src/query/planning/deliveries'
import {
  deleteBulkDeliveries,
  deleteDeliveryById,
  updateDelivery,
} from 'src/service/DeliveryService'
import {
  IDelivery,
  IImportItemList,
  IKeypoint,
  ISystemTypeGroup,
} from 'src/service/OrgTypes'
import { removeDeliveriesFromKeypoint } from 'src/service/ProcessService'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import Table from 'src/ui-elements/Table/Table'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import useAlert from 'src/ui-elements/toast/useAlert'
import { addUserDefinedInfoToImport } from 'src/utility/exportUtils'
import { classNames } from 'src/utility/utils'
import { userDefinedColumns } from '../../TableColumns/Columns'

import KeypointChangeLogModal from '../main-process/KeypointChangeLogModal'
import DeliveryForm from './DeliveryForm'
import DeliveryInspectorPanel from './DeliveryInspectorPanel'

interface IProjectDeliveriesListProps {
  keypoint?: IKeypoint
  isExpandedElement?: boolean
  disableAdd?: boolean
  tableName?: string
  improvementId?: number
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  reloadParent?: () => void
  systemTypeGroup?: ISystemTypeGroup
  filter?: Omit<TableFilter, 'sort'>
  tableButtons?: JSX.Element[]
  inInspector?: boolean
}

const ProjectDeliveriesList = ({
  keypoint,
  isExpandedElement,
  disableAdd,
  improvementId,
  systemTypeGroup,
  onOpenItem,
  reloadParent,
  tableName = 'projectDeliveriesList',
  filter,
  tableButtons,
  inInspector = false,
}: IProjectDeliveriesListProps) => {
  const { isProjectAdmin: writeAccess } = useUserAccess()
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
    add: classNames('py-1', 'text-center', 'w-6'),
  }

  const { t } = useTranslation()
  const projectContext = useContext(ProjectContext)
  const userContext = useContext(UserContext)
  const { id: projectId } = projectContext.state.currentProject

  const [selectedDelivery, setSelectedDelivery] = useState<IDelivery>(
    {} as IDelivery,
  )
  const [newDeliveryModal, setNewDeliveryModal] = useState<boolean>(false)
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false)
  const [copyDelivery, setCopyDelivery] = useState<Partial<IDelivery>>({})
  const [changeLogModal, setChangeLogModal] = useState<boolean>(false)
  const [changeLogReason, setChangeLogReason] = useState<string>('')
  const { addAlert } = useAlert()

  const [openInspectorPanel, setOpenInspection] = useState(false)
  const [selectedDeliveryId, setSelectedDeliveryId] = useState(0)

  const queryClient = useQueryClient()

  const reloadDeliveries = () => {
    queryClient.invalidateQueries({ queryKey: ['deliveries'] })
  }
  const reload = () => {
    reloadDeliveries()
    reloadParent?.()
  }

  const importDeliveryTemplate: IImportItemList = {
    title: t('upload_deliveries'),
    templateJson: addUserDefinedInfoToImport(
      deliveryImportTemplate,
      [],
      systemTypeGroup?.optional_fields ?? [],
    ),
    type: 'deliveries',
    reload,
  }

  const onRowClick = (row: IDelivery) => {
    if (onOpenItem) {
      onOpenItem(row.id, 'delivery')
    } else {
      setOpenInspection(true)
      setSelectedDelivery(row)
    }
  }

  const onCloneItem = (row: IDelivery) => {
    const cDelivery: Partial<IDelivery> = {
      ...row,
      name: `Copy of ${row.name}`,
      copy_from_id: row.id,
      id: undefined,
    }
    setCopyModalOpen(true)
    setCopyDelivery(cDelivery)
  }

  const onDateChange = (delivery: IDelivery, date: string) => {
    setSelectedDelivery({ ...delivery, endTime: date })
    setChangeLogReason('')
    setChangeLogModal(true)
  }

  const onChangeLogModalClose = () => {
    setChangeLogModal(false)
    setSelectedDelivery({} as IDelivery)
  }

  const onChangeLogSubmit = async () => {
    setChangeLogModal((prevState) => !prevState)
    if (selectedDelivery.id) {
      await updateDelivery(
        { ...selectedDelivery, change_reason: changeLogReason },
        selectedDelivery.id,
      )
      setSelectedDelivery({} as IDelivery)
      reload()
    }
  }

  const { confirmDelete } = useDeleteModal()

  const deleteDelivery = async (row: IDelivery) => {
    if (keypoint) {
      await removeDeliveriesFromKeypoint(keypoint.id, projectId, [row.id])
      reload()
    } else {
      const confirm = await confirmDelete({
        itemIdnType: `${row.record_id} (${t('delivery')})`,
        itemName: `${row.record_id} - ${row.name}`,
      })
      if (!confirm) return
      await deleteDeliveryById(row.id)
      reload()
    }
  }

  const deleteSelectedDeliveries = async (deliveries: number[]) => {
    if (keypoint) {
      await removeDeliveriesFromKeypoint(keypoint.id, projectId, deliveries)
    } else {
      await deleteBulkDeliveries(projectId, deliveries)
      addAlert({
        type: 'success',
        title: t('successfully_deleted'),
        description: t('selected_deliveries_are_deleted'),
        autoClose: true,
      })
    }
    reload()
  }

  const onUpdateProjectDeliveriesList = () => {
    reload()
  }

  const updateMetaField = useCallback((data: IMetaValue) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload()
      })
    }
  }, [])

  const userDefinedAttributesColumns = useMemo(() => {
    if (systemTypeGroup) {
      return userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateMetaField,
        'meta_data',
      )
    } else {
      return []
    }
  }, [systemTypeGroup, updateMetaField])

  const handlePreviewClick = (data: IDelivery) => {
    setOpenInspection(true)
    if (data.id) {
      setSelectedDeliveryId(data.id)
    }
  }

  const customFilter = useMemo(
    () => ({
      ...(keypoint && { key_point: [keypoint.id] }),
      ...(improvementId && { improvement: [improvementId] }),
      ...filter,
    }),
    [improvementId, keypoint, filter],
  )

  const { columns, defaultOrdering, inspectorPanelColumnVisibility } =
    useDeliveryColumns({
      disabled: !writeAccess,
      onDateChange: onDateChange,
      reload: reload,
    })

  return (
    <div className={styleClass.root}>
      <Table
        name={tableName}
        useDataQuery={useFilteredDeliveries}
        initialFilter={customFilter}
        legacyColumns={userDefinedAttributesColumns}
        onPreviewClick={handlePreviewClick}
        onDeleteClick={deleteDelivery}
        onCopyClick={onCloneItem}
        onRowClick={onRowClick}
        deleteIcon={keypoint ? DoNotDisturbOn : Delete}
        tableButtons={
          !isExpandedElement
            ? () => ({
                exportData: deliveriesExportData,
                onBulkDelete: writeAccess
                  ? deleteSelectedDeliveries
                  : undefined,
                importItem: importDeliveryTemplate,
                customButtons: !disableAdd
                  ? [
                      <Button
                        key="new_delivery"
                        onClick={() => setNewDeliveryModal(true)}
                        type={ButtonType.PRIMARY}
                      >
                        <Add className={'fill-white text-xl'} />
                        {t('new_delivery')}
                      </Button>,
                      ...(tableButtons ?? []),
                    ]
                  : undefined,
              })
            : undefined
        }
        columns={columns}
        defaultOrdering={defaultOrdering}
        initialColumnVisibility={
          inInspector ? inspectorPanelColumnVisibility : undefined
        }
      />
      {newDeliveryModal ? (
        <DeliveryForm
          projectId={projectId}
          user={userContext.state.user}
          show={newDeliveryModal}
          selectedKeypoint={keypoint}
          onDeliveryCreated={onUpdateProjectDeliveriesList}
          isNotFromProcess={true}
          closeModal={() => setNewDeliveryModal(false)}
        />
      ) : null}

      {copyModalOpen ? (
        <DeliveryForm
          projectId={projectId}
          user={userContext.state.user}
          show={copyModalOpen}
          selectedKeypoint={keypoint}
          onDeliveryCreated={onUpdateProjectDeliveriesList}
          isNotFromProcess={true}
          delivery={copyDelivery}
          closeModal={() => setCopyModalOpen(false)}
        />
      ) : null}

      {changeLogModal && selectedDelivery.id ? (
        <KeypointChangeLogModal
          show={changeLogModal}
          toggleChangeLogModal={onChangeLogModalClose}
          onChangeLogSubmit={onChangeLogSubmit}
          onChangeReasonLog={setChangeLogReason}
        />
      ) : null}

      {openInspectorPanel && selectedDeliveryId ? (
        <DeliveryInspectorPanel
          deliveryId={selectedDeliveryId}
          open={openInspectorPanel}
          onClose={() => setOpenInspection(false)}
          projectId={projectId}
          onOpenItem={onOpenItem}
          onUpdate={reload}
        />
      ) : undefined}
    </div>
  )
}

export default ProjectDeliveriesList
