import { FC, useCallback, useContext, useEffect, useState } from 'react'
import FileDragOverlay from 'src/components/document/FileDragOverlay'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { classNames } from 'src/utility/utils'
import FileUploadingLists from './UploadingFIlesList'
import { FileUploadContext } from './context/FileUploadContext'

export interface FileDropProps {
  multiple?: boolean
  type?: string
  uploadUrl: string
  downloadTemplate?: () => void
  accessToken?: string
  height?: string
  disabled?: boolean
  CustomFileUploadingLists?: FC<{
    files: File[]
  }>
  validate?: (files: { name: string }[]) => { error: boolean; message: string }
  onFileSelected?: () => Promise<void>
}

const styleClass = {
  root: (files: boolean, height: string, disabled: boolean) =>
    classNames(
      'flex sticky flex-col mr-4 my-2 border-dashed border-2 rounded border-d-gray-light justify-center items-center bg-white',
      files ? '' : height,
      disabled ? 'opacity-50 cursor-not-allowed' : '',
    ),
  template: (show: boolean) =>
    classNames('text-sm font-normal text-d-fontchip', !show ? 'hidden' : ''),
}

const FileDrop: FC<FileDropProps> = ({
  multiple,
  type,
  uploadUrl,
  downloadTemplate,
  accessToken,
  height = 'min-h-70vh',
  disabled = false,
  CustomFileUploadingLists,
  validate,
  onFileSelected,
}) => {
  // set files from FileList

  const [files, setFiles] = useState<File[]>([])
  const [reloadList, setReloadList] = useState<boolean>(false)
  const { t } = useMultipleKeysTranslation()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const { addFilesToQueue } = useContext(FileUploadContext)

  const addToQueue = useCallback(() => {
    addFilesToQueue(files, uploadUrl, accessToken)
    setReloadList((n) => !n)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, uploadUrl])

  useEffect(() => {
    addToQueue()
  }, [addToQueue])

  const handleFiles = async (files: FileList | null) => {
    await onFileSelected?.()
    if (!files) {
      return
    }
    if (validate) {
      const { error, message } = validate(
        Array.from(files).map((file) => ({ name: file.name })),
      )
      if (error) setErrorMessage(message)
      else setErrorMessage(undefined)
    }
    const items = Array.from(files)
    const filteredItems = items.filter(checkType)
    if (!multiple) {
      const firstItem = type ? filteredItems[0] : items[0]
      setFiles(firstItem ? [firstItem] : [])
      return
    }
    const spreadItems = type ? [...filteredItems] : [...items]
    setFiles((n) => [...n, ...spreadItems])
  }

  // check file extension
  const checkType = ({ name }: File): boolean => {
    const allowedTypes = type ? type.split(',') : []
    const fileName = name
    const fileExtension = fileName.split('.').pop()
    return allowedTypes.includes(`.${fileExtension}`)
  }

  return (
    <>
      <FileDragOverlay
        t={t}
        onFilesDropped={disabled ? undefined : handleFiles}
        disabled={disabled}
      />
      <div className={`flex flex-col ${height}`}>
        <div className={styleClass.root(files.length > 0, height, disabled)}>
          <div className="flex flex-col items-center lowercase">
            <MaterialIcon
              className="text-d-grey-light text-[64px]"
              icon="cloud_upload"
              outline={true}
            />
            {errorMessage && (
              <p className="first-capitalize text-red">{errorMessage}</p>
            )}
            <p className={'first-capitalize'}>
              {t('drag_and_drop_your_files_here_or')}{' '}
              <label
                className={`text-blue-root underline ${
                  disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
              >
                <input
                  type="file"
                  accept={type}
                  onChange={(e) => handleFiles(e.target.files)}
                  multiple={multiple}
                  className="opacity-0 w-0"
                  disabled={disabled}
                />
                {t('browse', { capitalize: false })}
              </label>
            </p>
            <span
              className={styleClass.template(
                !files.length && downloadTemplate !== undefined,
              )}
            >
              <span
                onClick={() => downloadTemplate?.()}
                className="text-blue-root cursor-pointer underline"
              >
                {t('first_download')}
              </span>{' '}
              {t('upload_and_reload')}
            </span>
          </div>
        </div>
        {files.length > 0 && (
          <div key={reloadList ? '1' : '2'} className="mr-4 my-2">
            {CustomFileUploadingLists ? (
              <CustomFileUploadingLists files={files} />
            ) : (
              <FileUploadingLists files={files} />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default FileDrop
