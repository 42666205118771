import Delete from '@icons/delete.svg'
import RestartAlt from '@icons/restart_alt.svg'
import Save from '@icons/save.svg'
import Table from '@icons/table.svg'
import TableView from '@icons/table_view.svg'
import Update from '@icons/update.svg'
import Add from '@material-symbols/svg-500/rounded/add.svg'
import { Table as ReactTable, TableState } from '@tanstack/react-table'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { isMac } from 'src/components/grid/Grid/utils/getOperatingSystem'
import useProjectId from 'src/components/hooks/useProjectId'
import useShortcut from 'src/components/hooks/useShortCut'
import useReloadTableConfig from 'src/hooks/useReloadTableConfig'
import { queryClient } from 'src/query/client'
import {
  useDeleteSavedTableConfig,
  useMutateSavedTableConfigs,
  useClearTableConfig,
  savedTableConfigQueryKey,
  useTableConfig,
  useMutateTableConfig,
} from 'src/query/tableConfig'
import { overrideSessionTableConfig } from 'src/service/TableKeeperService'
import TableConfigFormModal from 'src/ui-elements/Table/TableConfigFormModal'
import {
  SavedTableConfig,
  TableKeeperState,
} from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import ButtonWithDropdownMenu from 'src/ui-elements/button/ButtonWithDropdownMenu'
import ButtonWithDropdownMenuMultipleActions from 'src/ui-elements/button/ButtonWithDropdownMenuMultipleActions'
import BackgroundLoader from '../loader/BackgroundLoader'

type ITableConfigButtonProps<T> = {
  table: ReactTable<T>
  tableName: string
  savedTableConfigs: SavedTableConfig[]
}

export interface ITableConfigProps {
  name: string
}

const TableConfigButtons = <T extends { id: number | string }>({
  tableName,
  savedTableConfigs,
  table,
}: ITableConfigButtonProps<T>) => {
  const [showCreateConfigModal, setShowCreateConfigModal] = useState(false)
  const [updating, setUpdating] = useState(false)
  const projectId = useProjectId()

  const { data: currentConfig } = useTableConfig<TableState>(tableName)
  const mutateTable = useMutateTableConfig(tableName)

  const { mutate: deleteConfig } = useDeleteSavedTableConfig(tableName)
  const { mutate: clearTableConfig } = useClearTableConfig(tableName)
  const { mutate: mutateConfig } = useMutateSavedTableConfigs(tableName)
  const { mutateAsync: mutateConfigWithCallback } = useMutateSavedTableConfigs(
    tableName,
    () => {
      setShowCreateConfigModal(false)
      queryClient.invalidateQueries({
        queryKey: savedTableConfigQueryKey(projectId, tableName),
      })
    },
  )

  const invalidate = useReloadTableConfig(tableName)

  const tableState = table.getState()
  const copyCurrentTableKeeperState = useCallback(() => {
    return {
      columnVisibility: tableState.columnVisibility,
      sorting: tableState.sorting,
      filters: tableState.columnFilters,
      columnOrdering: tableState.columnOrder,
      columnSizing: tableState.columnSizing,
    } as TableKeeperState
  }, [tableState])

  const overrideConfig = () => {
    overrideSessionTableConfig(projectId, tableName)
    setTimeout(() => {
      setUpdating(false)
    }, 900)
    setUpdating(true)
  }

  const setNewActiveTableConfig = (config: SavedTableConfig) => {
    overrideConfig()
    const currentActive = savedTableConfigs.find((c) => c.active)
    if (currentActive && currentActive.name !== config.name) {
      mutateConfig({ ...currentActive, active: false })
    }
    mutateConfigWithCallback({ ...config, active: true })
    table.setColumnVisibility(config.config.columnVisibility ?? {})
    table.setSorting(config.config.sorting ?? [])
    table.setColumnFilters(config.config.filters ?? [])
    table.setColumnOrder(config.config.columnOrdering ?? [])
    table.setColumnSizing(config.config.columnSizing ?? {})
  }

  const cleanConfig = (config?: TableKeeperState) => {
    if (!config) return null
    return JSON.stringify({
      columnVisibility: config.columnVisibility,
      sorting: config.sorting,
      filters: config.filters,
      columnOrdering: config.columnOrdering,
      columnSizing: config.columnSizing,
    })
  }

  const unsavedConfig = useMemo(() => {
    const activeConfig = cleanConfig(currentConfig)
    const sessionConfig = cleanConfig(copyCurrentTableKeeperState())
    return !isEqual(activeConfig, sessionConfig)
  }, [copyCurrentTableKeeperState, currentConfig])

  const saveConfig = () => {
    if (!unsavedConfig) return
    overrideConfig()
    const config = copyCurrentTableKeeperState()
    mutateTable.mutate({
      ...config,
      pagination: table.getState().pagination,
    })
  }

  const saveTableConfig: SubmitHandler<ITableConfigProps> = async ({
    name,
  }) => {
    const config = copyCurrentTableKeeperState()
    const newConfig = {
      config: config,
      name: name,
      active: true,
    }
    const currentActive = savedTableConfigs.find((c) => c.active)
    if (currentActive) mutateConfig({ ...currentActive, active: false })
    await mutateConfigWithCallback(newConfig)
    invalidate(tableName)
    saveConfig()
  }

  const overrideCurrentActiveConfig = async () => {
    const currentActive = savedTableConfigs.find((c) => c.active)
    const config = copyCurrentTableKeeperState()
    if (currentActive) {
      await mutateConfigWithCallback({
        config: config,
        name: currentActive.name,
        active: true,
      })
      invalidate(tableName)
      saveConfig()
    }
  }

  const clearTableConfigurations = () => {
    table.resetSorting()
    table.resetColumnFilters()
    table.resetColumnSizing()
    table.resetColumnVisibility()
    table.resetColumnOrder()
    clearTableConfig(tableName)
    const currentActive = savedTableConfigs.find((c) => c.active)
    if (currentActive) {
      mutateConfig({ ...currentActive, active: false })
    }
  }

  useShortcut(['Shift', 'S'], saveConfig)

  return (
    <>
      <div className="flex items-center">
        <div className="relative">
          <ButtonWithDropdownMenu
            type={Button.ButtonType.SECONDARY}
            title={`${t('save')} ${t('table_configuration')}`}
            items={[
              ...(unsavedConfig
                ? [
                    {
                      icon: <Save className="text-md mr-1" />,
                      title: t('save_configuration'),
                      onClick: saveConfig,
                      shortcut: t('cmd_s', {
                        cmd: isMac ? 'Shift' : '⇧',
                      }),
                    },
                  ]
                : []),
              {
                icon: <Add className="text-md mr-1" />,
                title: `${t('create_new')} ${t('table_configuration')}`,
                onClick: () => setShowCreateConfigModal(true),
              },
              {
                icon: <Update className="text-md mr-1" />,
                title: t('override_current_table_configuration'),
                onClick: () => overrideCurrentActiveConfig(),
              },
              {
                icon: <RestartAlt className="text-md mr-1" />,
                title: `${t('clear_table_configuration_for_this_table')}`,
                onClick: () => clearTableConfigurations(),
              },
            ]}
          >
            <Table className={'text-xl'} />
          </ButtonWithDropdownMenu>
          {unsavedConfig && (
            <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-600"></span>
          )}
        </div>
        <ButtonWithDropdownMenuMultipleActions
          disabled={savedTableConfigs.length < 1}
          title={`${t('switch')} ${t('table_configurations')}`}
          items={
            savedTableConfigs.length > 0
              ? savedTableConfigs.map((c) => ({
                  icon: <Delete className={'fill-red text-sm'} />,
                  active: c.active && !unsavedConfig,
                  onClickIcon: () => deleteConfig(c.name),
                  title: c.name,
                  onClick: () => setNewActiveTableConfig(c),
                }))
              : []
          }
        >
          <TableView className="text-xl" />
        </ButtonWithDropdownMenuMultipleActions>
      </div>
      {showCreateConfigModal && (
        <TableConfigFormModal
          tableConfigs={savedTableConfigs}
          closeModal={() => setShowCreateConfigModal(false)}
          showModal={showCreateConfigModal}
          onSave={saveTableConfig}
        />
      )}

      <BackgroundLoader
        active={updating}
        loadingText={t('saving_table_config')}
      />
    </>
  )
}

export default TableConfigButtons
