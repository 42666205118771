export enum DetailPageKeys {
  CONTRACT = 'contract',
  CONTRACTUAL_BORDER = 'contractualBorder',
  CONTROL_AREA = 'controlArea',
  DELIVERY = 'delivery',
  DISCIPLINE = 'discipline',
  DOCUMENT = 'document',
  KEYPOINT = 'keypoint',
  MEETING_SERIES = 'meetingSeries',
  MILESTONE = 'milestone',
  RISK = 'risk',
  RISK_GROUP = 'riskGroup',
  ROOM = 'room',
  SYSTEM = 'system',
  TASK = 'task',
  TASK_UNDER_CASE_TYPE = 'taskUnderCaseType',
  TASK_WITHOUT_CASE_TYPE = 'taskWithoutCaseType',
  TEST = 'test',
  TEST_SYSTEM_GROUP = 'testSystemGroup',
  TEST_SYSTEM_GROUP_SYSTEM = 'testSystemGroupSystem',
  TEST_TYPE = 'testType',
  TEST_WORK_GROUP = 'testWorkGroup',
  TEST_WORK_GROUP_TEST_SYSTEM_GROUP = 'testWorkGroupTestSystemGroup',
  TEST_WORK_GROUP_SYSTEM = 'testWorkGroupSystem',
  TRAIN = 'train',
  TRAIN_TYPE = 'trainType',
  WAGON = 'wagon',
  WAGON_TYPE = 'wagonType',
}

type DetailPageIdTypes = {
  [DetailPageKeys.CONTRACT]: { contractId: number | undefined }
  [DetailPageKeys.CONTRACTUAL_BORDER]: {
    contractualBorderId: number | undefined
  }
  [DetailPageKeys.CONTROL_AREA]: {
    cagId: number | undefined
    caId: number | undefined
  }
  [DetailPageKeys.DELIVERY]: { deliveryId: number | undefined }
  [DetailPageKeys.DISCIPLINE]: {
    contractId: number | undefined
    disciplineId: number | undefined
  }
  [DetailPageKeys.DOCUMENT]: {
    folderId: number | undefined
    documentId: number | undefined
  }
  [DetailPageKeys.KEYPOINT]: { keypointId: number | undefined }
  [DetailPageKeys.MEETING_SERIES]: { meetingSeriesId: number | undefined }
  [DetailPageKeys.MILESTONE]: { milestoneId: number | undefined }
  [DetailPageKeys.RISK]: {
    riskGroupId: number | undefined
    riskId: number | undefined
  }
  [DetailPageKeys.RISK_GROUP]: { riskGroupId: number | undefined }
  [DetailPageKeys.ROOM]: {
    sectionId: number | undefined
    floorId: number | undefined
    roomId: number | undefined
  }
  [DetailPageKeys.SYSTEM]: {
    systemId: number | undefined
    systemSettingId: number | undefined
  }
  [DetailPageKeys.TASK]: { taskId: number | undefined }
  [DetailPageKeys.TASK_UNDER_CASE_TYPE]: {
    taskTypeId: number | undefined
    taskId: number | undefined
  }
  [DetailPageKeys.TASK_WITHOUT_CASE_TYPE]: {
    taskId: number | undefined
  }
  [DetailPageKeys.TEST]: {
    testTypeId: number | undefined
    testId: number | undefined
  }
  [DetailPageKeys.TEST_SYSTEM_GROUP]: { testSystemGroupId: number | undefined }
  [DetailPageKeys.TEST_SYSTEM_GROUP_SYSTEM]: {
    testSystemGroupId: number | undefined
    systemPath: string | undefined
  }
  [DetailPageKeys.TEST_TYPE]: { testTypeId: number | undefined }
  [DetailPageKeys.TEST_WORK_GROUP]: { testWorkGroupId: number | undefined }
  [DetailPageKeys.TEST_WORK_GROUP_TEST_SYSTEM_GROUP]: {
    testWorkGroupId: number | undefined
    testSystemGroupId: string | undefined
  }
  [DetailPageKeys.TEST_WORK_GROUP_SYSTEM]: {
    testWorkGroupId: number | undefined
    testSystemGroupId: string | undefined
    systemPath: string | undefined
  }

  [DetailPageKeys.TRAIN]: {
    cagId: number | undefined
    caId: number | undefined
    trainId: number | undefined
  }
  [DetailPageKeys.TRAIN_TYPE]: { trainTypeId: number | undefined }
  [DetailPageKeys.WAGON]: {
    cagId: number | undefined
    caId: number | undefined
    trainId: number | undefined
    wagonId: number | undefined
  }
  [DetailPageKeys.WAGON_TYPE]: {
    trainTypeId: number | undefined
    wagonTypeId: number | undefined
  }
}

export interface IDetailPageData<K extends DetailPageKeys> {
  key: K
  ids: DetailPageIdTypes[K]
}

export const getDetailUrl = {
  validateIds(ids: Array<number | string | undefined>): boolean {
    for (const key in ids) {
      if (ids[key] === undefined || ids[key] === 0 || ids[key] === '') {
        return false
      }
    }
    return true
  },
  contract: (ids: DetailPageIdTypes[DetailPageKeys.CONTRACT]) =>
    `/project/contracts/contracts/${ids.contractId}/`,
  contractualBorder: (
    ids: DetailPageIdTypes[DetailPageKeys.CONTRACTUAL_BORDER],
  ) =>
    `/systematiccompletion/contractual_borders/contractual_borders/${ids.contractualBorderId}/`,
  controlArea: (ids: DetailPageIdTypes[DetailPageKeys.CONTROL_AREA]) =>
    `/constructions/control_areas/control_area_groups/control_area_groups/${ids.cagId}/control_areas/${ids.caId}/`,
  delivery: (ids: DetailPageIdTypes[DetailPageKeys.DELIVERY]) =>
    `/delivery/table_overview/delivery/${ids.deliveryId}/`,
  discipline: (ids: DetailPageIdTypes[DetailPageKeys.DISCIPLINE]) =>
    `/project/contracts/contracts/${ids.contractId}/disciplines/${ids.disciplineId}/`,
  document: (ids: DetailPageIdTypes[DetailPageKeys.DOCUMENT]) =>
    `/documents/documents?folderId=${ids.folderId}&documentId=${ids.documentId}`,
  keypoint: (ids: DetailPageIdTypes[DetailPageKeys.KEYPOINT]) =>
    `/keypoint/table_overview/keypoint/${ids.keypointId}/`,
  meetingSeries: (ids: DetailPageIdTypes[DetailPageKeys.MEETING_SERIES]) =>
    `/meetings/meeting_series/meeting_series/${ids.meetingSeriesId}/`,
  milestone: (ids: DetailPageIdTypes[DetailPageKeys.MILESTONE]) =>
    `/milestone/overview/milestone/${ids.milestoneId}/`,
  risk: (ids: DetailPageIdTypes[DetailPageKeys.RISK]) =>
    `/risks/overview/risk_groups/${ids.riskGroupId}/risks/${ids.riskId}`,
  riskGroup: (ids: DetailPageIdTypes[DetailPageKeys.RISK_GROUP]) =>
    `/risks/overview/risk_groups/${ids.riskGroupId}`,
  room: (ids: DetailPageIdTypes[DetailPageKeys.ROOM]) =>
    `/sections/${ids.sectionId}/floors/${ids.floorId}/rooms/${ids.roomId}`,
  system: (ids: DetailPageIdTypes[DetailPageKeys.SYSTEM]) =>
    `/systems/systems?systemPath=${ids.systemId}&systemSettingId=${ids.systemSettingId}`,
  task: (ids: DetailPageIdTypes[DetailPageKeys.TASK]) =>
    `/actions/table_overview/actions/${ids.taskId}/`,
  taskUnderCaseType: (
    ids: DetailPageIdTypes[DetailPageKeys.TASK_UNDER_CASE_TYPE],
  ) => `/tasks/table_overview/tasktypes/${ids.taskTypeId}/tasks/${ids.taskId}`,
  taskWithoutCaseType: (
    ids: DetailPageIdTypes[DetailPageKeys.TASK_WITHOUT_CASE_TYPE],
  ) => `/tasks/table_overview/tasktypes/no_task_type/tasks/${ids.taskId}`,
  test: (ids: DetailPageIdTypes[DetailPageKeys.TEST]) =>
    `/systematiccompletion/tests/test-types/${ids.testTypeId}/tests/${ids.testId}`,
  testSystemGroup: (ids: DetailPageIdTypes[DetailPageKeys.TEST_SYSTEM_GROUP]) =>
    `/systems/test_system_groups?testSystemGroupId=${ids.testSystemGroupId}`,
  testSystemGroupSystem: (
    ids: DetailPageIdTypes[DetailPageKeys.TEST_SYSTEM_GROUP_SYSTEM],
  ) =>
    `/systems/test_system_groups?testSystemGroupId=${ids.testSystemGroupId}&systemPath=${ids.systemPath}`,
  testType: (ids: DetailPageIdTypes[DetailPageKeys.TEST_TYPE]) =>
    `/systematiccompletion/tests/test-types/${ids.testTypeId}/`,
  testWorkGroup: (ids: DetailPageIdTypes[DetailPageKeys.TEST_WORK_GROUP]) =>
    `/systems/test_work_groups?testWorkGroupId=${ids.testWorkGroupId}`,
  testWorkGroupTestSystemGroup: (
    ids: DetailPageIdTypes[DetailPageKeys.TEST_WORK_GROUP_TEST_SYSTEM_GROUP],
  ) =>
    `/systems/test_work_groups?testWorkGroupId=${ids.testWorkGroupId}&testSystemGroupId=${ids.testSystemGroupId}`,
  testWorkGroupSystem: (
    ids: DetailPageIdTypes[DetailPageKeys.TEST_WORK_GROUP_SYSTEM],
  ) =>
    `/systems/test_work_groups?testWorkGroupId=${ids.testWorkGroupId}&testSystemGroupId=${ids.testSystemGroupId}&systemPath=${ids.systemPath}`,
  train: (ids: DetailPageIdTypes[DetailPageKeys.TRAIN]) =>
    `/constructions/control_areas/control_area_groups/control_area_groups/${ids.cagId}/control_areas/${ids.caId}/trains/${ids.trainId}/`,
  trainType: (ids: DetailPageIdTypes[DetailPageKeys.TRAIN_TYPE]) =>
    `/constructions/templates/train_templates/train_types/${ids.trainTypeId}`,
  wagon: (ids: DetailPageIdTypes[DetailPageKeys.WAGON]) =>
    `/constructions/control_areas/control_area_groups/control_area_groups/${ids.cagId}/control_areas/${ids.caId}/trains/${ids.trainId}/wagons/${ids.wagonId}`,
  wagonType: (ids: DetailPageIdTypes[DetailPageKeys.WAGON_TYPE]) =>
    ids.trainTypeId
      ? `/constructions/templates/train_templates/train_types/${ids.trainTypeId}/locomotive_types/${ids.wagonTypeId}/`
      : `/constructions/templates/wagon_templates/locomotive_types/${ids.wagonTypeId}/`,
}
