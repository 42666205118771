import 'filepond/dist/filepond.min.css'
import { capitalize } from 'lodash'
import moment from 'moment'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPanel } from 'react-tabs'
import TaskRelations from 'src/components/task/TaskRelations'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { getAllProjectDeliveries } from 'src/service/DeliveryService'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import {
  IConstructionWagon,
  IControlArea,
  IImprovement,
  IRisk,
  ITask,
} from 'src/service/OrgTypes'
import { getProjectRiskList, getRisk } from 'src/service/RiskService'
import { getProjectRooms } from 'src/service/RoomService'
import { actionStatus } from 'src/service/SystemValues'
import { editTask, getProjectTaskTypes, getTask } from 'src/service/TaskService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import { getErrorMessage, ValidationError } from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import PageHeader from 'src/ui-elements/page-display/PageHeader'
import PageRoot from 'src/ui-elements/page-display/PageRoot'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { ContentTabsWrapper } from 'src/ui-elements/tabs/ContentTabs'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter } from 'src/utility/utils'
import history from '../../history'
import { metaDataSection } from '../TableColumns/Columns'
import ChangeLog from '../changelog/Changelog'
import Comments from '../comment/Comments'
import DocumentsList from '../document/DocumentsList'
import ImprovementPanel from '../improvement/improvement-panel/ImprovementPanel'
import TaskReviewInFloorPlan from '../pdf/TaskReviewInFloorPlan'
import RiskInspectorPanel from '../risk/RiskInspectorPanel'
import { getMetaDataValues, loadMetaValues } from '../system/SystemUtil'
import RelatedTasks from './RelatedTasks'

interface ITaskDetailPage {
  taskId: number
  projectId: number
  disable?: boolean
  hideSystem?: boolean
  reloadTree?: () => void
}

const TaskDetailPage = ({
  taskId,
  projectId,
  disable,
  hideSystem,
  reloadTree,
}: ITaskDetailPage) => {
  const { t } = useTranslation()
  const [initialTaskData, setInitialTaskData] = useState<ITask>({} as ITask)
  const taskIdRef = useRef(taskId)
  const [taskData, setTaskData] = useState<ITask>({} as ITask)
  const [risk, setRisk] = useState<IRisk>({} as IRisk)
  const [ControlAreas, setControlAreas] = useState<IControlArea[]>([])
  const [wagons, setWagons] = useState<IConstructionWagon[]>([])
  const [openRiskDetailModal, setOpenRiskDetailModal] = useState<boolean>(false)
  const [projectEndDate, setProjectEndDate] = useState<moment.Moment>(moment())
  const [showImprovementPanel, setShowImprovementPanel] =
    useState<boolean>(false)

  const [improvements, setImprovements] = useState<IImprovement[]>([])

  const projectContext = useContext(ProjectContext)
  const { addAlert } = useAlert()
  const [tabIndex, setTabIndex] = useState(0)
  const tabItems = [
    t('related_tasks_original'),
    t('documents'),
    t('comments'),
    t('change_log'),
  ]
  const detailItems: string[] = [
    capFirstLetter(t('details')),
    capFirstLetter(t('additional_information')),
    capitalize(t('connections')),
  ]
  const [detailTabIndex, setDetailTabIndex] = useState<number>(0)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])

  const showAlert = (type: IAlertType, titleForAlert: string, text: string) => {
    addAlert({ type, title: titleForAlert, description: text })
  }

  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<ITask>(setTaskData, taskData)

  const reload = useCallback(async () => {
    if (taskIdRef.current !== taskId) {
      taskIdRef.current = taskId
    }
    const { currentProject } = projectContext.state
    const aTask = await getTask(projectId, taskId)
    if (aTask) {
      setTaskData(aTask)
      setInitialTaskData(aTask)
      setControlAreas(aTask.control_area ? [aTask.control_area] : [])
      setWagons(
        aTask.construction_locomotive ? [aTask.construction_locomotive] : [],
      )

      setImprovements(aTask.improvement ? [aTask.improvement] : [])
    }
    setProjectEndDate(moment(currentProject.endDate))
    const metaData = getMetaDataValues(aTask.meta_data)
    setOptionalFields(
      loadMetaValues(
        taskId,
        'Task',
        aTask.task_type?.optional_fields ?? [],
        metaData,
      ),
    )
  }, [taskId])

  useEffect(() => {
    reload()
  }, [reload])

  const toggleImprovementPanel = () => {
    setShowImprovementPanel((v) => !v)
  }

  const isEndTimeValid = (deadlineVal: moment.Moment) => {
    const parent = taskData.parent

    if (taskData && (!deadlineVal || !moment(deadlineVal).isValid())) {
      return t('select_w_param', { param: t('deadline') })
    }

    const improvement = improvements.find(
      (u) => u.id === taskData.improvement_id,
    )
    if (improvement) {
      if (moment(deadlineVal).isAfter(moment(improvement.deadline), 'day')) {
        return (
          t(
            'deadline_for_the_task_must_be_before_the_deadline_for_the_corresponding_improvement',
          ) +
          ' (' +
          moment(improvement.deadline).format('L') +
          ')'
        )
      }
    }
    if (
      parent &&
      deadlineVal &&
      ![
        'CheckInParameter',
        'ChecklistParameter',
        'ConstructionLocomotive',
        'ControlArea',
      ].includes(taskData.parent_type)
    ) {
      const endTime =
        taskData.parent_type === 'Risk' ? parent.deadline : parent.endTime
      const message =
        taskData.parent_type === 'Risk'
          ? t('deadline_must_be_within_the_risk_deadline') + ' ('
          : t('deadline_must_be_within_the_delivery_deadline') + ' ('
      if (endTime && moment(deadlineVal).isAfter(moment(endTime), 'day')) {
        return message + moment(endTime).format('L') + ')'
      }

      if (
        taskData &&
        taskData.parent_type === 'Project' &&
        taskData.parent_id === projectId
      ) {
        if (moment(deadlineVal).isAfter(moment(projectEndDate), 'day')) {
          return (
            t('deadline_must_be_within_the_project_deadline') +
            ' (' +
            moment(projectEndDate).format('L') +
            ')'
          )
        }
      }
    }
    return
  }

  const openRiskModal = (e: any) => {
    e.preventDefault()
    getRisk(taskData.parent_id).then((res) => {
      if (res.id) {
        setRisk(res)
        setOpenRiskDetailModal(true)
      }
    })
  }

  const gotoMeeting = () => {
    if (taskData.parent) {
      const meetingID = taskData.parent.meeting_id
      const meetingSeriesId = taskData.parent.meeting_series_id
      if (meetingID && meetingSeriesId) {
        history.push(`/meeting_series/${meetingSeriesId}/meetings/${meetingID}`)
      } else if (meetingSeriesId) {
        history.push(`/meeting_series/${meetingSeriesId}`)
      } else if (meetingID) {
        history.push(`/meetings/${meetingID}`)
      }
    }
  }

  const disabled = !taskData.update_access || disable

  const onStatusSelect = () => {
    if (disabled) {
      showAlert('error', t('access_limited'), t('do_not_have_access_to_edit'))
    } else {
      const updatedTask = { ...initialTaskData, status: 'done' }
      editTask(updatedTask).then(() => {
        reload()
      })
    }
  }

  const parentIsMeeting = (): boolean => {
    return (
      taskData.parent &&
      (taskData.parent.meeting_series_id || taskData.parent.meeting_id)
    )
  }

  const extraButtons = (): JSX.Element => {
    return (
      <div className="flex items-center">
        {taskData.status && taskData.status !== 'done' && (
          <Button
            disabled={disabled}
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}

        {taskData.parent_type === 'Risk' ? (
          <Button size={Button.ButtonSize.SMALL} onClick={openRiskModal}>
            {t('see_risk')}
          </Button>
        ) : null}
        {parentIsMeeting() ? (
          <Button size={Button.ButtonSize.SMALL} onClick={gotoMeeting}>
            {t('see_meeting')}
          </Button>
        ) : null}
        {taskData.improvement_id ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={toggleImprovementPanel}
          >
            {t('see_improvement_measures')}
          </Button>
        ) : null}
      </div>
    )
  }

  const onChangeInput = async (update: Partial<ITask>) => {
    try {
      if (taskData?.id && projectId) {
        const allUpdates = getChangesForUpdate(update)
        setTaskData({ ...taskData, ...allUpdates })
        editTask({
          ...convertUndefinedToNull(allUpdates),
          id: taskId,
          project_id: projectId,
        }).then(() => {
          reload()
        })
        if (update.title) reloadTree?.()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const mainSection = () => {
    return (
      <div className="flex">
        <InlineComponentsWrapper padding="left" border={'right'}>
          <TextInlineInputCompontent
            label={t('title')}
            value={taskData?.title ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ title: newValue })
            }}
            validate={(value) => {
              if (value === undefined || value === '') {
                return getErrorMessage(ValidationError.MISSING_TITLE, t)
              }
              return
            }}
          />

          <SelectorInlineInputComponent
            items={actionStatus(t)}
            label={t('status')}
            getItemLabel={(stat) => stat?.name}
            initialItem={{
              id: taskData?.status ?? '',
              name:
                actionStatus(t).find(
                  (statData) => statData.id === taskData?.status,
                )?.name ?? '',
            }}
            validate={() => {
              if (
                taskData.parent &&
                taskData.parent.status &&
                (taskData.parent_type === 'Risk' ||
                  taskData.parent_type === 'Delivery')
              ) {
                if (
                  taskData.parent.status === 'done' &&
                  taskData.status !== 'done'
                ) {
                  const message =
                    taskData.parent_type === 'Risk'
                      ? t('associated_risk_is_closed')
                      : t('associated_delivery_is_closed')
                  return message
                }
              }
              return
            }}
            selectedId={taskData?.status}
            onValueSubmitted={(stat) => {
              onChangeInput({ status: stat })
            }}
            inspectorPanel={false}
          />

          <DateTimeInlineInputComponent
            label={t('deadline')}
            selectedTime={`${taskData?.deadline}`}
            onValueSubmitted={(deadlineValue) => {
              onChangeInput({ deadline: moment(deadlineValue) })
            }}
            validate={(value) => {
              if (value) {
                return isEndTimeValid(value)
              }
              return
            }}
            inspectorPanel={false}
          />
          <TextInlineInputCompontent
            label={t('duration_days')}
            value={`${taskData?.duration ? taskData?.duration : 0}`}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ duration: parseInt(`${newValue}`, 10) })
            }}
            type={'number'}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              if (newValue && +newValue < 0) {
                return getErrorMessage(ValidationError.NEGATIVE_DURATION, t)
              }
              return undefined
            }}
          />

          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={taskData?.discipline}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={taskData?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              addChangesToAppendWhenKeyUpdates('responsible_id', {
                discipline_id,
              })
            }}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              taskData?.discipline_id
                ? getDisplineUsers(taskData?.discipline_id)
                : getProjectUsersWithDisciplines(projectId)
            }
            label="responsible"
            getItemLabel={(responsible) =>
              `${responsible?.firstName} ${responsible?.lastName}`
            }
            initialItem={taskData?.responsible}
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('responsible'),
                })
              return
            }}
            selectedId={taskData?.responsible_id ?? 0}
            onValueSubmitted={(responsible_id) => {
              onChangeInput({ responsible_id })
            }}
            dependencies={[taskData?.discipline_id]}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            label={'contract'}
            disabled={true}
            selectedId={taskData?.contract_id ?? ''}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            initialItem={taskData?.contract}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectTaskTypes(projectId)}
            label={t('task_type')}
            getItemLabel={(taskType) => taskType?.taskTypeName}
            initialItem={taskData?.task_type}
            selectedId={taskData?.task_type_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ task_type_id: val })
            }}
            inspectorPanel={false}
          />
          <TextInlineInputCompontent
            label={t('description')}
            value={taskData?.description ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ description: newValue })
            }}
            textArea={true}
          />
        </InlineComponentsWrapper>
        <InlineComponentsWrapper padding="left" border={undefined}>
          {taskData.parent_type === 'Delivery' && (
            <SelectorInlineInputComponent
              getItems={() => getAllProjectDeliveries(projectId)}
              label={t('delivery')}
              getItemLabel={(parent) => parent?.name}
              initialItem={taskData?.parent}
              selectedId={taskData?.parent_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ parent_id: val })
              }}
              cancelButton={true}
              inspectorPanel={false}
            />
          )}

          {taskData.parent_type === 'Topic' && (
            <SelectorInlineInputComponent
              getItems={() => getAllProjectDeliveries(projectId)}
              label={t('delivery')}
              getItemLabel={(delivery) =>
                delivery ? `${delivery?.record_id} - ${delivery?.name}` : ''
              }
              initialItem={taskData?.delivery}
              selectedId={taskData?.delivery_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ delivery_id: val })
              }}
              cancelButton={true}
              inspectorPanel={false}
            />
          )}

          {taskData.parent_type === 'Risk' && (
            <SelectorInlineInputComponent
              getItems={() => getProjectRiskList(projectId)}
              label={t('risk')}
              getItemLabel={(parent) => parent?.name}
              initialItem={taskData?.parent}
              selectedId={taskData?.parent_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ parent_id: val })
              }}
              inspectorPanel={false}
            />
          )}

          {taskData.control_area_id && taskData?.control_area ? (
            <SelectorInlineInputComponent
              items={ControlAreas}
              label={t('control_area')}
              getItemLabel={(controlArea) => controlArea?.title}
              initialItem={taskData?.control_area}
              selectedId={taskData?.control_area_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ control_area_id: val })
              }}
              disabled={true}
              inspectorPanel={false}
            />
          ) : null}

          {taskData.construction_locomotive_id ? (
            <SelectorInlineInputComponent
              items={wagons}
              label={t('wagon')}
              getItemLabel={(constructionlocomotive) =>
                constructionlocomotive?.title
              }
              initialItem={taskData?.construction_locomotive}
              selectedId={taskData?.construction_locomotive_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ construction_locomotive_id: val })
              }}
              disabled={true}
              inspectorPanel={false}
            />
          ) : null}

          {taskData.taskType === 'Aksjon' && !hideSystem && (
            <SelectorInlineInputComponent
              getItems={() => getProjectRooms(projectId)}
              label={t('room')}
              getItemLabel={(room) =>
                `${room?.room_name} ${room?.functional_room_number}`
              }
              initialItem={taskData?.location}
              selectedId={taskData?.location_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ location_id: val })
              }}
              disabled={disabled}
              inspectorPanel={false}
            />
          )}
          <TextInlineInputCompontent
            label={t('reporter')}
            value={`${taskData?.reporter?.firstName} ${taskData?.reporter?.lastName}`}
            disabled={true}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={taskData?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={taskData?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <TaskReviewInFloorPlan task={taskData} />
        </InlineComponentsWrapper>
      </div>
    )
  }
  return (
    <PageRoot>
      <PageHeader
        title={`${taskData?.record_id ?? 'loading'} - ${
          taskData?.title ?? 'loading'
        }`}
        subTitle={''}
        additionalButtons={extraButtons()}
      />
      <div className="mt-4">
        {optionalFields.length > 0 ? (
          <ContentTabsWrapper
            tabIndex={detailTabIndex}
            tabItems={detailItems}
            onSelect={(index) => setDetailTabIndex(index)}
          >
            <TabPanel>{mainSection()}</TabPanel>
            <TabPanel>
              <div className="flex">
                {metaDataSection(optionalFields, reload)}
              </div>
            </TabPanel>
            <TabPanel>
              <TaskRelations task={taskData} onChangeInput={onChangeInput} />
            </TabPanel>
          </ContentTabsWrapper>
        ) : (
          <ContentTabsWrapper
            tabIndex={detailTabIndex}
            tabItems={[
              capFirstLetter(t('details')),
              capitalize(t('connections')),
            ]}
            onSelect={(index) => setDetailTabIndex(index)}
          >
            <TabPanel>{mainSection()}</TabPanel>
            <TabPanel>
              <TaskRelations task={taskData} onChangeInput={onChangeInput} />
            </TabPanel>
          </ContentTabsWrapper>
        )}
      </div>
      <ContentTabsWrapper
        tabIndex={tabIndex}
        tabItems={tabItems}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabPanel>
          <div className="px-4">
            <RelatedTasks taskId={taskId} />
          </div>
        </TabPanel>
        <TabPanel className={'noTabPanelPadding'}>
          <div className="mx-4">
            <DocumentsList
              projectId={projectId}
              parentId={taskId}
              parentType={'Task'}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-4">
            <Comments
              disabled={disable}
              parentType="Task"
              parentId={taskId}
              includeExportVisibility={['Meeting', 'Topic'].includes(
                taskData.parent_type,
              )}
              canToggleExportVisibility={taskData.can_edit_parent}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="px-4">
            <ChangeLog parentType="Task" parentId={taskId} />
          </div>
        </TabPanel>
      </ContentTabsWrapper>

      {openRiskDetailModal &&
        taskData.parent_type === 'Risk' &&
        risk &&
        risk.id && (
          <RiskInspectorPanel
            riskId={risk.id}
            open={openRiskDetailModal}
            onClose={() => setOpenRiskDetailModal(false)}
            onUpdate={reload}
          />
        )}

      {showImprovementPanel && taskData.improvement_id && (
        <ImprovementPanel
          projectId={projectId}
          improvementId={taskData.improvement_id}
          onClose={toggleImprovementPanel}
          show={showImprovementPanel}
        />
      )}
    </PageRoot>
  )
}

export default TaskDetailPage
