import moment from 'moment'
import {
  IDiscipline,
  IImprovement,
  IMainProcess,
  IMilestone,
  ITeam,
  IKeypoint,
  IPaginateResult,
} from 'src/service/OrgTypes'
import { request } from './service-utils'

export interface IModalOrigin {
  name: string
  url: string
  showHome?: boolean
}

/**
 * Api requests
 */
export const swapOrderMainProcess = (
  mainProcessId: number,
  sequence: number,
): Promise<IMainProcess[]> => {
  return request(`main_processes/${mainProcessId}/change_order`, 'PUT', {
    sequence: sequence,
  })
}

// get main processes for a project
export const getMainProcesses = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/main_processes`, 'GET')
}

// Create a new main process for a projec
export const createMainProcess = (
  projectId: number,
  mainProcess: IMainProcess,
): Promise<any> => {
  return request(`/projects/${projectId}/main_processes`, 'POST', mainProcess)
}

export const updateMainProcess = (
  processId: number,
  mainProcess: IMainProcess,
): Promise<any> => {
  return request(`/main_processes/${processId}`, 'PUT', mainProcess, true)
}

// Delete main process
export const deleteMainProcess = (mainProcessId: number): Promise<any> => {
  return request(`/main_processes/${mainProcessId}`, 'DELETE', {}, true)
}

// Get keypoints
export const getMainprocessKeypoints = (
  mainProcessId: number,
): Promise<any> => {
  return request(`/main_processes/${mainProcessId}/key_points`, 'GET')
}

// get project keyPoints
export const getProjectKeypoints = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/key_points`, 'GET')
}

export const getKeypointMainProcessCanvasData = (
  projectId: number,
  disciplineIds: any,
  _startDate?: any,
  _endDate?: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/key_point_main_process_canvas`,
    'POST',
    disciplineIds,
  )
}

export const getKeypointTemaCanvasData = (
  projectId: number,
  disciplineIds: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/key_point_tema_canvas`,
    'POST',
    disciplineIds,
  )
}

export const getKeypointDisciplineCanvasData = (
  projectId: number,
  disciplineIds: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/key_point_discipline_canvas`,
    'POST',
    disciplineIds,
  )
}

// get project keyPoints
export const getProjectKeypointsFilteredWithPagination = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginateResult<IKeypoint>> =>
  request(
    `projects/${projectId}/key_points/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )

// Get keypoint
export const getKeypointDetails = (
  keypointId: number,
  projectId: number,
): Promise<IKeypoint> => {
  return request(`projects/${projectId}/key_points/${keypointId}`, 'GET')
}

// Get dependent on key_points
export const getDependentOnKeyPoints = (
  projectId: number,
  keypointId: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/key_points/${keypointId}/dependent_on_key_points`,
    'GET',
  )
}

// Get dependent key_points
export const getDependentKeyPoints = (
  projectId: number,
  keypointId: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/key_points/${keypointId}/dependent_key_points`,
    'GET',
  )
}

interface IPlanningData {
  disciplines: IDiscipline[]
  main_processes: IMainProcess[]
  teams: ITeam[]
  mile_stones: IMilestone[]
  improvements: IImprovement[]
}

// Get keypoint data
export const getProjectPlanningData = (
  projectId: number,
): Promise<IPlanningData> => {
  return request(`projects/${projectId}/planning_data`, 'GET')
}

// Add Keypoint
export const addKeyPoint = (
  mainProcessId: number,
  keypoint: IKeypoint,
): Promise<IKeypoint> => {
  return request(
    `/main_processes/${mainProcessId}/key_points`,
    'POST',
    keypoint,
  )
}

// update keypoint
export const updateKeypoint = (
  keypointId: number,
  keypoint: NullablePartial<IKeypoint> | { change_reason?: string },
): Promise<IKeypoint> => {
  return request(`/key_points/${keypointId}`, 'PUT', keypoint)
}

// Delete keypoint
export const deleteKeyPoint = (keypointId: number): Promise<any> => {
  return request(`/key_points/${keypointId}`, 'DELETE', {}, true)
}

export const deleteBulkKeypoints = (
  projectId: number,
  data: number[],
): Promise<any> => {
  return request(`projects/${projectId}/key_points/remove_multiple`, 'POST', {
    ids: data,
  })
}

// Add Keypoint Dependency
export const addKeypointDependency = (
  keypointID: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/key_points/${keypointID}/key_point_dependencies`,
    'PUT',
    dependencies,
    true,
  )
}

export const resetKeypointDependency = (
  keypointID: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/key_points/${keypointID}/reset_key_point_dependencies`,
    'PUT',
    dependencies,
    true,
  )
}

export const resetDeliveryDependency = (
  deliveryId: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/deliveries/${deliveryId}/reset_delivery_dependencies`,
    'PUT',
    dependencies,
    true,
  )
}

export const removeKeypointDependency = (
  keypointID: number,
  dependencies: any,
): Promise<any> => {
  return request(
    `/key_points/${keypointID}/key_point_dependencies/remove`,
    'PUT',
    dependencies,
    true,
  )
}

export const massMoveKeypoints = (projectId: number, body: any) => {
  return request(`/projects/${projectId}/key_points/mass_update`, 'POST', body)
}

export const removeMilestoneFromKeypoints = (
  projectId: number,
  keypointIds: number[],
) => {
  return request(`projects/${projectId}/key_points/remove_mile_stone`, 'POST', {
    key_points: keypointIds,
  })
}

export const getKeypointChangeLog = (keypointId: number): Promise<any> => {
  return request(`key_points/${keypointId}/change_logs`, 'GET')
}

export const getProjectKeypointsGraph = (projectId: number) => {
  return request(`projects/${projectId}/ppus/key_point`, 'GET')
}

export const getProjectPPUbyDataType = (
  projectId: number,
  dataType: string,
  body: { date: moment.Moment[]; parent_type?: string; parent_id?: number[] },
) => {
  return request(`projects/${projectId}/ppus/${dataType}`, 'POST', body)
}

export const getProjectPPUbySpecificWeek = (
  projectId: number,
  dataType: string,
  year: number,
  week: number,
  body: any,
) => {
  return request(
    `projects/${projectId}/ppus/${dataType}/${year}/${week}`,
    'POST',
    body,
  )
}

export const getProjectPPUbyFilter = (
  projectId: number,
  body: { date: moment.Moment[]; parent_type?: string; parent_id?: number[] },
) => {
  return request(`projects/${projectId}/ppus`, 'POST', body)
}

export const getProjectProcesses = (projectId: number) => {
  return request(`/projects/${projectId}/main_processes`, 'GET')
}

export const getProjectTheams = (projectId: number) => {
  return request(`/projects/${projectId}/theams`, 'GET')
}

export const getProjectPhases = (projectId: number) => {
  return request(`/projects/${projectId}/phases`, 'GET')
}

export const reCalculatePPU = (
  projectId: number,
  type: string,
  body?: { week: number; year: number; reset: boolean },
): Promise<any[]> => {
  return request(`projects/${projectId}/ppus/${type}/recalculate`, 'POST', body)
}

export const getProcessTimelineDataFiltered = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(`projects/${projectId}/main_process_canvas`, 'POST', data)
}

export const getKeyPointTree = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/key_points/tree`, 'GET')
}

export const addDeliveriesToKeypoint = (
  keypointId: number,
  projectId: number,
  deliveryIds: number[],
) => {
  return request(
    `projects/${projectId}/key_points/${keypointId}/add_deliveries`,
    'POST',
    { delivery_ids: deliveryIds },
  )
}

export const removeDeliveriesFromKeypoint = (
  keypointId: number,
  projectId: number,
  deliveryIds: number[],
) => {
  return request(
    `projects/${projectId}/key_points/${keypointId}/remove_deliveries`,
    'POST',
    { delivery_ids: deliveryIds },
  )
}
