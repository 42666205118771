import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import { IRisk, IRiskArea, ITask } from 'src/service/OrgTypes'
import { getFilterTasks } from 'src/service/TaskService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import { Icons } from 'src/ui-elements/icon/Icon'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { capFirstLetter } from 'src/utility/utils'
import { getProjectDisciplines } from '../../service/DisciplineService'
import {
  getMainprocessKeypoints,
  getProjectKeypoints,
  getProjectPhases,
  getProjectProcesses,
} from '../../service/ProcessService'
import {
  editRisk,
  getProjectRiskGroups,
  getRisk,
} from '../../service/RiskService'
import { riskStatusTypes } from '../../service/SystemValues'
import { getProjectTags } from '../../service/TagService'
import { getMainprocessTeams, getProjectTeams } from '../../service/TeamService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from '../../service/UserService'
import FixedPane from '../../ui-elements/fixed-pane/FixedPane'
import BooleanInlineInputComponent from '../../ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import DateTimeInlineInputComponent from '../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from '../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from '../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from '../../ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { convertUndefinedToNull } from '../../utility/convertNullToUndefined'
import TaskInspectorPanel from '../task/TaskInspectorPanel'
import { RiskAssesmentPanelContent } from './RiskInspectorHelper'

interface IRiskInspectorPanel {
  riskId: number
  open: boolean
  onUpdate?: () => void
  onClose: () => void
  defaultIndex?: number
}

const RiskInspectorPanel: React.FC<IRiskInspectorPanel> = ({
  riskId,
  open,
  onClose,
  onUpdate,
  defaultIndex = 0,
}) => {
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const { t } = useMultipleKeysTranslation()
  const [risk, setRisk] = useState<IRisk>({} as IRisk)
  const [tasks, setTasks] = useState<ITask[]>([])
  const [riskArea, setRiskArea] = useState<IRiskArea>()
  const [showTaskInspector, setShowTaskInspector] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>()
  const loadRisk = () => {
    getRisk(riskId).then((res: IRisk) => {
      setRisk(res)
      setRiskArea(res?.last_assessment?.risk_area)
    })
  }

  useEffect(() => {
    loadRisk()
  }, [projectId, riskId])

  const loadTasks = () => {
    getFilterTasks(
      projectId,
      { parent_id: [risk.id], parent_type: ['Risk'] },
      1,
      50,
    ).then((res) => {
      setTasks(res.tasks)
    })
  }

  const openTaskInspector = (id: number) => {
    setSelectedTaskId(id)
    setShowTaskInspector(true)
  }

  const closeTaskInspector = () => {
    setSelectedTaskId(undefined)
    setShowTaskInspector(false)
  }

  const getMainContent = () => {
    return (
      <div className="-mt-2 -ml-6">
        <InlineComponentsWrapper padding="left">
          <TextInlineInputCompontent
            label="title"
            value={risk?.title}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('required')
              }
              return undefined
            }}
            onValueSubmitted={(title) => {
              if (title) onChangeInput({ title })
            }}
          />
          <DateTimeInlineInputComponent
            label="deadline"
            selectedTime={`${risk?.deadline}`}
            onValueSubmitted={(deadline) => {
              if (deadline) onChangeInput({ deadline: moment(deadline) })
            }}
            validate={(newValue) => {
              if (!newValue) {
                return t('required')
              }
              return undefined
            }}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            items={riskStatusTypes(t)}
            label="status"
            getItemLabel={(status) => status?.name}
            initialItem={{
              id: risk?.status,
              name:
                riskStatusTypes(t).find((status) => status.id === risk?.status)
                  ?.name ?? '',
            }}
            validate={(value) => {
              if (value === undefined) return t('required')
              return
            }}
            selectedId={risk?.status}
            onValueSubmitted={(status) => {
              onChangeInput({ status })
            }}
            inspectorPanel={true}
          />
          <TextInlineInputCompontent
            label={'risk_area'}
            disabled={true}
            value={riskArea?.name}
          />
          <TextInlineInputCompontent
            label="cause_why"
            value={risk?.cause}
            onValueSubmitted={(cause) => {
              onChangeInput({ cause })
            }}
            textArea
          />

          <TextInlineInputCompontent
            label="event_What"
            value={risk?.incident}
            onValueSubmitted={(incident) => {
              onChangeInput({ incident })
            }}
            textArea
          />

          <TextInlineInputCompontent
            label="impact"
            value={risk?.impact}
            onValueSubmitted={(impact) => {
              onChangeInput({ impact })
            }}
            textArea
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectRiskGroups(projectId)}
            label="risk_assessment"
            initialItem={risk?.risk_group}
            getItemLabel={(risk_group) =>
              `${risk_group?.record_id} - ${risk_group?.title}`
            }
            validate={(value) => {
              if (value === undefined) return t('required')
              return
            }}
            selectedId={risk?.risk_group_id}
            onValueSubmitted={(risk_group_id) => {
              onChangeInput({ risk_group_id })
            }}
            inspectorPanel={true}
          />
          <TextInlineInputCompontent
            label="category"
            value={risk?.category}
            onValueSubmitted={(category) => {
              onChangeInput({ category })
            }}
          />
          <BooleanInlineInputComponent
            label="is_top_ten_risk"
            onValueSubmitted={(is_top_ten) => {
              onChangeInput({ is_top_ten })
            }}
            value={risk.is_top_ten}
            showCheckBox
          />
          <BooleanInlineInputComponent
            label="is_active"
            onValueSubmitted={(is_active) => {
              onChangeInput({ is_active })
            }}
            value={risk.is_active}
            showCheckBox
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={risk?.discipline}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined) return t('required')
              return
            }}
            selectedId={risk?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              addChangesToAppendWhenKeyUpdates('risk_owner_id', {
                discipline_id,
              })
            }}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              risk?.discipline_id
                ? getDisplineUsers(risk?.discipline_id)
                : getProjectUsersWithDisciplines(projectId)
            }
            label="risk_owner"
            getItemLabel={(risk_owner) =>
              `${risk_owner?.firstName} ${risk_owner?.lastName}`
            }
            initialItem={risk?.risk_owner}
            validate={(value) => {
              if (value === undefined) return t('required')
              return
            }}
            selectedId={risk?.risk_owner_id}
            onValueSubmitted={(risk_owner_id) => {
              onChangeInput({ risk_owner_id })
            }}
            dependencies={[risk.discipline_id]}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            label={'contract'}
            disabled={true}
            selectedId={risk?.contract_id}
            getItemLabel={(contract) =>
              `${contract?.contractName} - ${contract?.contractName}`
            }
            initialItem={risk?.contract}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectProcesses(projectId)}
            label="main_process"
            initialItem={risk?.main_process}
            getItemLabel={(main_process) => main_process?.name}
            selectedId={risk?.main_process_id}
            validate={(value) => {
              if (value === undefined) return t('required')
              return
            }}
            onValueSubmitted={(main_process_id) => {
              onChangeInput({ main_process_id, team_id: undefined })
            }}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              risk.main_process_id
                ? getMainprocessTeams(risk.main_process_id)
                : getProjectTeams(projectId)
            }
            label="theme"
            initialItem={risk?.team}
            getItemLabel={(team) => team?.name}
            selectedId={risk?.team_id}
            onValueSubmitted={(team_id) => {
              onChangeInput({ team_id })
            }}
            dependencies={[risk?.main_process_id]}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              risk.main_process_id
                ? getMainprocessKeypoints(risk.main_process_id)
                : getProjectKeypoints(projectId)
            }
            label="keypoint"
            initialItem={risk?.key_point}
            getItemLabel={(key_point) =>
              `${key_point?.record_id} - ${key_point?.name}`
            }
            selectedId={risk?.key_point_id}
            onValueSubmitted={(key_point_id) => {
              onChangeInput({ key_point_id })
            }}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectPhases(projectId)}
            label="phase"
            initialItem={risk?.phase}
            getItemLabel={(phase) => phase?.phaseName}
            selectedId={risk?.phase_id}
            onValueSubmitted={(phase_id) => {
              onChangeInput({ phase_id })
            }}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectTags(projectId)}
            label="type"
            initialItem={risk?.tag}
            getItemLabel={(tag) => tag?.name}
            selectedId={risk?.tag_id}
            onValueSubmitted={(tag_id) => {
              onChangeInput({ tag_id })
            }}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={risk?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={risk?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }

  const sections: IInspectorSection[] = [
    {
      name: capFirstLetter(t('details')),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      onClick: loadRisk,
      content: getMainContent(),
    },
    {
      name: capFirstLetter(t('risk_assessment')),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <RiskAssesmentPanelContent
          riskId={risk.id}
          riskGroupId={risk.risk_group_id}
        />
      ),
    },
    {
      name: t('cases'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      onClick: loadTasks,
      content: (
        <InspectorSectionTable
          headerColumns={[t('id'), t('title'), t('status'), t('deadline')]}
          rowsData={
            tasks && tasks?.length > 0
              ? tasks.map((issue) => {
                  return {
                    cells: [
                      issue?.record_id ?? '',
                      issue.title,
                      t(issue.status) ?? '',
                      issue.deadline ? moment(issue.deadline).format('L') : '',
                    ],
                    id: issue.id,
                  }
                })
              : []
          }
          handleClick={openTaskInspector}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={risk.id} parentType="Risk" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={risk.id} parentType="Risk" />,
    },
  ]

  const onCloseInspector = () => {
    onClose?.()
  }

  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<IRisk>(setRisk, risk)

  const onChangeInput = (update: Partial<IRisk>) => {
    const allUpdates = getChangesForUpdate(update)
    editRisk({
      ...convertUndefinedToNull(allUpdates),
      id: risk?.id,
    }).then((_res: IRisk) => {
      loadRisk()
      onUpdate?.()
    })
  }

  return (
    <>
      <FixedPane
        title={risk ? `${risk.record_id}-${risk.title}` : 'loading'}
        show={open}
        onClose={onCloseInspector}
        className={'w-[700px]'}
        disableOutsideClose={true}
        detailPageData={{
          key: DetailPageKeys.RISK,
          ids: {
            riskGroupId: risk.risk_group_id,
            riskId: risk.id,
          },
        }}
      >
        <InspectorSections
          defaultIndex={defaultIndex}
          sections={sections}
          noMainContent={true}
        />
      </FixedPane>
      {selectedTaskId && showTaskInspector && (
        <TaskInspectorPanel
          taskId={selectedTaskId}
          open={showTaskInspector}
          onClose={closeTaskInspector}
          projectId={projectId}
          onUpdate={loadTasks}
        />
      )}
    </>
  )
}
export default RiskInspectorPanel
