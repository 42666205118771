import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import history from 'src/history'
import {
  createConstructionChecklist,
  getConstructionLocomotivesChecklists,
} from 'src/service/ConstructionChecklistService'
import { getConstructionWagon } from 'src/service/ConstructionWagonService'
import {
  IConstructionChecklist,
  IConstructionWagon,
  ITask,
} from 'src/service/OrgTypes'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import Input from 'src/ui-elements/input/Input'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import RadioItems, { IRadioItems } from 'src/ui-elements/radio/RadioItems'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { toFixed } from '../../utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'
import { contentStyle } from '../MUtils'
import BottomActionBtns from '../components/BottomActionBtns'
import MCard from '../components/MCard'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MAddIssue from './MAddIssue'
import MVognInfo from './MVognInfo'

const MAddTavlamote = () => {
  const styleClass = {
    root: classNames('bg-mobile', 'w-screen', 'h-screen'),
    container: classNames('p-4', 'overflow-y-auto'),
    row: classNames('flex', 'flex-nowrap', 'items-center'),
    label: classNames(
      'text-xs',
      'text-gray-600',
      'font-light',
      'p-2 pl-0',
      'min-w-24 ',
    ),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'text-xs',
      'font-light',
    ),
  }

  const { t } = useTranslation()
  const { vid } = useParams<{ vid: string }>()
  const userContext: IUserContext = useContext(UserContext)
  const projectContext: IProjectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const [numberOfWorkers, setNumberOfWorkers] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)
  const [allPresent, setAllPresent] = useState<boolean>(false)
  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [shaColor, setShaColor] = useState<string>('')
  const [qualityColor, setQualityColor] = useState<string>('')
  const [neatnessColor, setNeatnessColor] = useState<string>('')
  const [progressColor, setProgressColor] = useState<string>('')
  const [staffingColor, setStaffingColor] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  const [shaError, setShaError] = useState<string>('')
  const [qualityError, setQualityError] = useState<string>('')
  const [neatnessError, setNeatnessError] = useState<string>('')
  const [progressError, setProgressError] = useState<string>('')
  const [staffingError, setStaffingError] = useState<string>('')
  const [prevTavlamote, setPrevTavlamote] = useState<IConstructionChecklist>(
    {} as IConstructionChecklist,
  )
  const [tasks, setTasks] = useState<any>({
    sha: [],
    quality: [],
    neatness: [],
    progress: [],
    staffing: [],
  })
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const [newTaskType, setNewTaskType] = useState<string>('')

  const onBackClick = () => {
    history.goBack()
  }

  useEffect(() => {
    if (+vid) {
      getConstructionWagon(+vid).then((res) => {
        setWagon(res)
      })
      getConstructionLocomotivesChecklists(+vid).then((res) => {
        if (res.length > 0) {
          setPrevTavlamote(res[0])
        }
      })
    }
  }, [vid, projectId])

  const getItem = (color: string) => (
    <div
      className={`rounded-full flex w-6 h-6 bg-${color} hover:border-white focus:border-white mr-2`}
    />
  )
  const getVal = (val?: string) => (
    <div
      className={`flex justify-center items-center rounded-full mr-1 w-6 h-6 bg-gray-mobile text-gray-300 border border-gray-300`}
    >
      {val}
    </div>
  )

  const onPresentChange = () => {
    setAllPresent((v) => !v)
  }

  const onSHAChange = (e: any) => {
    setShaColor(e.target.value)
    if (
      e.target.value !== 'green' &&
      tasks?.sha?.length === 0 &&
      (!isEmpty(prevTavlamote)
        ? prevTavlamote.check_list_task_count?.sha_checklist === 0
        : true)
    ) {
      setShaError(t('add_issue_to_sha'))
      return
    }
    setShaError('')
  }

  const onQualityChange = (e: any) => {
    setQualityColor(e.target.value)
    if (
      e.target.value !== 'green' &&
      tasks?.quality?.length === 0 &&
      (!isEmpty(prevTavlamote)
        ? prevTavlamote.check_list_task_count?.quality_checklist === 0
        : true)
    ) {
      setQualityError(t('add_issue_to_quality'))
      return
    }
    setQualityError('')
  }

  const onNeatnessChange = (e: any) => {
    setNeatnessColor(e.target.value)
    if (
      e.target.value !== 'green' &&
      tasks?.neatness?.length === 0 &&
      (!isEmpty(prevTavlamote)
        ? prevTavlamote.check_list_task_count?.neatness_checklist === 0
        : true)
    ) {
      setNeatnessError(t('add_issue_to_neatness'))
      return
    }
    setNeatnessError('')
  }

  const onProgressChange = (e: any) => {
    setProgressColor(e.target.value)
    if (
      e.target.value !== 'green' &&
      tasks?.progress?.length === 0 &&
      (!isEmpty(prevTavlamote)
        ? prevTavlamote.check_list_task_count?.progress_checklist === 0
        : true)
    ) {
      setProgressError(t('add_issue_to_progress'))
      return
    }
    setProgressError('')
  }

  const onStaffingChange = (e: any) => {
    setStaffingColor(e.target.value)
    if (
      e.target.value !== 'green' &&
      tasks?.staffing?.length === 0 &&
      (!isEmpty(prevTavlamote)
        ? prevTavlamote.check_list_task_count?.staffing_checklist === 0
        : true)
    ) {
      setStaffingError(t('add_issue_to_number_of_workers'))
      return
    }
    setStaffingError('')
  }

  const onWorkersChange = (e: any) => {
    const val = e.target.value
    setNumberOfWorkers(val)
  }

  const addTask = (type: string) => {
    setShowTaskModal(true)
    setNewTaskType(type)
  }

  const getWagonDeadline = () => {
    return moment(wagon.startTime).add(wagon.extended_duration, 'days')
  }

  const statusTypes = (_disable: boolean): IRadioItems[] => {
    return [
      {
        id: 'green',
        name: <div className={'h-25'} />,
        classNames: 'green_radio',
      },
      {
        id: 'yellow',
        name: <div className={'h-25'} />,
        classNames: 'yellow_radio',
      },
      {
        id: 'red',
        name: <div className={'h-25'} />,
        classNames: 'red_radio',
      },
    ]
  }

  const setNewTasks = (type: string, task: ITask) => {
    const cloneTasks = { ...tasks }
    if (cloneTasks[type]) {
      cloneTasks[type].push(task)
    }
    setTasks(cloneTasks)
    switch (type) {
      case 'sha':
        const e1 = { target: { value: shaColor } }
        onSHAChange(e1)
        break
      case 'quality':
        const e2 = { target: { value: qualityColor } }
        onQualityChange(e2)
        break
      case 'neatness':
        const e3 = { target: { value: neatnessColor } }
        onNeatnessChange(e3)
        break
      case 'progress':
        const e4 = { target: { value: progressColor } }
        onProgressChange(e4)
        break
      case 'staffing':
        const e5 = { target: { value: staffingColor } }
        onStaffingChange(e5)
        break
    }
  }

  const getTasksLength = (type: string) => {
    return !isEmpty(prevTavlamote) && prevTavlamote?.check_list_task_count
      ? prevTavlamote?.check_list_task_count[`${type}_checklist`] +
          tasks[type]?.length
      : tasks[type]?.length
  }

  const hasValidationErrors = () => {
    const values = [
      shaError,
      qualityError,
      neatnessError,
      progressError,
      staffingError,
    ]
    const found = values.find((item) => item !== '')
    return found ? true : false
  }

  const submit = (e: any) => {
    e.preventDefault()

    let error = false

    if (!shaColor) {
      setShaError(t('add_status_checklist', { type: t('sha') }))
      error = true
    }

    if (!qualityColor) {
      setQualityError(t('add_status_checklist', { type: t('quality') }))
      error = true
    }

    if (!neatnessColor) {
      setNeatnessError(t('add_status_checklist', { type: t('neatness') }))
      error = true
    }

    if (!progressColor) {
      setProgressError(t('add_status_checklist', { type: t('progresss') }))
      error = true
    }

    if (!staffingColor) {
      setStaffingError(t('add_status_checklist', { type: t('staffing') }))
      error = true
    }

    if (!error && !hasValidationErrors()) {
      setLoading(true)
      const updatedConstructionChecklist: IConstructionChecklist = {
        sha_color: shaColor,
        quality_color: qualityColor,
        neatness_color: neatnessColor,
        progress_color: progressColor,
        staffing_color: staffingColor,
        comment,
        construction_locomotive_id: +vid,
        number_of_workers: numberOfWorkers,
        all_disciplines_present: allPresent,
        ...tasks,
      }

      createConstructionChecklist(updatedConstructionChecklist, +vid).then(
        () => {
          setLoading(false)
          history.goBack()
        },
      )
    }
  }

  return (
    <MPage bgColor={'mobile'}>
      {!showTaskModal ? (
        <>
          <MHeader
            showHomeBtn={true}
            showUser={false}
            user={userContext.state.user}
          />
          <div style={contentStyle} className={styleClass.container}>
            {loading ? (
              <div className={'w-full h-full grid place-items-center'}>
                <SpinnerMobile />
              </div>
            ) : (
              <>
                {wagon && (
                  <div
                    className={
                      'w-full bg-white rounded shadow overflow-hidden mb-2'
                    }
                  >
                    <div className={'w-full md:w-1/2 bg-white'}>
                      <MVognInfo wagon={wagon} isCard={false} />
                    </div>
                  </div>
                )}
                <MCard>
                  <div className={'flex flex-col w-full'}>
                    <div className={`flex mb-3 w-full`}>
                      <div
                        className={'mr-1 min-w-18 max-w-18 invisible flex-0'}
                      />
                      <div
                        className={
                          'flex text-xs text-gray-500 justify-between flex-auto min-w-0 px-2'
                        }
                      >
                        <p
                          className={
                            'w-1/3' + !isEmpty(prevTavlamote)
                              ? 'visible'
                              : 'invisible'
                          }
                        >
                          {moment(prevTavlamote.created_at).format(
                            'MM DD YYYY',
                          )}
                        </p>
                        <div className={'w-2/3 flex justify-between'}>
                          <p>{capFirstLetter(t('select'))}</p>
                          <p>{capFirstLetter(t('cases'))}</p>
                        </div>
                      </div>
                    </div>
                    {shaError && (
                      <p className={styleClass.errorMessage}>{shaError}</p>
                    )}
                    <div
                      className={
                        'flex items-center text-xs font-medium text-gray-600 mb-1'
                      }
                    >
                      <div className={'mr-1 min-w-18 max-w-18'}>{t('sha')}</div>

                      <div
                        className={`${
                          !isEmpty(prevTavlamote) ? 'visible' : 'invisible'
                        } flex opacity-90 transform scale-75 md:w-1/3`}
                      >
                        {getItem(`m-${prevTavlamote?.sha_color}`)}
                        {getVal(
                          `${prevTavlamote?.check_list_task_count?.sha_checklist}`,
                        )}
                      </div>

                      <div
                        className={`${styleClass.row} md:justify-between md:w-2/3`}
                      >
                        <div className={'transform scale-75'}>
                          <RadioItems
                            disableTitle={t(
                              'please_create_one_or_more_issues_to_select_medium_or_high',
                            )}
                            items={statusTypes(false)}
                            onClick={onSHAChange}
                            value={shaColor}
                            noMargin={true}
                            inMobile={true}
                          />
                        </div>
                        {
                          <div className={'flex items-center'}>
                            <Icon
                              icon={Icons.PLUS}
                              className={
                                'flex pl-px justify-center items-center rounded-full w-5 h-5 border-gray-200 border'
                              }
                              onClick={() => addTask('sha')}
                            />
                            <span
                              className={
                                'ml-2 flex justify-center items-center w-5 h-5 bg-gray-100 text-gray-800 rounded-full border-gray-200 border'
                              }
                            >
                              {getTasksLength('sha')}
                            </span>
                          </div>
                        }
                      </div>
                    </div>

                    {qualityError && (
                      <p className={styleClass.errorMessage}>{qualityError}</p>
                    )}
                    <div
                      className={
                        'flex items-center text-xs font-medium text-gray-600 mb-1'
                      }
                    >
                      <div className={'mr-1 min-w-18 max-w-18'}>
                        {t('quality')}
                      </div>

                      <div
                        className={`${
                          !isEmpty(prevTavlamote) ? 'visible' : 'invisible'
                        } flex opacity-90 transform scale-75  md:w-1/3`}
                      >
                        {getItem(`m-${prevTavlamote?.quality_color}`)}
                        {getVal(
                          `${prevTavlamote?.check_list_task_count?.quality_checklist}`,
                        )}
                      </div>

                      <div
                        className={`${styleClass.row} md:justify-between md:w-2/3`}
                      >
                        <div className={'transform scale-75'}>
                          <RadioItems
                            disableTitle={t(
                              'please_create_one_or_more_issues_to_select_medium_or_high',
                            )}
                            items={statusTypes(false)}
                            onClick={onQualityChange}
                            value={qualityColor}
                            noMargin={true}
                            inMobile={true}
                          />
                        </div>
                        {
                          <div className={'flex items-center'}>
                            <Icon
                              icon={Icons.PLUS}
                              className={
                                'flex pl-px justify-center items-center rounded-full w-5 h-5 border-gray-200 border'
                              }
                              onClick={() => addTask('quality')}
                            />
                            <span
                              className={
                                'ml-2 flex justify-center items-center w-5 h-5 bg-gray-100 text-gray-800 rounded-full border-gray-200 border'
                              }
                            >
                              {getTasksLength('quality')}
                            </span>
                          </div>
                        }
                      </div>
                    </div>

                    {neatnessError && (
                      <p className={styleClass.errorMessage}>{neatnessError}</p>
                    )}
                    <div
                      className={
                        'flex items-center text-xs font-medium text-gray-600 mb-1'
                      }
                    >
                      <div className={'mr-1 min-w-18 max-w-18'}>
                        {t('neatness')}
                      </div>

                      <div
                        className={`${
                          !isEmpty(prevTavlamote) ? 'visible' : 'invisible'
                        } flex opacity-90 transform scale-75  md:w-1/3`}
                      >
                        {getItem(`m-${prevTavlamote?.neatness_color}`)}
                        {getVal(
                          `${prevTavlamote?.check_list_task_count?.neatness_checklist}`,
                        )}
                      </div>

                      <div
                        className={`${styleClass.row} md:justify-between md:w-2/3`}
                      >
                        <div className={'transform scale-75'}>
                          <RadioItems
                            disableTitle={t(
                              'please_create_one_or_more_issues_to_select_medium_or_high',
                            )}
                            items={statusTypes(false)}
                            onClick={onNeatnessChange}
                            value={neatnessColor}
                            noMargin={true}
                            inMobile={true}
                          />
                        </div>
                        {
                          <div className={'flex items-center'}>
                            <Icon
                              icon={Icons.PLUS}
                              className={
                                'flex pl-px justify-center items-center rounded-full w-5 h-5 border-gray-200 border'
                              }
                              onClick={() => addTask('neatness')}
                            />
                            <span
                              className={
                                'ml-2 flex justify-center items-center w-5 h-5 bg-gray-100 text-gray-800 rounded-full border-gray-200 border'
                              }
                            >
                              {getTasksLength('neatness')}
                            </span>
                          </div>
                        }
                      </div>
                    </div>

                    {progressError && (
                      <p className={styleClass.errorMessage}>{progressError}</p>
                    )}
                    <div
                      className={
                        'flex items-center text-xs font-medium text-gray-600 mb-1'
                      }
                    >
                      <div className={'mr-1 min-w-18 max-w-18'}>
                        {t('progress')}
                      </div>

                      <div
                        className={`${
                          !isEmpty(prevTavlamote) ? 'visible' : 'invisible'
                        } flex opacity-90 transform scale-75  md:w-1/3`}
                      >
                        {getItem(`m-${prevTavlamote?.progress_color}`)}
                        {getVal(
                          `${prevTavlamote?.check_list_task_count?.progress_checklist}`,
                        )}
                      </div>

                      <div
                        className={`${styleClass.row} md:justify-between md:w-2/3`}
                      >
                        <div className={'transform scale-75'}>
                          <RadioItems
                            disableTitle={t(
                              'please_create_one_or_more_issues_to_select_medium_or_high',
                            )}
                            items={statusTypes(false)}
                            onClick={onProgressChange}
                            value={progressColor}
                            noMargin={true}
                            inMobile={true}
                          />
                        </div>
                        {
                          <div className={'flex items-center'}>
                            <Icon
                              icon={Icons.PLUS}
                              className={
                                'flex pl-px justify-center items-center rounded-full w-5 h-5 border-gray-200 border'
                              }
                              onClick={() => addTask('progress')}
                            />
                            <span
                              className={
                                'ml-2 flex justify-center items-center w-5 h-5 bg-gray-100 text-gray-800 rounded-full border-gray-200 border'
                              }
                            >
                              {getTasksLength('progress')}
                            </span>
                          </div>
                        }
                      </div>
                    </div>

                    {staffingError && (
                      <p className={styleClass.errorMessage}>{staffingError}</p>
                    )}
                    <div
                      className={
                        'flex items-center text-xs font-medium text-gray-600 mb-1'
                      }
                    >
                      <div className={'mr-1 min-w-18 max-w-18'}>
                        {t('number_of_workers')}
                      </div>

                      <div
                        className={`${
                          !isEmpty(prevTavlamote) ? 'visible' : 'invisible'
                        } flex opacity-90 transform scale-75  md:w-1/3`}
                      >
                        {getItem(`m-${prevTavlamote?.staffing_color}`)}
                        {getVal(
                          `${prevTavlamote?.check_list_task_count?.staffing_checklist}`,
                        )}
                      </div>

                      <div
                        className={`${styleClass.row} md:justify-between md:w-2/3`}
                      >
                        <div className={'transform scale-75'}>
                          <RadioItems
                            disableTitle={t(
                              'please_create_one_or_more_issues_to_select_medium_or_high',
                            )}
                            items={statusTypes(false)}
                            onClick={onStaffingChange}
                            value={staffingColor}
                            noMargin={true}
                            inMobile={true}
                          />
                        </div>
                        {
                          <div className={'flex items-center'}>
                            <Icon
                              icon={Icons.PLUS}
                              className={
                                'flex pl-px justify-center items-center rounded-full w-5 h-5 border-gray-200 border'
                              }
                              onClick={() => addTask('staffing')}
                            />
                            <span
                              className={
                                'ml-2 flex justify-center items-center w-5 h-5 bg-gray-100 text-gray-800 rounded-full border-gray-200 border'
                              }
                            >
                              {getTasksLength('staffing')}
                            </span>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </MCard>

                <div className={'my-4 flex flex-no-wrap items-center px-1'}>
                  <div className={'text-gray-500 text-xs'}>
                    {t('staffing_in_the_area')}:{' '}
                    {wagon?.planned_staffing !== null
                      ? toFixed(
                          wagon?.planned_staffing ? wagon?.planned_staffing : 0,
                        )
                      : ''}
                  </div>
                </div>
                <div className={'my-4'}>
                  <Input
                    inMobile={true}
                    disabled={false}
                    block={true}
                    placeholder={t('number_of_workers_in_the_area')}
                    required={true}
                    value={numberOfWorkers}
                    type={'number'}
                    minValue={0}
                    onChange={onWorkersChange}
                    noPadding={true}
                  />
                </div>
                <div className={'flex justify-between px-1'}>
                  <p className={'text-gray-500 text-xs'}>
                    {t('were_all_disciplines_in_the_control_area_present')}
                  </p>
                  <SwitchHOC
                    valueProp={allPresent}
                    className="custom-classname"
                    onChange={onPresentChange}
                  />
                </div>
                <div className={'w-full my-4 mb-20'}>
                  <Textarea
                    value={comment}
                    placeholder={t('comment_to_board_meeting')}
                    hidelabel={true}
                    label={t('comment_to_board_meeting')}
                    isValid={false}
                    inMobile={true}
                    onChange={(e) => setComment(e.target.value)}
                    noPaddingX={true}
                    block={true}
                    rows={8}
                  />
                </div>
                <BottomActionBtns onSave={submit} onCancel={onBackClick} />
              </>
            )}
          </div>
        </>
      ) : (
        <MAddIssue
          projectId={projectId}
          wagon={wagon}
          controlAreaId={wagon?.control_area_id}
          parentDeadline={getWagonDeadline()}
          onClose={() => setShowTaskModal(false)}
          submitTask={(task) => setNewTasks(newTaskType, task)}
          title={t('add_board_meeting_issue')}
        />
      )}
    </MPage>
  )
}

export default MAddTavlamote
