import { useMemo } from 'react'
import { ITask } from 'src/service/OrgTypes'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'

export const useTaskDetailPageProp = (
  taskData: ITask | undefined,
  module?: 'planning',
) => {
  return useMemo(() => {
    if (!taskData) return
    if (module === 'planning') {
      return {
        key: DetailPageKeys.TASK,
        ids: {
          taskId: taskData?.id,
        },
      }
    }
    if (!taskData.task_type_id) {
      return {
        key: DetailPageKeys.TASK_WITHOUT_CASE_TYPE,
        ids: {
          taskId: taskData?.id,
        },
      }
    }
    return {
      key: DetailPageKeys.TASK_UNDER_CASE_TYPE,
      ids: {
        taskTypeId: taskData?.task_type_id,
        taskId: taskData.id,
      },
    }
  }, [taskData])
}
