import Add from '@icons/add.svg'
import { useQueryClient } from '@tanstack/react-query'
import { useState, useContext } from 'react'

import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import MilestoneForm from 'src/components/milestones/MilestoneForm'
import MilestoneInspectorPanel from 'src/components/milestones/MilestoneInspectorPanel'
import { useMilestoneColumns } from 'src/components/milestones/useMilestoneColumns'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { milestoneExportData } from 'src/export-templates/MilestoneExports'
import { milestoneListImportTemplate } from 'src/export-templates/MilestoneImport'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import useUserAccess from 'src/hooks/useUserAccess'
import { useFilteredMilestonesWithPagination } from 'src/query/planning/milestones'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import {
  deleteBulkMilestones,
  deleteMilestone,
} from 'src/service/MilestoneService'
import { IImportItemList, IMilestone } from 'src/service/OrgTypes'
import Table from 'src/ui-elements/Table/Table'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import useAlert from 'src/ui-elements/toast/useAlert'
import { addUserDefinedInfoToImport } from 'src/utility/exportUtils'

interface IMilestoneListProps {
  onOpenItem: (id: number, type: string, parentId?: number) => void
  reloadTree?: () => void
}

const MilestoneList = ({ onOpenItem, reloadTree }: IMilestoneListProps) => {
  const { t } = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [copyMilestone, setCopyMilestone] =
    useState<NullablePartial<IMilestone>>()
  const [selectedMilestone, setSelectedMilestone] = useState<IMilestone>()
  const [showCopyModal, setShowCopyModal] = useState<boolean>(false)
  const [showInspectorPanel, setShowInspectorPanel] = useState(false)
  const { addAlert } = useAlert()

  const queryClient = useQueryClient()
  const projectId = useProjectId()

  const projectContext = useContext(ProjectContext)
  const { isBaselineAdmin } = projectContext.state
  const { writeAccess } = useUserAccess('planning')
  const canEdit = writeAccess || isBaselineAdmin

  const { confirmDelete } = useDeleteModal()

  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('Milestone')

  const handlePreviewClick = (data: IMilestone) => {
    setShowInspectorPanel(true)
    setSelectedMilestone(data)
  }

  const onCloneItem = (row: IMilestone) => {
    setShowCopyModal(true)
    setCopyMilestone({ ...row, name: `${row.name}-Copy`, id: null })
  }

  const reload = () => {
    queryClient.refetchQueries({ queryKey: ['milestonesFilteredPaginated'] })
    reloadTree?.()
  }

  const importMilestoneTemplate: IImportItemList = {
    title: t('upload_milestones'),
    templateJson: addUserDefinedInfoToImport(
      milestoneListImportTemplate,
      [],
      systemTypeGroup?.optional_fields ?? [],
    ),
    type: 'mile_stones',
    reload,
  }

  const onDeleteItemClick = async (milestone: IMilestone) => {
    const confirmed = await confirmDelete({
      itemName: milestone.name,
      itemIdnType: 'milestone',
    })
    if (!confirmed) return
    await deleteMilestone(milestone)
    reload()
  }

  const closeFormModal = () => {
    setShowEditModal(false)
    setShowCreateModal(false)
    setShowCopyModal(false)
    reload()
  }

  const deleteSelectedMilestones = (ids: number[]) => {
    deleteBulkMilestones(projectId, ids).then(() => {
      addAlert({
        type: 'success',
        title: t('successfully_deleted'),
        description: t('selected_items_deleted', { items: t('milestones') }),
        autoClose: true,
      })
      reload()
      if (reloadTree) {
        reloadTree()
      }
    })
  }

  const { columns, legacyColumns, defaultOrdering } = useMilestoneColumns({
    disabled: !canEdit,
    reload: reloadTree,
  })

  return (
    <>
      <Table
        name={'milestoneList'}
        columns={columns}
        legacyColumns={legacyColumns}
        defaultOrdering={defaultOrdering}
        useDataQuery={useFilteredMilestonesWithPagination}
        onRowClick={(row) => onOpenItem(row.id, 'milestone')}
        onCopyClick={onCloneItem}
        onDeleteClick={onDeleteItemClick}
        onPreviewClick={handlePreviewClick}
        tableButtons={() => ({
          onBulkDelete: canEdit ? deleteSelectedMilestones : undefined,
          exportData: milestoneExportData,
          importItem: canEdit ? importMilestoneTemplate : undefined,
          customButtons: [
            <Button
              key={'create-milestone'}
              type={Button.ButtonType.PRIMARY}
              size={Button.ButtonSize.SMALL}
              onClick={() => setShowCreateModal(true)}
            >
              <Add className={'text-xl'} />
              {t('new_milestone')}
            </Button>,
          ],
        })}
      />

      <Modal
        show={showCreateModal}
        closeModal={() => setShowCreateModal(false)}
        title={t('add_milestone')}
        maxWidth={800}
        confirmOutsideClick={true}
      >
        <MilestoneForm
          milestone={{} as IMilestone}
          projectId={projectId}
          closeModal={closeFormModal}
          reloadList={reload}
        />
      </Modal>
      {selectedMilestone && (
        <Modal
          show={showEditModal}
          closeModal={() => setShowEditModal(false)}
          size={'w-5/6'}
          title={
            t('edit_item', { item: t('milestone') }) +
            selectedMilestone.record_id
          }
          maxWidth={800}
        >
          <MilestoneForm
            milestone={selectedMilestone}
            projectId={projectId}
            closeModal={closeFormModal}
          />
        </Modal>
      )}

      {copyMilestone && (
        <Modal
          show={showCopyModal}
          closeModal={() => setShowCopyModal(false)}
          title={t('add_milestone')}
          maxWidth={800}
          confirmOutsideClick={true}
        >
          <MilestoneForm
            milestone={copyMilestone as IMilestone}
            projectId={projectId}
            closeModal={closeFormModal}
            reloadList={reload}
          />
        </Modal>
      )}

      {showInspectorPanel && selectedMilestone?.id && (
        <MilestoneInspectorPanel
          origin={{
            name: t('table_overview'),
            url: '/milestone?tabIndex=0',
          }}
          milestoneId={selectedMilestone.id}
          open={showInspectorPanel}
          onClose={() => setShowInspectorPanel(false)}
          onUpdate={reload}
        />
      )}
    </>
  )
}

export default MilestoneList
