import {
  ColumnDef,
  VisibilityState,
  createColumnHelper,
} from '@tanstack/react-table'
import { t } from 'i18next'
import { capitalize } from 'lodash'
import { useRef } from 'react'
import { userDefinedColumns } from 'src/components/TableColumns/Columns'
import {
  ResponsibleAndDisciplineColumn,
  DisciplineAndResponsibleColumn,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import { MainProcessAndThemeColumn } from 'src/components/TableColumns/MainProcessTemaColumns'
import useProjectId from 'src/components/hooks/useProjectId'
import { useKeypointUpdateValidation } from 'src/components/key-point/useKeypointUpdateValidation'
import { IStatusCell } from 'src/components/status-dropdown/TableStatusLabel'
import { IMetaValue } from 'src/document/types/IMetaData'
import { useDomainSystemTypeGroupMetaData } from 'src/query/systemTypeGroups'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import { getProjectMilestones } from 'src/service/MilestoneService'
import {
  IDiscipline,
  IMainProcess,
  IStatusTypes,
  ITeam,
  IUserData,
  IKeypoint,
  IMilestone,
  ITag,
  IImprovement,
} from 'src/service/OrgTypes'
import { updateKeypoint } from 'src/service/ProcessService'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getProjectTeams } from 'src/service/TeamService'
import {
  defaultTextColumn,
  defaultDateColumnObject,
  defaultUserColumn,
  defaultEditableTextColumn,
  statusColumn,
  defaultMultiFilterColumn,
  defaultNumberColumn,
  multiFilterOptionsColumn,
  defaultChildrenCountColumn,
} from 'src/ui-elements/Table/Columns'
import useListHelper from 'src/ui-elements/list/UseListHelper'

interface IKeypointColumnType {
  onDateChange?: (keypoint: IKeypoint, date: string) => void
  disabled?: boolean
  reload?: () => void
}

export function useKeypointColumns({
  onDateChange,
  disabled = false,
  reload,
}: IKeypointColumnType) {
  const {
    getMilestoneFilter,
    getTagFilter,
    getImprovementFilter,
    getReporterFilter,
    getContractFilter,
    getTeamFilter,
  } = useListHelper()

  const projectId = useProjectId()
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])
  const mainProcesses = useRef<IMainProcess[]>([])
  const themes = useRef<ITeam[]>([])
  const { data: metaData } = useDomainSystemTypeGroupMetaData('KeyPoint')

  const { isUpdatingDateInFreeze, validateDate, validateStatus } =
    useKeypointUpdateValidation()

  const onUpdateKeypoint = async (updatedKeypoint: Partial<IKeypoint>) => {
    if (updatedKeypoint.id) {
      await updateKeypoint(updatedKeypoint.id, updatedKeypoint)
      reload?.()
    }
  }

  const onStatusSelect = (
    status: IStatusTypes,
    key: number,
    keypoint: IStatusCell,
  ) => {
    const isValid = validateStatus(keypoint, status.id)
    if (!isValid) return
    onUpdateKeypoint({ id: key, status: status.id })
  }

  const onDateUpdate = (date: string, keyPoint: IKeypoint) => {
    if (isUpdatingDateInFreeze(date, keyPoint.endTime)) {
      onDateChange?.(keyPoint, date)
      return
    }
    onUpdateKeypoint({ id: keyPoint.id, endTime: date })
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const keypoint = { id: key, [field]: value }
    onUpdateKeypoint(keypoint)
  }

  const updateResponsible = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const keypoint = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IKeypoint
    onUpdateKeypoint(keypoint)
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const keypoint = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IKeypoint
    onUpdateKeypoint(keypoint)
  }

  const updateMainProcess = (
    id: number,
    mainProcessId: number,
    teamId: number,
  ) => {
    const keypoint = {
      id,
      main_process_id: mainProcessId,
      team_id: teamId,
    } as IKeypoint
    onUpdateKeypoint(keypoint)
  }

  const c = (s: string) => capitalize(t(s))

  const keypointsColumnHelper = createColumnHelper<IKeypoint>()

  const columns = [
    keypointsColumnHelper.accessor(
      'record_id',
      defaultTextColumn('record_id', {
        name: capitalize(t('id')),
      }),
    ),
    keypointsColumnHelper.accessor(
      'name',
      defaultEditableTextColumn(
        'name',
        { name: capitalize('title') },
        () => {},
        disabled,
      ),
    ),
    statusColumn(
      'status',
      ['status', 'endTime', 'expired_children', 'open_children'],
      { name: c('status') },
      statusTypes(t),
      !disabled ? onStatusSelect : undefined,
    ),
    keypointsColumnHelper.accessor(
      'endTime',
      defaultDateColumnObject(
        'endTime',
        { name: capitalize('deadline') },
        undefined,
        false,
        onDateUpdate,
        validateDate,
      ),
    ),
    ResponsibleAndDisciplineColumn(
      projectId,
      projectUsers,
      projectDiscipline,
      updateResponsible,
      disabled,
    ),
    DisciplineAndResponsibleColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      updateDiscipline,
      disabled,
    ),
    multiFilterOptionsColumn(
      'contract',
      {
        name: c('contract'),
        getFilter: getContractFilter,
        field: 'contract.contractNumber||contract.contractName',
      },
      ['contractNumber', 'contractName'],
    ),
    keypointsColumnHelper.accessor(
      'mile_stone',
      defaultMultiFilterColumn<IKeypoint, IMilestone[]>(
        'mile_stone',
        {
          name: c('milestone'),
          getFilter: getMilestoneFilter,
          field: 'mile_stone.record_id||mile_stone.name',
        },
        ['record_id', 'name'],
        () => getProjectMilestones(projectId),
        (key, value) => onFieldChange('mile_stone_id', key, value),
        disabled,
      ),
    ),
    MainProcessAndThemeColumn(
      projectId,
      mainProcesses,
      themes,
      updateMainProcess,
      disabled,
    ),
    keypointsColumnHelper.accessor(
      'team',
      defaultMultiFilterColumn<IKeypoint, ITeam[]>(
        'team',
        {
          name: c('team'),
          getFilter: getTeamFilter,
          field: 'team.name',
        },
        ['name'],
        () => getProjectTeams(projectId),
        (key, value) => onFieldChange('team_id', key, value),
        disabled,
      ),
    ),
    keypointsColumnHelper.accessor(
      'tag',
      defaultMultiFilterColumn<IKeypoint, ITag[]>(
        'tag',
        {
          name: c('tag'),
          getFilter: getTagFilter,
          field: 'tag.name',
        },
        ['name'],
        () => getProjectTags(projectId),
        (key, value) => onFieldChange('tag_id', key, value),
        disabled,
      ),
    ),
    keypointsColumnHelper.accessor(
      'improvement',
      defaultMultiFilterColumn<IKeypoint, IImprovement[]>(
        'improvement',
        {
          name: c('improvement'),
          getFilter: getImprovementFilter,
          field: 'improvement.title',
        },
        ['title'],
        () =>
          getFilteredProjectImprovementsWithPagination(projectId).then(
            (res) => res.items,
          ),
        (key, value) => onFieldChange('improvement_id', key, value),
        disabled,
      ),
    ),
    keypointsColumnHelper.accessor(
      'baseline',
      defaultDateColumnObject('baseline', { name: c('baseline_date') }),
    ),
    keypointsColumnHelper.accessor(
      'duration',
      defaultNumberColumn('duration', { name: c('duration_days') }),
    ),
    keypointsColumnHelper.accessor(
      'delay',
      defaultNumberColumn('delay', { name: c('delay_days') }),
    ),
    keypointsColumnHelper.accessor(
      'open_children',
      defaultChildrenCountColumn({ name: c('deliveries_done_total') }),
    ),
    keypointsColumnHelper.accessor(
      'user',
      defaultUserColumn('user', {
        name: c('reporter'),
        getFilter: getReporterFilter,
        field: 'user.firstName||user.lastName',
      }),
    ),
    keypointsColumnHelper.accessor(
      'created_at',
      defaultDateColumnObject('created_at', { name: c('created_at') }),
    ),
    keypointsColumnHelper.accessor(
      'updated_at',
      defaultDateColumnObject('updated_at', { name: c('updated_at') }),
    ),
  ] as ColumnDef<IKeypoint>[]

  const updateMetaFiled = (data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload?.()
      })
    }
  }

  const userDefinedAttributesColumns = metaData
    ? userDefinedColumns(
        metaData,
        updateMetaFiled,
        'meta_data',
        true,
        false,
        false,
      )
    : []

  const defaultOrdering = [
    'select',
    'record_id',
    'name',
    'status',
    'responsible',
    'endTime',
    'duration',
    'delay',
    'closed_date',
    'open_children',
    'mile_stone',
    'main_process',
    'team',
    'tag',
    'improvement',
    'discipline',
    'contract',
    'user',
    'baseline',
    'created_at',
    'updated_at',
  ]

  const visibleColumns = [
    'select',
    'record_id',
    'name',
    'status',
    'responsible',
    'endTime',
  ]

  const inspectorPanelColumnVisibility = {} as VisibilityState
  defaultOrdering?.map((field) => {
    inspectorPanelColumnVisibility[field] = visibleColumns.includes(field)
  })

  return {
    columns,
    defaultOrdering,
    inspectorPanelColumnVisibility,
    legacyColumns: userDefinedAttributesColumns,
  }
}
