export enum TimelineItemType {
  PROJECT = 'Project',
  MAIN_PROCESS = 'MainProcess',
  MILE_STONE = 'MileStone',
  KEY_POINT = 'KeyPoint',
  DELIVERY = 'Delivery',
  TASK = 'Task',
}

export type ItemWithDependency =
  | TimelineItemType.KEY_POINT
  | TimelineItemType.DELIVERY

export const typeIsTopLevel = (type: TimelineItemType) =>
  [TimelineItemType.PROJECT, TimelineItemType.MAIN_PROCESS].includes(type)

export const typeHasDependencies = (type: TimelineItemType) =>
  [TimelineItemType.KEY_POINT, TimelineItemType.DELIVERY].includes(type)

export interface Dependent {
  id: number
  endTime: string
  status: string
  key_point_id?: number
  main_process_id?: number
  mile_stone_id?: number
}

export interface Parent {
  id: number
  name: string
  deadline: string
  endTime: string
}

export interface Discipline {
  id: number
  name: string
  shortName: string
  color: string
}

export interface Responsible {
  id: number
  firstName: string
  lastName: string
  image_url: string
}

export interface Improvement {
  id: number
  title: string
  deadline: string
}

export interface ITimelineItem {
  id: string
  item_id: number
  title?: string
  name: string
  record_id: string
  start_time: number
  end_time: number
  status: string
  endTime: string
  expired: boolean
  open_children: number
  done_children: number
  expired_children: number
  behind_schedule_children: number
  delayed: boolean
  group: string
  dependent_ids: number[]
  dependent_on_ids: number[]
  dependent: Dependent[]
  dependent_on: Dependent[]
  color: string
  changes: number
  is_under_milestone: boolean
  children: ITimelineItem[]
  hierarchy: string
  cached?: boolean
  root?: boolean
  isParent?: boolean
  parent?: Parent
  discipline?: Discipline
  responsible?: Responsible
  improvement?: Improvement
  type: TimelineItemType
}
